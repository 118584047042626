import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { Dimension, TDimension } from '../../api/analytics/Dimension';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import {
	KeyMeasure,
	KeyMeasureStatusOptions,
	KeyMeasureStatusOptionsMap,
	TKeyMeasure,
} from '../../api/analytics/KeyMeasure';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import SchnurForm, {
	EventType,
	Field,
	IDualListOption,
	ISelectOption,
	IVariableInputList,
	IVariableInputListData,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { TUnitType, UnitType } from '../../api/analytics/UnitType';
import { useMount } from 'react-use';
import { Period, TPeriod } from '../../api/analytics/Period';
import SchemaUpdateButton from '../../components/button/SchemaUpdateButton';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import { KeyMeasureFact } from '../../api/analytics/KeyMeasureFact';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export default function KeyMeasures(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TKeyMeasure[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeKeyMeasure, setActiveKeyMeasure] = useState<TKeyMeasure>(
		KeyMeasure.Default() as TKeyMeasure
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const [_unitTypes, setUnitType] = useState<TUnitType[]>([]);
	const [periods, setPeriods] = useState<TPeriod[]>([]);

	const selectedColumns: Column<TKeyMeasure>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			sortable: true,
		},
		{
			title: 'Title',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Status',
			columnName: 'status',
			sortable: true,
			customAccessor: (item) => KeyMeasureStatusOptionsMap[item.status],
		},
		{
			title: 'Dimensions',
			columnName: 'dimensions',
			customAccessor: (item) => `${item.dimensions.length} dimensions`,
			sortable: true,
		},
		{
			title: 'Facts',
			columnName: 'keyMeasureFacts',
			customAccessor: (item) => `${item.keyMeasureFacts.length} facts`,
			sortable: true,
		},
	];

	useMount(() => {
		Dimension.GetAll()
			.then((dimensions) => {
				setDimensions(dimensions);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dimensions. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		UnitType.GetAll()
			.then((unitTypes) => {
				setUnitType(unitTypes);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load unit types. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		Period.GetAll()
			.then((periods) => {
				setPeriods(periods);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load periods. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	});

	const formProperties: Field<TKeyMeasure>[] = [
		{
			title: 'Alias',
			columnName: 'code',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'key_measure_alias',
				helpText: 'The alias of the key measure to use in SQL statements.',
			},
			validate: (eventType, value) => {
				if (eventType !== EventType.BLUR && eventType !== EventType.CHANGE) {
					return true;
				}

				if (value.includes(' ')) {
					return 'An alias must not contain a space!';
				}

				return true;
			},
			required: true,
		},
		{
			title: 'Title',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'Key Measure Title',
				helpText: 'The title of the Key Measure to display in the UI.',
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
				placeholder: 'Key Measure Description',
				helpText: 'The description of the Key Measure to display in the UI.',
			},
			maxLength: 255,
		},
		{
			title: 'Status',
			columnName: 'status',
			uiSchema: {
				type: UIType.SELECT,
				options: KeyMeasureStatusOptions,
				onSelect: (value: ISelectOption) => value.key,
				initialSelection: 'id' in activeKeyMeasure ? activeKeyMeasure.status : 'ACTIVE',
			},
			required: true,
		},
		{
			title: 'Dimensions',
			columnName: 'dimensions',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: dimensions.map(
					(dimension) => ({ key: dimension.id, value: dimension.name } as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => value.map((option) => option.key),
				selected: activeKeyMeasure.dimensions
					.map((dimension) => {
						if (typeof dimension === 'object') {
							return {
								key: dimension.id,
								value: dimension.name,
							} as ISelectOption;
						} else if (typeof dimension === 'number') {
							const foundDim = dimensions.find((dim) => dim.id === dimension);
							return {
								key: foundDim?.id,
								value: foundDim?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
			required: true,
		},
		{
			title: 'Key Measure Facts',
			columnName: 'keyMeasureFacts',
			required: true,
			uiSchema: {
				type: UIType.VARIABLE_INPUT_GROUP,
				data: activeKeyMeasure.keyMeasureFacts.map(
					(fact) =>
						({
							key: fact.id,
							content: {
								name: fact.name,
								unitType: fact.unitType,
								sequence: fact.sequence,
								direction: fact.direction,
								minimum_value: fact.minimum_value,
								maximum_value: fact.maximum_value,
							} as IVariableInputListData,
						} as IVariableInputList)
				),
			},
			validate: (eventType, value) => true,
		},
		{
			title: 'Recalculation Average Percentage',
			columnName: 'recalc_percentage',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
		{
			title: 'Partitioned',
			columnName: 'partitioned',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
		{
			title: 'Lowest Aggregate Period Data',
			columnName: 'period',
			uiSchema: {
				type: UIType.SELECT,
				options: periods.map(
					(period) => ({ key: period.id, value: period.name } as ISelectOption)
				),
				onSelect: (value: ISelectOption) => value.key,
				initialSelection:
					typeof activeKeyMeasure.period === 'object' && activeKeyMeasure.period
						? activeKeyMeasure.period.name
						: null,
			},
		},
	];

	const actions: Action<KeyMeasure>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			callback: (item) => {
				setActiveKeyMeasure(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditKeyMeasure,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			callback: (item) => {
				setTableLoading(true);
				KeyMeasure.Delete(item.id)
					.then((response) => {
						if (response.success) {
							getKeyMeasures();
						} else {
							setTableLoading(false);
						}
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete Key Measure. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteKeyMeasure,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Key Measure"
					pageDescription="Manage your Key Measures."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		getKeyMeasures();
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const getKeyMeasures = () => {
		KeyMeasure.GetAll(['unitType', 'period', 'dimensions', 'keyMeasureFacts'])
			.then((KeyMeasures) => {
				setData(KeyMeasures);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load key measures. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: KeyMeasure) => {
		const index = data.findIndex((KeyMeasure) => KeyMeasure.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'key-measure-add-btn'}
			variant={'primary'}
			onClick={() => {
				setActiveKeyMeasure(KeyMeasure.Default() as TKeyMeasure);
				setIsModalOpen(true);
			}}
			permission={Permission.CreateKeyMeasure}
		>
			New Key Measure
		</PermissionButton>
	);

	const keyMeasureTable = (
		<ZiTable<KeyMeasure>
			ariaLabel={'Key Measure table'}
			columns={selectedColumns}
			data={data}
			caption="Key Measure"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.large}
				title="Key Measure Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TKeyMeasure>
					title={'Key Measure Management'}
					fields={formProperties}
					initialSubject={activeKeyMeasure}
					isLoading={isFormLoading}
					onSubmit={(keyMeasure) => {
						setIsFormLoading(true);
						keyMeasure.keyMeasureFacts = keyMeasure.keyMeasureFacts.map(
							(km) => new KeyMeasureFact(km)
						);
						if ('id' in keyMeasure) {
							KeyMeasure.Update(keyMeasure, [
								'unitType',
								'period',
								'dimensions',
								'keyMeasureFacts',
							])
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the key measure. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setActiveKeyMeasure(keyMeasure);
									setIsFormLoading(false);
								});
						} else {
							KeyMeasure.New(keyMeasure, [
								'unitType',
								'period',
								'dimensions',
								'keyMeasureFacts',
							])
								.then((newSource) => {
									handleSuccess(newSource);
								})
								.catch(() => {
									setActiveKeyMeasure(keyMeasure);
									addToast(
										'An error occurred while trying to save the key measure. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setActiveKeyMeasure(keyMeasure);
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={keyMeasureTable}
					layoutActions={[addButton, <SchemaUpdateButton />]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
