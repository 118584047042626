import {
	Button,
	DropdownItem,
	Flex,
	FlexItem,
	Popover,
	Text,
	TextVariants,
	TreeViewDataItem,
} from '@patternfly/react-core';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData, BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { GenericKeyValueResponse, GenericStringKeyValueResponse } from '../api/types';
import { constructUrl, parseFolderSegments } from '../helpers/folder-url-helper';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useCommonStoreContext } from '../components/common-store/CommonStoreProvider';
import SearchableTreeViewSelect, {
	dropdownChildrenToTreeViewData,
} from '../components/form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../helpers/tree-view.helper';
import { TFolder } from '../api/types/folder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { Card, TCard } from '../api/cards/Cards';
import { CardTypesEnum } from '../enums/card-types.enum';
import { FolderTypesEnum } from '../enums/folder-types-enum';

const BreadCrumbs = () => {
	const pathname = useLocation().pathname;
	const segments = pathname.split('/'); // Split the URL by '/'
	const isDataframes = pathname.includes('analyze/dataframes');
	const isCharts = pathname.includes('analyze/charts');
	const isTables = pathname.includes('analyze/table') || pathname.includes('/table/');
	const isReports = pathname.includes('/report');
	const isDashboards = pathname.includes('analyze/dashboards');
	const isReportsRecents = pathname == '/report/recents';
	const isReportsFavourites = pathname == '/report/favorites';
	const isPresent = pathname.includes('/present/');
	const isView = pathname.includes('/view/');
	const isFromDashboard =
		pathname.includes('analyze/dashboards/') || pathname.includes('/dashboards/');
	const isStandardFlow =
		isCharts || isDataframes || isReports || isTables || isDashboards || isPresent;
	const [segmentedDataframeBreadcrumb, setSegmentedDataframeBreadcrumb] = useState<
		GenericKeyValueResponse<string | number | JSX.Element>[]
	>([]);
	const [segmentedStandardBreadcrumb, setSegmentedStandardBreadcrumb] = useState<
		GenericKeyValueResponse<string | number | JSX.Element>[]
	>([]);
	const navigate = useNavigate();
	const folderName =
		Number.isInteger(Number(segments[segments.length - 1])) && segments.includes('folder')
			? decodeURI(segments[segments.length - 2])
			: 'Folder';

	const nestedFoldersBreadcrumb = localStorage.getItem('currentNestedFolderPath') ?? 'Create';

	const { dashboardId, chartId, frameId, tableId, reportId, presentationId } = useParams();
	const {
		currentSelectedChart,
		currentSelectedDataframe,
		currentSelectedTableReport,
		currentSelectedDashboard,
		currentSelectedPresentation,
	} = useCommonStoreContext();
	const [treeViewData, setTreeViewData] = useState<TreeViewDataItem[]>([]);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const popoverRef = useRef<HTMLDivElement>(null);
	const searchInputRef = useRef<HTMLInputElement>(null);

	useMount(() => {
		const nestedFoldersBreadcrumb_Dataframes = localStorage.getItem('prevDataframePage') ?? '';
		const segmentedDataframesBreadcrumb = parseFolderSegments(
			nestedFoldersBreadcrumb_Dataframes
		);
		const nestedFoldersBreadcrumb_Charts =
			localStorage.getItem('currentNestedFolderPath') ?? '';
		const segmentedDChartsBreadcrumb = parseFolderSegments(nestedFoldersBreadcrumb_Charts);

		setSegmentedDataframeBreadcrumb(segmentedDataframesBreadcrumb);
		setSegmentedStandardBreadcrumb(segmentedDChartsBreadcrumb);
	});

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				popoverRef.current &&
				popoverRef.current.contains(event.target as Node) //Click inside popover
			) {
				return; // Do nothing, allow typing
			}

			if (
				searchInputRef.current &&
				searchInputRef.current.contains(event.target as Node) //Click inside search input
			) {
				return; // Do nothing, allow typing
			}

			setIsPopoverOpen(false); //Clicked outside → Close
		};

		if (isPopoverOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isPopoverOpen]);

	const renderBreadrcrumbFolderCharts = () => {
		const folderId = currentSelectedChart.folder;
		const dropdownHtml: JSX.Element[] = [];

		void Card.GetAll(CardTypesEnum.Chart, folderId ? +folderId : undefined, []).then(
			(response) => {
				response.map((chart) => {
					if (!chart.isFolder) {
						dropdownHtml.push(
							<DropdownItem
								id={chart.id?.toString()}
								key={chart.name}
							>
								{chart.name}
							</DropdownItem>
						);
					}
				});

				const treeViewData = dropdownChildrenToTreeViewData(dropdownHtml);
				setTreeViewData(treeViewData);
			}
		);
	};

	const renderBreadrcrumbFolderDataframes = () => {
		if (currentSelectedDataframe) {
			const folderId = currentSelectedDataframe
				? (currentSelectedDataframe.folder as TFolder)?.id
				: 0;
			const dropdownHtml: JSX.Element[] = [];

			void Card.GetAll(CardTypesEnum.DataFrame, folderId ? +folderId : undefined, []).then(
				(response) => {
					response.map((dataframe) => {
						if (!dataframe.isFolder && !dataframe.managed) {
							dropdownHtml.push(
								<DropdownItem
									id={dataframe.id?.toString()}
									key={dataframe.name}
								>
									{dataframe.name}
								</DropdownItem>
							);
						}
					});

					const treeViewData = dropdownChildrenToTreeViewData(dropdownHtml);
					setTreeViewData(treeViewData);
				}
			);
		}
	};

	const renderBreadrcrumbFolderTablesReports = () => {
		if (currentSelectedTableReport) {
			const folderId = currentSelectedTableReport.folder;
			const dropdownHtml: JSX.Element[] = [];

			void Card.GetAll(
				isTables ? FolderTypesEnum.Tables : CardTypesEnum.Report,
				folderId ? +folderId : undefined,
				[]
			).then((response) => {
				response.map((item) => {
					const isTableCondition = isTables
						? (item: TCard) => item.isTable === true
						: (item: TCard) => item.isTable === false;

					if (!item.isFolder && isTableCondition(item)) {
						dropdownHtml.push(
							<DropdownItem
								id={item.id?.toString()}
								key={item.name}
							>
								{item.name}
							</DropdownItem>
						);
					}
				});

				const treeViewData = dropdownChildrenToTreeViewData(dropdownHtml);
				setTreeViewData(treeViewData);
			});
		}
	};

	const renderBreadrcrumbFolderDashboards = () => {
		const folderId = currentSelectedDashboard.folder;
		const dropdownHtml: JSX.Element[] = [];

		void Card.GetAll(CardTypesEnum.Dashboard, folderId ? +folderId : undefined, []).then(
			(response) => {
				response.map((dashboard) => {
					if (!dashboard.isFolder) {
						dropdownHtml.push(
							<DropdownItem
								id={dashboard.id?.toString()}
								key={dashboard.name}
							>
								{dashboard.name}
							</DropdownItem>
						);
					}
				});

				const treeViewData = dropdownChildrenToTreeViewData(dropdownHtml);
				setTreeViewData(treeViewData);
			}
		);
	};

	const renderBreadrcrumbFolderPresentation = () => {
		const folderId = currentSelectedPresentation.folder;
		const dropdownHtml: JSX.Element[] = [];

		void Card.GetAll(CardTypesEnum.Presentation, folderId ? +folderId : undefined, []).then(
			(response) => {
				response.map((present) => {
					if (!present.isFolder) {
						dropdownHtml.push(
							<DropdownItem
								id={present.id?.toString()}
								key={present.name}
							>
								{present.name}
							</DropdownItem>
						);
					}
				});

				const treeViewData = dropdownChildrenToTreeViewData(dropdownHtml);
				setTreeViewData(treeViewData);
			}
		);
	};

	const stageBreadcrumbSegementsData = (
		segmentedChartsBreadcrumb?: GenericKeyValueResponse<string | number | JSX.Element>[]
	) => {
		const currentFolderPath = localStorage.getItem('currentNestedFolderPath');
		const urlToUse = currentFolderPath != null ? currentFolderPath : pathname;

		if (urlToUse) {
			const newChartsBreadcrumb = parseFolderSegments(urlToUse).filter(
				(x) => x.value != 'View'
			);
			const updatedBreadcrumbToUse = segmentedChartsBreadcrumb ?? newChartsBreadcrumb;

			const breadcrumbListContainsCreate =
				newChartsBreadcrumb.find((x) => x.value == 'Create') !== undefined;
			const breadcrumbListContainsEdit =
				newChartsBreadcrumb.find((x) => x.value == 'Edit') !== undefined;
			const breadcrumbListContainsView =
				newChartsBreadcrumb.find((x) => x.value == 'View') !== undefined;

			const urlEndsInNumber = /\/\d+$/.test(pathname);

			if (
				(pathname.includes('create') || pathname.includes('build/dataframe/')) &&
				!breadcrumbListContainsCreate
			) {
				setSegmentedStandardBreadcrumb([
					...updatedBreadcrumbToUse,
					{ id: 0, value: 'Create' },
				]);
			} else if (
				(pathname.includes('build') ||
					pathname.includes('edit') ||
					(urlEndsInNumber &&
						!pathname.includes('view') &&
						!pathname.includes('/analyze/table/') &&
						!pathname.includes('/folder/'))) &&
				!pathname.includes('build/dataframe/') &&
				!breadcrumbListContainsEdit
			) {
				setSegmentedStandardBreadcrumb([
					...updatedBreadcrumbToUse,
					{ id: 0, value: 'Edit' },
				]);
			} else if (pathname.includes('view') && !breadcrumbListContainsView) {
				setSegmentedStandardBreadcrumb([
					...updatedBreadcrumbToUse,
					{ id: 0, value: 'View' },
				]);
			} else {
				setSegmentedStandardBreadcrumb(updatedBreadcrumbToUse);
			}
		} else {
			setSegmentedStandardBreadcrumb([]);
		}
	};

	const navigateBreadcrumbToEntity = (entityId: string) => {
		const editViewMode = isView ? 'view' : 'edit';

		if (isFromDashboard) {
			if (chartId) {
				const isDasbhoardChartView = pathname.includes('/view');
				const url = `/analyze/dashboards/${dashboardId ?? 0}/chart/${entityId}${
					isDasbhoardChartView ? '/view' : ''
				}`;
				navigate(url);
			}
			if (tableId) {
				navigate(`/dashboards/${dashboardId ?? 0}/table/${editViewMode}/${entityId}`);
			}
			if (dashboardId) {
				if (isView) {
					navigate(`/analyze/dashboards/view/${entityId}`);
				} else {
					navigate(`/analyze/dashboards/${entityId}`);
				}
			}
		} else {
			if (chartId) {
				const chartEditViewMode = isView ? 'view' : 'build';
				navigate(`/analyze/charts/${chartEditViewMode}/chart/${entityId}`);
			}
			if (frameId) {
				navigate(`/analyze/dataframes/${editViewMode}/${entityId}`);
			}
			if (tableId) {
				navigate(`/table/${editViewMode}/${entityId}`);
			}
			if (reportId) {
				navigate(`/report/${editViewMode}/${entityId}`);
			}
			if (dashboardId) {
				if (isView) {
					navigate(`/analyze/dashboards/view/${entityId}`);
				} else {
					navigate(`/analyze/dashboards/${entityId}`);
				}
			}
			if (presentationId) {
				navigate(`/present/edit/${entityId}`);
			}
		}

		setIsPopoverOpen(false);
	};

	useEffect(() => {
		if (chartId) {
			const chartName = currentSelectedChart.name;
			const ChartNameSegment: GenericKeyValueResponse<JSX.Element> = {
				id: currentSelectedChart.id ?? 0,
				value: (
					<>
						{chartName + ' '}
						<FontAwesomeIcon
							className="icon"
							icon={faCaretDown}
							size="xl"
						/>
					</>
				),
			};

			const nestedFoldersBreadcrumb_Charts =
				localStorage.getItem('currentNestedFolderPath') ?? '';
			const segmentedChartsBreadcrumb = parseFolderSegments(nestedFoldersBreadcrumb_Charts);
			segmentedChartsBreadcrumb.push(ChartNameSegment);

			stageBreadcrumbSegementsData(segmentedChartsBreadcrumb);
			renderBreadrcrumbFolderCharts();
		}
	}, [currentSelectedChart]);

	useEffect(() => {
		if (frameId && currentSelectedDataframe) {
			const dataframeName = currentSelectedDataframe.name;
			const dataframeNameSegment: GenericKeyValueResponse<string | number | JSX.Element> = {
				id: currentSelectedDataframe.id ?? 0,
				value: (
					<>
						{dataframeName + ' '}
						<FontAwesomeIcon
							className="icon"
							icon={faCaretDown}
							size="xl"
						/>
					</>
				),
			};

			const nestedFoldersBreadcrumb_Charts =
				localStorage.getItem('currentNestedFolderPath') ?? '';
			const segmentedChartsBreadcrumb = parseFolderSegments(nestedFoldersBreadcrumb_Charts);
			segmentedChartsBreadcrumb.push(dataframeNameSegment);

			updateDataframeBreadcrumb(segmentedChartsBreadcrumb);
			renderBreadrcrumbFolderDataframes();
		}
	}, [currentSelectedDataframe]);

	useEffect(() => {
		if ((tableId || reportId) && currentSelectedTableReport) {
			const entityName = currentSelectedTableReport.name;
			const entityNameSegment: GenericKeyValueResponse<string | number | JSX.Element> = {
				id: currentSelectedTableReport.id ?? 0,
				value: (
					<>
						{entityName + ' '}
						<FontAwesomeIcon
							className="icon"
							icon={faCaretDown}
							size="xl"
						/>
					</>
				),
			};

			const nestedFoldersBreadcrumb_TableReports =
				localStorage.getItem('currentNestedFolderPath') ?? '';
			const segmentedTableReportsBreadcrumb = parseFolderSegments(
				nestedFoldersBreadcrumb_TableReports
			);
			segmentedTableReportsBreadcrumb.push(entityNameSegment);

			stageBreadcrumbSegementsData(segmentedTableReportsBreadcrumb);
			renderBreadrcrumbFolderTablesReports();
		}
	}, [currentSelectedTableReport]);

	useEffect(() => {
		if (presentationId) {
			const presentName = currentSelectedPresentation.name;
			const presentNameSegment: GenericKeyValueResponse<string | number | JSX.Element> = {
				id: currentSelectedPresentation.id ?? 0,
				value: (
					<>
						{presentName + ' '}
						<FontAwesomeIcon
							className="icon"
							icon={faCaretDown}
							size="xl"
						/>
					</>
				),
			};

			const nestedFoldersBreadcrumb_Presentation =
				localStorage.getItem('currentNestedFolderPath') ?? '';
			const segmentedPresentBreadcrumb = parseFolderSegments(
				nestedFoldersBreadcrumb_Presentation
			);
			segmentedPresentBreadcrumb.push(presentNameSegment);

			stageBreadcrumbSegementsData(segmentedPresentBreadcrumb);
			renderBreadrcrumbFolderPresentation();
		}
	}, [currentSelectedPresentation]);

	useEffect(() => {
		if (dashboardId) {
			const dashboardName = currentSelectedDashboard.name;
			const dasbhoardNameSegment: GenericKeyValueResponse<string | number | JSX.Element> = {
				id: currentSelectedDashboard.id ?? 0,
				value: (
					<>
						{dashboardName + ' '}
						<FontAwesomeIcon
							className="icon"
							icon={faCaretDown}
							size="xl"
						/>
					</>
				),
			};

			const nestedFoldersBreadcrumb_Dashboards =
				localStorage.getItem('currentNestedFolderPath') ?? '';
			const segmentedDashboardsBreadcrumb = parseFolderSegments(
				nestedFoldersBreadcrumb_Dashboards
			);
			segmentedDashboardsBreadcrumb.push(dasbhoardNameSegment);

			stageBreadcrumbSegementsData(segmentedDashboardsBreadcrumb);
			renderBreadrcrumbFolderDashboards();
		}
	}, [currentSelectedDashboard]);

	useEffect(() => {
		if (isDataframes && !frameId) {
			updateDataframeBreadcrumb();
		}
		if (isStandardFlow && !chartId) {
			updateStandardBreadcrumb();
		}

		//un-hide any breadcrumbs that were previously hidden by other actions
		const cleanupElements = document.getElementsByClassName('temp-hidden');

		Array.from(cleanupElements).forEach((element, index) => {
			const htmlElement = element as HTMLElement;
			htmlElement.classList.remove('temp-hidden');
		});

		//look for GUIDs and hide any breadcrumbs that contain a guid
		const breadcrumbElements = document.getElementsByClassName('breadcrumb-item-box');
		const guidRegex =
			/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

		Array.from(breadcrumbElements).forEach((element, index) => {
			const htmlElement = element as HTMLElement;
			const text = htmlElement.innerText.replaceAll(' ', '-').trim();
			if (guidRegex.test(text)) {
				//the html structure of the breadcrumbs items never changes so it will always be 3 parent elements up
				const parentElement = htmlElement.parentElement?.parentElement?.parentElement;

				if (parentElement && !parentElement.classList.contains('temp-hidden')) {
					parentElement.classList.add('temp-hidden');
				}
			}
		});
	}, [window.location.pathname]);

	const updateDataframeBreadcrumb = (
		segmentedDataframesBreadcrumb?: GenericKeyValueResponse<string | number | JSX.Element>[]
	) => {
		const urlToUse = localStorage.getItem('prevDataframePage') ?? pathname;
		const newDataframesBreadcrumb = parseFolderSegments(urlToUse).filter(
			(x) => x.value != 'View'
		);

		const updatedBreadcrumbsToUse = frameId
			? segmentedDataframesBreadcrumb && segmentedDataframesBreadcrumb?.length > 0
				? segmentedDataframesBreadcrumb
				: newDataframesBreadcrumb
			: newDataframesBreadcrumb;

		const breadcrumbListContainsCreate =
			newDataframesBreadcrumb.find((x) => x.value == 'Create') !== undefined;
		const breadcrumbListContainsEdit =
			newDataframesBreadcrumb.find((x) => x.value == 'Edit') !== undefined;
		const breadcrumbListContainsView =
			newDataframesBreadcrumb.find((x) => x.value == 'View') !== undefined;

		if (pathname.includes('create') && !breadcrumbListContainsCreate) {
			setSegmentedDataframeBreadcrumb([
				...updatedBreadcrumbsToUse,
				{ id: 0, value: 'Create' },
			]);
		} else if (pathname.includes('edit') && !breadcrumbListContainsEdit) {
			setSegmentedDataframeBreadcrumb([...updatedBreadcrumbsToUse, { id: 0, value: 'Edit' }]);
		} else if (pathname.includes('view') && !breadcrumbListContainsView) {
			setSegmentedDataframeBreadcrumb([...updatedBreadcrumbsToUse, { id: 0, value: 'View' }]);
		} else {
			setSegmentedDataframeBreadcrumb(updatedBreadcrumbsToUse);
		}
	};

	const updateStandardBreadcrumb = () => {
		setTimeout(() => {
			stageBreadcrumbSegementsData();
		}, 550);
	};

	const breadcrumbs = useBreadcrumbs([
		{
			path: '/analyze/dashboards/blank',
			breadcrumb: 'New Dashboard',
		},
		{
			path: '/analyze',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'charts/folder/*folderPath',
					breadcrumb: pathname,
				},
				{
					path: 'dataframes',
					breadcrumb: 'Dataframes',
				},
				{
					path: 'dataframes/create',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'dataframes/folder/*folderPath',
					breadcrumb: segmentedDataframeBreadcrumb,
				},
				{
					path: 'dataframes/edit',
					breadcrumb: null,
				},
				{
					path: 'dataframes/edit/:id',
					breadcrumb: 'Edit',
				},
				{
					path: 'charts',
					breadcrumb: 'Charts',
				},
				{
					path: 'charts/build',
					breadcrumb: null,
				},
				{
					path: 'charts/folder',
					breadcrumb: null,
				},
				{
					path: 'charts/folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'charts/folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{
					path: 'charts/view/chart/*',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'charts/build/chart/*',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'dashboards',
					breadcrumb: 'Dashboards',
					children: [
						{
							path: 'view',
							breadcrumb: 'View',
							children: [
								{
									path: ':id',
									breadcrumb: null,
								},
							],
						},
						{
							path: ':dashboardId',
							breadcrumb: 'Edit',
							children: [
								{
									path: 'chart',
									breadcrumb: 'Chart Widget',
									children: [
										{
											path: ':chartId',
											breadcrumb: null,
										},
										{
											path: 'dataframe/:dataframeId',
											breadcrumb: null,
										},
										{
											path: 'key-measure/:measureId',
											breadcrumb: null,
										},
										{
											path: 'key-measure-fact/:measureFactId',
											breadcrumb: null,
										},
									] as BreadcrumbsRoute[],
								},
							] as BreadcrumbsRoute[],
						},
					] as BreadcrumbsRoute[],
				},

				{
					path: 'charts/build/dataframe',
					breadcrumb: null,
				},
				{
					path: 'charts/build/dataframe/:id',
					breadcrumb: nestedFoldersBreadcrumb,
				},
				{
					path: 'charts/build/chart',
					breadcrumb: null,
				},
				{
					path: 'charts/build/chart/:chartId',
					breadcrumb: 'Edit',
				},
				{
					path: 'charts/build/key-measure-fact',
					breadcrumb: null,
				},
				{
					path: 'charts/build/key-measure-fact/:id',
					breadcrumb: null,
				},
				{
					path: 'charts/build/key-measure-fact/:id/:id',
					breadcrumb: 'Create',
				},
			],
		},
		{
			path: '/setup',
			breadcrumb: 'Setup',
		},
		{
			path: '/report',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{ path: 'edit', breadcrumb: null },
				{
					path: 'edit/:id',
					breadcrumb: 'Edit',
				},
			],
		},
		{
			path: '/present',
			children: [
				{
					path: 'folder',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername',
					breadcrumb: null,
				},
				{
					path: 'folder/:foldername/:id',
					breadcrumb: folderName,
				},
				{ path: 'edit', breadcrumb: null },
				{
					path: 'edit/:presentationId',
					breadcrumb: 'Edit',
				},
			],
		},
		{
			path: '/analyze/dashboard',
			children: [
				{
					path: ':id',
					breadcrumb: 'View',
				},
			],
		},
	] as BreadcrumbsRoute[]);

	const CleanFoldersLocalStorage = () => {
		localStorage.removeItem('currentSelectedFolderId');
		localStorage.removeItem('prevAnalyzePage');
		localStorage.removeItem('prevDataframePage');
	};

	const breadcrumbItemClick = (item: BreadcrumbData<string>) => {
		CleanFoldersLocalStorage();
		const link = item.match.pathname;

		if (link == '/setup/security') {
			navigate('/setup');
		} else if (link.includes('/setup/security/user')) {
			navigate('/setup/security/users');
		} else if (link.includes('/setup/subscription')) {
			navigate('/setup/subscription/builder');
		} else {
			navigate(item.match.pathname);
		}
	};

	const DataframeBreadcrumbItemClick = (
		item: GenericKeyValueResponse<string | number | JSX.Element>
	) => {
		if (item.id == 0) {
			return;
		}

		if (item.value.toString().includes('[object Object]')) {
			if (!isPopoverOpen) {
				setIsPopoverOpen(true);
			}
			return;
		}

		CleanFoldersLocalStorage();
		//eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const link = `/analyze/dataframes/folder/${item.value}/${item.id}`;
		navigate(link);
	};

	function removeDuplicateSlashes(url: string): string {
		// Ensure we don't remove the protocol part (e.g., https://)
		return url.replace(/([^:]\/)\/+/g, '$1');
	}

	const StandardBreadcrumbItemClick = (
		item: GenericKeyValueResponse<string | number | JSX.Element>
	) => {
		if (item.id == 0) {
			return;
		}

		if (item.value.toString().includes('[object Object]')) {
			if (!isPopoverOpen) {
				setIsPopoverOpen(true);
			}
			return;
		}
		const selectedSegment = segmentedStandardBreadcrumb.find((x) => x.id == item.id);
		if (selectedSegment) {
			localStorage.setItem('isBreadcrumbFolderClicked', 'True');
			const nestedFolderPath = localStorage.getItem('currentNestedFolderPath');
			const selectedSegmentIdx = segmentedStandardBreadcrumb.indexOf(selectedSegment);

			let type = getSection(pathname);
			if (type == 'view' && isTables) {
				type = 'table';
			}

			let link = `/analyze/${type != 'dashboards' ? type : ''}`;

			if (selectedSegmentIdx == 0) {
				//eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				link += `/folder/${item.value}/${item.id}`;
			} else {
				link = constructUrl(nestedFolderPath!, selectedSegmentIdx);
			}

			link = removeDuplicateSlashes(link);

			navigate(link);
		}
	};

	const BaseBreadcrumbItemClick = (item: GenericStringKeyValueResponse<string>) => {
		CleanFoldersLocalStorage();

		if (item.key == 'Dataframes') {
			navigate('/analyze/loading');
			setTimeout(() => {
				navigate(item.value);
			}, 250);
		} else if (item.key == 'Dashboards' || item.key == 'Charts') {
			localStorage.removeItem('currentNestedFolderPath');
			navigate(item.value);
		} else {
			navigate(item.value);
		}
	};

	function decodeBreadcrumb(breadcrumb: React.ReactNode): React.ReactNode {
		if (React.isValidElement(breadcrumb)) {
			const element = breadcrumb as React.ReactElement;
			return React.cloneElement(element, {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
				children: React.Children.map(element.props.children, (child) =>
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
					typeof child === 'string' ? decodeURIComponent(child) : child
				),
			});
		}
		return breadcrumb;
	}

	function getSection(url: string): string {
		const start = url.indexOf('analyze/') + 'analyze/'.length;
		const end = url.indexOf('/', start);

		return end === -1 ? url.substring(start) : url.substring(start, end);
	}

	const emptyTree = [BuildTreeViewItem({ id: -1, name: 'No options were found' }, [])];

	if (isDataframes) {
		const baseSegments: GenericStringKeyValueResponse<string>[] = [
			{ key: 'Home', value: '/' },
			{ key: 'Analyze', value: '/analyze' },
			{ key: 'Dataframes', value: '/analyze/dataframes' },
		];

		const dataframeTreeViewData = frameId && treeViewData.length > 0 ? treeViewData : emptyTree;

		return (
			<React.Fragment>
				<Flex
					direction={{ default: 'row' }}
					className="breadcrumbs"
				>
					{/* BASE SEGMENTS */}
					{baseSegments.map((item, index) => {
						const isLastItem = index === baseSegments.length - 1;
						const isListing = pathname == '/analyze/dataframes';

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={TextVariants.a}
										onClick={() => {
											BaseBreadcrumbItemClick(item);
										}}
									>
										{item.key}
									</Text>
								</FlexItem>
								{isListing && !isLastItem && <FlexItem>/</FlexItem>}
								{!isListing && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}

					{/* DYNAMIC DATAFRAME SEGMENTS */}
					{segmentedDataframeBreadcrumb.map((item, index) => {
						const isLastItem = index === segmentedDataframeBreadcrumb.length - 1;
						const decodedValue = decodeURIComponent(item.value.toString()); // Decode safely
						const isEntityName = decodedValue.includes('[object Object]');

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={isLastItem ? TextVariants.p : TextVariants.a}
										onClick={() => {
											DataframeBreadcrumbItemClick(item);
										}}
									>
										{isEntityName ? (
											<Popover
												aria-label="Popover"
												position="bottom"
												minWidth="450px"
												isVisible={isPopoverOpen}
												onHide={() => setIsPopoverOpen(false)}
												bodyContent={() => (
													<div
														ref={popoverRef}
														className="w390"
													>
														<SearchableTreeViewSelect
															className="no-innerscroll"
															data-testid="searchable-breadcrumb-chart-tree"
															data={dataframeTreeViewData}
															placeHolderText={'Search...'}
															onSelect={(event, item) => {
																navigateBreadcrumbToEntity(
																	item.id!
																);
															}}
															treeItemsExpanded={true}
															isTopLvlSearchOnly={true}
															isInputAndTreeOnly={true}
															searchInputRef={searchInputRef}
														/>
													</div>
												)}
											>
												<Button
													style={{ all: 'unset' }}
													component={TextVariants.a}
												>
													{item.value}
												</Button>
											</Popover>
										) : (
											decodedValue
										)}
									</Text>
								</FlexItem>
								{!isLastItem && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}
				</Flex>
			</React.Fragment>
		);
	} else if (isStandardFlow) {
		const baseSegments: GenericStringKeyValueResponse<string>[] = [{ key: 'Home', value: '/' }];

		if (isCharts) {
			baseSegments.push({ key: 'Analyze', value: '/analyze' });
			baseSegments.push({ key: 'Charts', value: '/analyze/charts' });
		}

		if (isTables) {
			baseSegments.push({ key: 'Analyze', value: '/analyze' });
			baseSegments.push({ key: 'Tables', value: '/analyze/tables' });
		}

		if (isReports) {
			baseSegments.push({ key: 'Reports', value: '/report' });

			if (isReportsRecents) {
				baseSegments.push({ key: 'Recents', value: '/report/recents' });
			}

			if (isReportsFavourites) {
				baseSegments.push({ key: 'Favorites', value: '/report/favorites' });
			}
		}

		if (isDashboards) {
			baseSegments.push({ key: 'Analyze', value: '/analyze' });
			baseSegments.push({ key: 'Dashboards', value: '/analyze/dashboards' });
		}

		if (isPresent) {
			baseSegments.push({ key: 'Present', value: '/present' });
		}

		const hasEntityId = chartId || tableId || reportId || dashboardId || presentationId;
		const chartTreeViewData = hasEntityId && treeViewData.length > 0 ? treeViewData : emptyTree;

		return (
			<React.Fragment>
				<Flex
					direction={{ default: 'row' }}
					className="breadcrumbs"
				>
					{/* BASE SEGMENTS */}
					{baseSegments.map((item, index) => {
						const isLastItem = index === baseSegments.length - 1;
						const isListing = pathname == baseSegments[baseSegments.length - 1].value;

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={
											(item.key == 'Recents' && isReportsRecents) ||
											(item.key == 'Favorites' && isReportsFavourites)
												? TextVariants.p
												: TextVariants.a
										}
										onClick={() => {
											BaseBreadcrumbItemClick(item);
										}}
									>
										{item.key}
									</Text>
								</FlexItem>
								{isListing && !isLastItem && <FlexItem>/</FlexItem>}
								{!isListing && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}

					{/* DYNAMIC CHART SEGMENTS */}
					{segmentedStandardBreadcrumb.map((item, index) => {
						const isLastItem = index === segmentedStandardBreadcrumb.length - 1;
						const decodedValue = decodeURIComponent(item.value.toString()); // Decode safely
						const isEntityName = decodedValue.includes('[object Object]');

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={isLastItem ? TextVariants.p : TextVariants.a}
										onClick={() => {
											StandardBreadcrumbItemClick(item);
										}}
									>
										{isEntityName ? (
											<Popover
												aria-label="Popover"
												position="bottom"
												minWidth="450px"
												isVisible={isPopoverOpen}
												onHide={() => setIsPopoverOpen(false)}
												bodyContent={() => (
													<div
														ref={popoverRef}
														className="w390"
													>
														<SearchableTreeViewSelect
															className="no-innerscroll"
															data-testid="searchable-breadcrumb-chart-tree"
															data={chartTreeViewData}
															placeHolderText={'Search...'}
															onSelect={(event, item) => {
																navigateBreadcrumbToEntity(
																	item.id!
																);
															}}
															treeItemsExpanded={true}
															isTopLvlSearchOnly={true}
															isInputAndTreeOnly={true}
															searchInputRef={searchInputRef}
														/>
													</div>
												)}
											>
												<Button
													style={{ all: 'unset' }}
													component={TextVariants.a}
												>
													{item.value}
												</Button>
											</Popover>
										) : (
											decodedValue
										)}
									</Text>
								</FlexItem>
								{!isLastItem && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}
				</Flex>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<Flex
					direction={{ default: 'row' }}
					className="breadcrumbs"
				>
					{breadcrumbs.map((item, index) => {
						const isLastItem = index === breadcrumbs.length - 1;

						return (
							<Flex
								key={index}
								spaceItems={{ default: 'spaceItemsSm' }}
								justifyContent={{ default: 'justifyContentSpaceBetween' }}
							>
								<FlexItem>
									<Text
										component={isLastItem ? TextVariants.p : TextVariants.a}
										onClick={() => {
											breadcrumbItemClick(item);
										}}
									>
										<div className="breadcrumb-item-box">
											{decodeBreadcrumb(item.breadcrumb)}
										</div>
									</Text>
								</FlexItem>
								{!isLastItem && <FlexItem>/</FlexItem>}
							</Flex>
						);
					})}
				</Flex>
			</React.Fragment>
		);
	}
};

export default BreadCrumbs;
