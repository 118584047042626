import { AlertVariant, Button, Tab, Tabs } from '@patternfly/react-core';
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import {
	Field,
	IDualListOption,
	ISelectOption,
	UIType,
} from '@zeroedin-react/zi-common/lib/components/ZiForm/ZiForm';
import ZiForm from '@zeroedin-react/zi-common/lib/components/ZiForm/ZiForm';
import React from 'react';
import { Dashboard, TDashboard } from '../api/dashbboards/Dashboards';
import { useMount } from 'react-use';
import {
	SubscriptionChart,
	SubscriptionContent,
	SubscriptionDashboard,
	SubscriptionDataframe,
	SubscriptionReportTable,
	SubscriptionRole,
	SubscriptionUserGroup,
	TNewSubscriptionContent,
	TSubscriptionChart,
	TSubscriptionContent,
	TSubscriptionDashboard,
	TSubscriptionDataframe,
	TSubscriptionReportTable,
	TSubscriptionRole,
	TSubscriptionUserGroup,
} from '../api/subscriptions/SubscriptionContent';
import { SubscriptionContentTypesEnum } from '../enums/subscription-content-types.enum';
import { useNavigate, useParams } from 'react-router-dom';
import { Chart, TChart } from '../api/charts/Chart';
import { TDataframe, TReport } from '../api/types';
import { Report } from '../api/reports/Reports';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { Dataframe } from '../api/dataframes/Dataframes';
import { Group, TGroup } from '../api/security/Group';
import { Role, TRole } from '../api/security/Role';
import { Widget } from '../api/dashbboards/DashboardWidgets';
import Loader from '../components/util/Loader';

type Props = {
	isFormLoading: boolean;
	handleNestedTabSelect2: (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	) => void;
	nestedActiveTabKey2: number;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export default function SubscriptionContentTabs(props: Props): ReactElement {
	const { addToast } = useToast();
	const navigate = useNavigate();
	const [isExistingSubContent, setIsExistingSubContent] = useState<boolean>(false);

	const [activeSubDashboards, setActiveSubDashboards] = useState<TSubscriptionDashboard>(
		SubscriptionDashboard.Default()
	);
	const [activeSubCharts, setActiveSubCharts] = useState<TSubscriptionChart>(
		SubscriptionChart.Default()
	);
	const [activeSubTables, setActiveSubTables] = useState<TSubscriptionReportTable>(
		SubscriptionReportTable.Default()
	);
	const [activeSubReports, setActiveSubReports] = useState<TSubscriptionReportTable>(
		SubscriptionReportTable.Default()
	);
	const [activeSubDataframes, setActiveSubDataframes] = useState<TSubscriptionDataframe>(
		SubscriptionDataframe.Default()
	);
	const [activeSubUserGroups, setActiveSubUserGroups] = useState<TSubscriptionUserGroup>(
		SubscriptionUserGroup.Default()
	);
	const [activeSubRole, setActiveSubRole] = useState<TSubscriptionRole>(
		SubscriptionRole.Default()
	);

	const [dashboards, setDashboards] = React.useState<TDashboard[]>([]);
	const [filteredDashboards, setFilteredDashboards] = React.useState<TDashboard[]>([]);
	const [charts, setCharts] = React.useState<TChart[]>([]);
	const [filteredCharts, setFilteredCharts] = React.useState<TChart[]>([]);
	const [tables, setTables] = React.useState<TReport[]>([]);
	const [filteredTables, setFilteredTables] = React.useState<TReport[]>([]);
	const [reports, setReports] = React.useState<TReport[]>([]);
	const [filteredReports, setFilteredReports] = React.useState<TReport[]>([]);
	const [dataframes, setDataframes] = React.useState<TDataframe[]>([]);
	const [filteredDataframes, setFilteredDataframes] = React.useState<TDataframe[]>([]);
	const [userGroups, setUserGroups] = React.useState<TGroup[]>([]);
	const [filteredUserGroups, setFilteredUserGroups] = React.useState<TGroup[]>([]);
	const [roles, setRoles] = React.useState<TRole[]>([]);
	const [filteredRoles, setFilteredRoles] = React.useState<TRole[]>([]);
	const [existingSubscriptionContents, setExistingSubscriptionContents] = useState<
		TSubscriptionContent[]
	>([]);

	const [chosenSelectDashboards, setChosenSelectDashboards] = React.useState<number[]>([]);
	const [chosenSelectCharts, setChosenSelectCharts] = React.useState<number[]>([]);
	const [chosenSelectTables, setChosenSelectTables] = React.useState<number[]>([]);
	const [chosenSelectReports, setChosenSelectReports] = React.useState<number[]>([]);
	const [chosenSelectDataframes, setChosenSelectDataframes] = React.useState<number[]>([]);
	const [chosenSelectUserGroups, setChosenSelectUserGroups] = React.useState<number[]>([]);
	const [chosenSelectRoles, setChosenSelectRoles] = React.useState<number[]>([]);
	const { subscriptionId } = useParams();

	const [dashboardFormProps, setDashboardFormProps] = React.useState<
		Field<TSubscriptionDashboard>[]
	>([]);
	const [chartFormProps, setChartFormProps] = React.useState<Field<TSubscriptionChart>[]>([]);
	const [tableFormProps, setTableFormProps] = React.useState<Field<TSubscriptionReportTable>[]>(
		[]
	);
	const [reportFormProps, setReportFormProps] = React.useState<Field<TSubscriptionReportTable>[]>(
		[]
	);
	const [dataframeFormProps, setDataframeFormProps] = React.useState<
		Field<TSubscriptionDataframe>[]
	>([]);
	const [userGroupFormProps, setUserGroupFormProps] = React.useState<
		Field<TSubscriptionUserGroup>[]
	>([]);
	const [rolesFormProps, setRolesFormProps] = React.useState<Field<TSubscriptionRole>[]>([]);

	const [chartIdsBasedOnDashboards, setChartIdsBasedOnDashboards] = useState<number[]>([]);
	const [tableIdsBasedOnDashboards, setTableIdsBasedOnDashboards] = useState<number[]>([]);
	const [isSubContentLoading, setIsSubContentLoading] = useState(false);

	useMount(() => {
		setIsSubContentLoading(true);
		getExistingSubModelData();

		void Dashboard.GetAll().then((response) => {
			setDashboards(response);
		});

		void Chart.GetAll().then((response) => {
			setCharts(response);
		});

		void Report.GetAll([], true).then((response) => {
			setTables(response);
		});

		void Report.GetAll([]).then((response) => {
			setReports(response);
		});

		void Dataframe.GetAll([]).then((response) => {
			setDataframes(response);
		});

		void Group.GetAll([]).then((response) => {
			setUserGroups(response);
		});

		void Role.GetAll([]).then((response) => {
			setRoles(response);
		});
	});

	const setDashboardChildEntityIds = (dashboardIds: number[]) => {
		const chartIdsBasedOnDashboards: number[] = [];
		const tableIdsBasedOnDashboards: number[] = [];

		dashboardIds.map((x) => {
			if (x) {
				void Widget.GetAll({ dashboard: x.toString() }).then((widgetResponse) => {
					widgetResponse.map((widgetItem) => {
						if (widgetItem.chart && widgetItem.widget_type == 'chart') {
							chartIdsBasedOnDashboards.push(widgetItem.chart);
						}
						if (widgetItem.report && widgetItem.widget_type == 'pivot_table') {
							tableIdsBasedOnDashboards.push(widgetItem.report);
						}
					});

					setChartIdsBasedOnDashboards(chartIdsBasedOnDashboards);
					setTableIdsBasedOnDashboards(tableIdsBasedOnDashboards);
				});
			}
		});

		if (dashboardIds.length == 0) {
			setChartIdsBasedOnDashboards([]);
			setTableIdsBasedOnDashboards([]);
		}
	};

	useEffect(() => {
		setDashboardChildEntityIds(chosenSelectDashboards);
	}, [chosenSelectDashboards]);

	useEffect(() => {
		setChosenSelectCharts(chartIdsBasedOnDashboards);
		setChosenSelectTables(tableIdsBasedOnDashboards);
	}, [chartIdsBasedOnDashboards, tableIdsBasedOnDashboards]);

	useEffect(() => {
		const isExistingSubContent =
			existingSubscriptionContents && existingSubscriptionContents.length > 0;

		setIsExistingSubContent(isExistingSubContent);
	}, [existingSubscriptionContents]);

	useEffect(() => {
		const existingDashboards = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.DASHBOARD
		);

		const filteredDashboards = dashboards.filter(
			(x) => !existingDashboards.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredDashboards(filteredDashboards);

		const dashboardIds = existingDashboards.map((x) => parseInt(x.contentId));

		const activeSubDashboards: TSubscriptionDashboard = {
			dashboardIds: dashboardIds,
		};

		setActiveSubDashboards(activeSubDashboards);
		setChosenSelectDashboards(dashboardIds);
	}, [existingSubscriptionContents, dashboards]);

	useEffect(() => {
		const existingCharts = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.CHART
		);

		const filteredCharts = charts.filter(
			(x) => !existingCharts.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredCharts(filteredCharts);

		const chartIds = existingCharts.map((x) => parseInt(x.contentId));

		const activeSubCharts: TSubscriptionChart = {
			chartIds: chartIds,
		};

		setActiveSubCharts(activeSubCharts);
		setChosenSelectCharts(chartIds);
	}, [existingSubscriptionContents, charts]);

	useEffect(() => {
		const existingTables = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.TABLE
		);

		const filteredTables = tables.filter(
			(x) => !existingTables.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredTables(filteredTables);

		const tableIds = existingTables.map((x) => parseInt(x.contentId));

		const activeSubTables: TSubscriptionReportTable = {
			entityIds: tableIds,
		};

		setActiveSubTables(activeSubTables);
		setChosenSelectTables(tableIds);
	}, [existingSubscriptionContents, tables]);

	useEffect(() => {
		const existingReports = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.REPORT
		);

		const filteredReports = reports.filter(
			(x) => !existingReports.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredReports(filteredReports);

		const reportIds = existingReports.map((x) => parseInt(x.contentId));

		const activeSubReports: TSubscriptionReportTable = {
			entityIds: reportIds,
		};

		setActiveSubReports(activeSubReports);
		setChosenSelectReports(reportIds);
	}, [existingSubscriptionContents, reports]);

	useEffect(() => {
		const existingDataframes = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.DATAFRAME
		);

		const filteredDataframes = dataframes.filter(
			(x) => !existingDataframes.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredDataframes(filteredDataframes);

		const dataframeIds = existingDataframes.map((x) => parseInt(x.contentId));

		const activeSubDataframes: TSubscriptionDataframe = {
			dataframeIds: dataframeIds,
		};

		setActiveSubDataframes(activeSubDataframes);
		setChosenSelectDataframes(dataframeIds);
	}, [existingSubscriptionContents, dataframes]);

	useEffect(() => {
		const existingUserGroups = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.USER_GROUP
		);

		const filteredUserGroups = userGroups.filter(
			(x) => !existingUserGroups.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredUserGroups(filteredUserGroups);

		const userGroupIds = existingUserGroups.map((x) => parseInt(x.contentId));

		const activeSubUserGroups: TSubscriptionUserGroup = {
			userGroupIds: userGroupIds,
		};

		setActiveSubUserGroups(activeSubUserGroups);
		setChosenSelectUserGroups(userGroupIds);
	}, [existingSubscriptionContents, userGroups]);

	useEffect(() => {
		const existingRoles = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.ROLE
		);

		const filteredRoles = roles.filter(
			(x) => !existingRoles.some((y) => y.contentId == x.id?.toString())
		);
		setFilteredRoles(filteredRoles);

		const roleIds = existingRoles.map((x) => parseInt(x.contentId));

		const activeSubUserGroups: TSubscriptionRole = {
			roleIds: roleIds,
		};

		setActiveSubRole(activeSubUserGroups);
		setChosenSelectRoles(roleIds);
	}, [existingSubscriptionContents, roles]);

	useEffect(() => {
		const existingDashboards = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.DASHBOARD
		);
		const formPropertiesDashboards: Field<TSubscriptionDashboard>[] = [
			{
				title: '',
				columnName: 'dashboardIds',
				required: false,
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredDashboards.map(
						(dashboard) =>
							({ key: dashboard.id, value: dashboard.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: existingDashboards
						.map((dashboard) => {
							if (typeof dashboard === 'object') {
								const foundDashboard = dashboards.find(
									(dashboardListItem) =>
										dashboardListItem.id?.toString() === dashboard.contentId
								);
								return {
									key: dashboard.contentId,
									value: foundDashboard?.name,
								} as ISelectOption;
							} else if (typeof dashboard === 'number') {
								const foundDashboard = dashboards.find(
									(dashboardListItem) => dashboardListItem.id === dashboard
								);
								return {
									key: foundDashboard?.id,
									value: foundDashboard?.name,
								} as ISelectOption;
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[],
				},
			},
		];

		setDashboardFormProps(formPropertiesDashboards);
	}, [filteredDashboards]);

	useEffect(() => {
		setIsSubContentLoading(true);
		LoadChartFormProperties();
		LoadChartFormProperties();
		setIsSubContentLoading(false);
	}, [filteredCharts, chartIdsBasedOnDashboards, filteredDashboards]);

	const LoadChartFormProperties = () => {
		const existingCharts = existingSubscriptionContents.filter(
			(x) =>
				x.contentType == SubscriptionContentTypesEnum.CHART &&
				!chartIdsBasedOnDashboards.some((y) => y == +x.contentId)
		);

		const isExistingChartsSameAsDashoardAutoSelectCharts =
			chartIdsBasedOnDashboards.length > 0 && existingCharts.length == 0;

		const selectedOptions = isExistingChartsSameAsDashoardAutoSelectCharts
			? (chartIdsBasedOnDashboards
					.map((chartId) => {
						if (typeof chartId === 'number') {
							const foundChart = charts.find(
								(chartListItem) => chartListItem.id === chartId
							);
							if (foundChart) {
								return {
									key: foundChart?.id,
									value: foundChart?.name,
								} as ISelectOption;
							}
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[])
			: [
					...(existingCharts
						.map((chart) => {
							if (typeof chart === 'object') {
								const foundChart = charts.find(
									(chartListItem) =>
										chartListItem.id?.toString() === chart.contentId
								);
								if (foundChart) {
									return {
										key: +chart.contentId,
										value: foundChart?.name,
									} as ISelectOption;
								}
							} else if (typeof chart === 'number') {
								const foundChart = charts.find(
									(chartListItem) => chartListItem.id === chart
								);
								if (foundChart) {
									return {
										key: foundChart?.id,
										value: foundChart?.name,
									} as ISelectOption;
								}
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[]),
					...(chartIdsBasedOnDashboards.length > 0
						? (chartIdsBasedOnDashboards
								.map((chartId) => {
									if (typeof chartId === 'number') {
										const foundChart = charts.find(
											(chartListItem) => chartListItem.id === chartId
										);
										if (foundChart) {
											return {
												key: foundChart?.id,
												value: foundChart?.name,
											} as ISelectOption;
										}
									}

									return;
								})
								.filter((option) => option !== undefined) as ISelectOption[])
						: []),
			  ];

		const formPropertiesCharts: Field<TSubscriptionChart>[] = [
			{
				title: '',
				columnName: 'chartIds',
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredCharts.map(
						(chart) => ({ key: chart.id, value: chart.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: selectedOptions,
				},
			},
		];

		setChartFormProps(formPropertiesCharts);
	};

	useEffect(() => {
		if (rolesFormProps.length == 0) {
			setIsSubContentLoading(true);
		} else {
			//after the ZiForm DualList component is initialized,
			//it takes a few seconds for the data to be loaded due to it being loaded in a react state variable
			//show loader during this time
			setTimeout(() => {
				setIsSubContentLoading(false);
			}, 3500);
		}
	}, [rolesFormProps]);

	useEffect(() => {
		const existingTables = existingSubscriptionContents.filter(
			(x) =>
				x.contentType == SubscriptionContentTypesEnum.TABLE &&
				!tableIdsBasedOnDashboards.some((y) => y == +x.contentId)
		);

		const formPropertiesTables: Field<TSubscriptionReportTable>[] = [
			{
				title: '',
				columnName: 'entityIds',
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredTables.map(
						(table) => ({ key: table.id, value: table.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: existingTables
						.map((table) => {
							if (typeof table === 'object') {
								const foundTable = tables.find(
									(tableListItem) =>
										tableListItem.id?.toString() === table.contentId
								);
								return {
									key: table.contentId,
									value: foundTable?.name,
								} as ISelectOption;
							} else if (typeof table === 'number') {
								const foundTable = tables.find(
									(tableListItem) => tableListItem.id === table
								);
								return {
									key: foundTable?.id,
									value: foundTable?.name,
								} as ISelectOption;
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[],
				},
			},
		];

		setTableFormProps(formPropertiesTables);
	}, [filteredTables]);

	useEffect(() => {
		const existingReports = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.REPORT
		);
		const formPropertiesReport: Field<TSubscriptionReportTable>[] = [
			{
				title: '',
				columnName: 'entityIds',
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredReports.map(
						(table) => ({ key: table.id, value: table.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: existingReports
						.map((table) => {
							if (typeof table === 'object') {
								const foundTable = reports.find(
									(tableListItem) =>
										tableListItem.id?.toString() === table.contentId
								);
								return {
									key: table.contentId,
									value: foundTable?.name,
								} as ISelectOption;
							} else if (typeof table === 'number') {
								const foundTable = tables.find(
									(tableListItem) => tableListItem.id === table
								);
								return {
									key: foundTable?.id,
									value: foundTable?.name,
								} as ISelectOption;
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[],
				},
			},
		];

		setReportFormProps(formPropertiesReport);
	}, [filteredReports]);

	useEffect(() => {
		const existingDataframes = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.DATAFRAME
		);

		const formPropertiesDataframe: Field<TSubscriptionDataframe>[] = [
			{
				title: '',
				columnName: 'dataframeIds',
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredDataframes.map(
						(table) => ({ key: table.id, value: table.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: existingDataframes
						.map((dataframe) => {
							if (typeof dataframe === 'object') {
								const foundDataframe = dataframes.find(
									(dataframeListItem) =>
										dataframeListItem.id?.toString() === dataframe.contentId
								);
								return {
									key: dataframe.contentId,
									value: foundDataframe?.name,
								} as ISelectOption;
							} else if (typeof dataframe === 'number') {
								const foundDataframe = dataframes.find(
									(dataframeListItem) => dataframeListItem.id === dataframe
								);
								return {
									key: foundDataframe?.id,
									value: foundDataframe?.name,
								} as ISelectOption;
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[],
				},
			},
		];

		setDataframeFormProps(formPropertiesDataframe);
	}, [filteredDataframes]);

	useEffect(() => {
		const existingUserGroups = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.USER_GROUP
		);

		const formPropertiesUserGroup: Field<TSubscriptionUserGroup>[] = [
			{
				title: '',
				columnName: 'userGroupIds',
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredUserGroups.map(
						(table) => ({ key: table.id, value: table.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: existingUserGroups
						.map((group) => {
							if (typeof group === 'object') {
								const foundGroup = userGroups.find(
									(groupListItem) =>
										groupListItem.id?.toString() === group.contentId
								);
								return {
									key: group.contentId,
									value: foundGroup?.name,
								} as ISelectOption;
							} else if (typeof group === 'number') {
								const foundGroup = dataframes.find(
									(groupListItem) => groupListItem.id === group
								);
								return {
									key: foundGroup?.id,
									value: foundGroup?.name,
								} as ISelectOption;
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[],
				},
			},
		];

		setUserGroupFormProps(formPropertiesUserGroup);
	}, [filteredUserGroups]);

	useEffect(() => {
		const existingRoles = existingSubscriptionContents.filter(
			(x) => x.contentType == SubscriptionContentTypesEnum.ROLE
		);

		const formPropertiesRoles: Field<TSubscriptionRole>[] = [
			{
				title: '',
				columnName: 'roleIds',
				uiSchema: {
					type: UIType.DUAL_LIST,
					options: filteredRoles.map(
						(role) => ({ key: role.id, value: role.name } as ISelectOption)
					),
					onSelect: (value: IDualListOption[]) => {
						return [...value.map((option) => Number(option.key))];
					},
					selected: existingRoles
						.map((role) => {
							if (typeof role === 'object') {
								const foundRole = roles.find(
									(tableListItem) =>
										tableListItem.id?.toString() === role.contentId
								);
								return {
									key: role.contentId,
									value: foundRole?.name,
								} as ISelectOption;
							} else if (typeof role === 'number') {
								const foundRole = roles.find(
									(tableListItem) => tableListItem.id === role
								);
								return {
									key: foundRole?.id,
									value: foundRole?.name,
								} as ISelectOption;
							}

							return;
						})
						.filter((option) => option !== undefined) as ISelectOption[],
				},
			},
		];

		setRolesFormProps(formPropertiesRoles);
	}, [filteredRoles]);

	const getExistingSubModelData = () => {
		void SubscriptionContent.Get(subscriptionId!).then((subscriptionContentReponse) => {
			setExistingSubscriptionContents(subscriptionContentReponse);
		});
	};

	const ContentTabOnSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	) => {
		props.handleNestedTabSelect2(event, tabIndex);
	};

	const getDataForSubModelSave = () => {
		const dashboardIds = chosenSelectDashboards;
		const chartIds = chosenSelectCharts;
		const tableIds = chosenSelectTables;
		const reportIds = chosenSelectReports;
		const dataframeIds = chosenSelectDataframes;
		const userGroupIds = chosenSelectUserGroups;
		const roleIds = chosenSelectRoles;

		const dashboardPromises = dashboardIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.DASHBOARD,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const chartPromises = chartIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.CHART,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const tablePromises = tableIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.TABLE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const reportPromises = reportIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.REPORT,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const dataframePromises = dataframeIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.DATAFRAME,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const userGroupPromises = userGroupIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.USER_GROUP,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const rolePromises = roleIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id.toString(),
				contentType: SubscriptionContentTypesEnum.ROLE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const subscriptionContentPromises: Promise<TSubscriptionContent>[] = [
			...dashboardPromises,
			...chartPromises,
			...tablePromises,
			...reportPromises,
			...dataframePromises,
			...userGroupPromises,
			...rolePromises,
		];

		return subscriptionContentPromises;
	};

	const SubscriptionContentSave = () => {
		props.setIsLoading(true);

		let deletePromises: Promise<boolean>[] = [];

		if (isExistingSubContent) {
			deletePromises = [SubscriptionContent.Delete(subscriptionId ?? '0')];
		}

		//delete pre-existing records
		Promise.all(deletePromises)
			.then(() => {
				const subscriptionContentPromises = getDataForSubModelSave();
				Promise.all(subscriptionContentPromises)
					.then((responses: TSubscriptionContent[]) => {
						props.setIsLoading(false);
						addToast('Sucessfully saved Subscription Content', AlertVariant.success);
						getExistingSubModelData();
					})
					.catch(() => {
						props.setIsLoading(false);
						addToast(
							'An error occured while saving Subscription Content',
							AlertVariant.danger
						);
					});
			})
			.catch(() => {
				props.setIsLoading(false);
			});
	};

	if (isSubContentLoading) {
		return <Loader />;
	}
	return (
		<>
			<Tabs
				activeKey={props.nestedActiveTabKey2}
				onSelect={ContentTabOnSelect}
				className="child-tabs"
			>
				<Tab
					eventKey={0}
					title="Dashboards"
				>
					<ZiForm<SubscriptionDashboard>
						title={''}
						fields={dashboardFormProps}
						initialSubject={activeSubDashboards}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectDashboards(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>
				<Tab
					eventKey={1}
					title="Charts"
				>
					<ZiForm<SubscriptionChart>
						title={''}
						fields={chartFormProps}
						initialSubject={activeSubCharts}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectCharts(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>

				<Tab
					eventKey={2}
					title="Tables"
				>
					<ZiForm<SubscriptionReportTable>
						title={''}
						fields={tableFormProps}
						initialSubject={activeSubTables}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectTables(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>
				<Tab
					eventKey={3}
					title="Reports"
				>
					<ZiForm<SubscriptionReportTable>
						title={''}
						fields={reportFormProps}
						initialSubject={activeSubReports}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectReports(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>
				<Tab
					eventKey={4}
					title="Dataframes"
				>
					<ZiForm<SubscriptionDataframe>
						title={''}
						fields={dataframeFormProps}
						initialSubject={activeSubDataframes}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectDataframes(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>
				<Tab
					eventKey={5}
					title="User Groups"
				>
					<ZiForm<SubscriptionUserGroup>
						title={''}
						fields={userGroupFormProps}
						initialSubject={activeSubUserGroups}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectUserGroups(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>
				<Tab
					eventKey={6}
					title="Roles"
				>
					<ZiForm<SubscriptionRole>
						title={''}
						fields={rolesFormProps}
						initialSubject={activeSubRole}
						isLoading={props.isFormLoading}
						onSubmit={() => {
							return;
						}}
						onDualListSelectedOptionsChange={(options) => {
							const entityIds = options?.map((x) => (x.key as number) ?? 0) ?? [];
							setChosenSelectRoles(entityIds);
						}}
						hideReqOptionalMsgs={true}
						hideSubmitBtn={true}
					/>
				</Tab>
			</Tabs>
			<br />
			<div className="pull-right">
				<Button
					variant="primary"
					onClick={() => {
						navigate('/setup/subscription/builder');
					}}
				>
					Back
				</Button>
				<Button
					variant="primary"
					onClick={() => SubscriptionContentSave()}
				>
					Save
				</Button>
			</div>
		</>
	);
}
