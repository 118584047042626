import React, { useState } from 'react';
import { Widget } from '../../api/dashbboards/DashboardWidgets';
import ChartView from '../charts/ChartView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import './AutoWidget.scss';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { Size } from '../../types/databuilder/databuilder';
import TableView from '../data-builder/TableView';
import TextView from './widgets/TextView';

library.add(far, fas, fal);

type Props = {
	widget: Widget;
	selectedDate?: TNewDateRange;
	removeWidget?: (widget: Widget) => void;
	updateWidget?: (widget: Widget) => void;
	handleEditClick?: (widget: Widget) => void;
	setEditingId?: (id?: number) => void;
	filters?: DashboardFilter[];
	isEdit?: boolean;
	size?: Size;
	noAnimation?: boolean;
};

export type TextObjectType = {
	type: 'p' | 'h2' | 'h3';
	value: string;
};

const AutoWidget = (props: Props) => {
	const {
		widget,
		selectedDate,
		removeWidget,
		updateWidget,
		handleEditClick,
		setEditingId,
		filters,
		isEdit,
		size,
		noAnimation,
	} = props;
	const [editing, setEditing] = useState<boolean>(false);
	let component: React.ReactNode = <></>;

	const toggleEdit = () => {
		setEditing(!editing);
		if (setEditingId) {
			setEditingId(!editing ? widget.id : undefined);
		}
	};

	const getTextComponent = () => {
		return (
			<>
				<TextView
					filters={filters ?? []}
					isEditModalOpen={editing ?? false}
					isEdit={isEdit ?? false}
					toggleEdit={toggleEdit}
					updateWidget={updateWidget}
					widget={widget}
					selectedDate={selectedDate}
				/>
				<span className="edit-span">
					<FontAwesomeIcon
						icon={faPencil}
						className="edit-icon"
						size="xs"
						onClick={(e) => {
							e.stopPropagation();
							toggleEdit();
						}}
					/>
				</span>
				<span className="delete-span">
					<FontAwesomeIcon
						icon={faTrash}
						className="delete-icon"
						size="xs"
						onClick={(e) => {
							e.stopPropagation();
							removeWidget && widget && removeWidget(widget);
						}}
					/>
				</span>
			</>
		);
		// }
	};

	const getChartComponent = () => {
		return (
			<>
				<ChartView
					widgetId={widget.id ?? 0}
					chartId={widget.chart?.toString() ?? '0'}
					selectedDate={selectedDate}
					allowClickNavigate
					filters={filters}
					isEdit={isEdit}
					transparentBackground={true}
					width={size ? size.width : ''}
					height={size ? size.height : ''}
					delayedDisplayTime={50}
					noAnimation={noAnimation}
				/>
				<span className="edit-span">
					<FontAwesomeIcon
						icon={faPencil}
						className="edit-icon"
						size="xs"
						onClick={() => {
							handleEditClick && handleEditClick(widget);
						}}
					/>
				</span>
				<span className="delete-span">
					<FontAwesomeIcon
						icon={faTrash}
						className="delete-icon"
						size="xs"
						onClick={() => removeWidget && widget && removeWidget(widget)}
					/>
				</span>
			</>
		);
	};

	const getPivotTableComponent = () => {
		return (
			<>
				<TableView
					widgetId={widget.id ?? 0}
					reportId={widget.report?.toString() ?? '0'}
					filters={filters}
					isEdit={isEdit}
					allowClickNavigate
					selectedDate={selectedDate}
					delayedDisplayTime={500}
				/>
				<span className="edit-span">
					<FontAwesomeIcon
						icon={faPencil}
						className="edit-icon"
						size="xs"
						onClick={() => {
							handleEditClick && handleEditClick(widget);
						}}
					/>
				</span>
				<span className="delete-span">
					<FontAwesomeIcon
						icon={faTrash}
						className="delete-icon"
						size="xs"
						onClick={() => removeWidget && widget && removeWidget(widget)}
					/>
				</span>
			</>
		);
	};

	switch (widget.widget_type) {
		case 'text':
			component = getTextComponent();
			break;
		case 'chart':
			component = getChartComponent();
			break;
		case 'pivot_table':
			component = getPivotTableComponent();
			break;
		default:
			break;
	}
	return component;
};

export default AutoWidget;
