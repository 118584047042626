import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import {
	DatabaseDriverOptions,
	DatabaseDriverOptionsMap,
	ETLSourceDatabase,
	TETLSourceDatabase,
} from '../../api/analytics/ETLSourceDatabase';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export default function ETLSourceDatabases(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TETLSourceDatabase[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeSourceDatabase, setActiveSourceDatabase] = useState<TETLSourceDatabase>(
		ETLSourceDatabase.Default() as TETLSourceDatabase
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TETLSourceDatabase>[] = [
		{
			title: 'Database Name',
			columnName: 'dbName',
			sortable: true,
		},
		{
			title: 'Driver Type',
			columnName: 'driverType',
			customAccessor: (item) => DatabaseDriverOptionsMap[item.driverType],
			sortable: true,
		},
		{
			title: 'Host Name',
			columnName: 'host',
			sortable: true,
		},
		{
			title: 'Username',
			columnName: 'username',
			sortable: true,
		},
	];

	const actions: Action<TETLSourceDatabase>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			callback: (item) => {
				setActiveSourceDatabase(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditEtlSourceDatabase,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			callback: (item) => {
				setTableLoading(true);
				ETLSourceDatabase.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('ETL Source Database deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete ETL Source Database. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteEtlSourceDatabase,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="ELT Source Databases"
					pageDescription="Manage your ELT Source Databases."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		ETLSourceDatabase.GetAll()
			.then((etlSources) => {
				setData(etlSources);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load ETL source databases. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TETLSourceDatabase) => {
		const index = data.findIndex((etlSource) => etlSource.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'etlSource-add-button'}
			variant={'primary'}
			permission={Permission.CreateEtlSourceDatabase}
			onClick={() => {
				setActiveSourceDatabase(ETLSourceDatabase.Default() as TETLSourceDatabase);
				setIsModalOpen(true);
			}}
		>
			New ETL Source Database
		</PermissionButton>
	);

	const formProperties: Field<TETLSourceDatabase>[] = [
		{
			title: 'Database Name',
			columnName: 'dbName',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'my_etl_source',
				helpText: 'The database name of the ETL source',
			},
			required: true,
		},
		{
			title: 'Host',
			columnName: 'host',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'myetl.example.com',
				helpText: 'The host name of the ETL source',
			},
			required: true,
		},
		{
			title: 'Port',
			columnName: 'port',
			uiSchema: {
				type: UIType.NUMBER,
				helpText: 'The post number of the ETL source',
				widthChars: 5,
				min: 1,
				max: 65535,
			},
			required: true,
		},
		{
			title: 'Driver Type',
			columnName: 'driverType',
			uiSchema: {
				type: UIType.SELECT,
				options: DatabaseDriverOptions,
				initialSelection:
					'id' in activeSourceDatabase ? activeSourceDatabase.driverType : undefined,
				onSelect: (value: ISelectOption) => {
					return value.key;
				},
			},
			required: true,
		},
		{
			title: 'Username',
			columnName: 'username',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'username',
				helpText: 'The username name of the ETL source',
			},
			required: true,
		},
		{
			title: 'Password',
			columnName: 'password',
			uiSchema: {
				type: UIType.PASSWORD,
				helpText: 'The password name of the ETL source',
			},
			required: true,
		},
	];

	const dimensionTable = (
		<ZiTable<TETLSourceDatabase>
			ariaLabel={'ETL Source Databases'}
			columns={selectedColumns}
			data={data}
			caption="ETL Source Databases"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="ETL Source Database Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TETLSourceDatabase>
					title={'ETL Source Database'}
					fields={formProperties}
					initialSubject={activeSourceDatabase}
					isLoading={isFormLoading}
					onSubmit={(etlSource) => {
						setIsFormLoading(true);
						if ('id' in etlSource) {
							ETLSourceDatabase.Update(etlSource)
								.then(() => {
									handleSuccess(etlSource);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save ETL Source Database. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							ETLSourceDatabase.New(etlSource)
								.then((newSource) => {
									handleSuccess(newSource);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save ETL Source Database. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dimensionTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
