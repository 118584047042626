import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import {
	AlertVariant,
	Button,
	Checkbox,
	DropdownGroup,
	DropdownItem,
	Grid,
	GridItem,
	Modal,
	ModalVariant,
	Select,
	SelectDirection,
	SelectOption,
	SelectOptionObject,
	SelectVariant,
	TextInput,
	TimePicker,
	TreeViewDataItem,
} from '@patternfly/react-core';
import { IConfig } from '../../../layout/Layout';
import Loader from '../../util/Loader';
import { useNavigate } from 'react-router';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import SearchableTreeViewSelect, {
	dropdownToTreeViewData,
} from '../../form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { useMount } from 'react-use';
import { TFolder } from '../../../api/types/folder';
import { GenericStringKeyValueResponse, TReport } from '../../../api/types';
import { buildFolderDisplayForDropdown } from '../../../helpers/dropdown-folder.helper';
import { Report } from '../../../api/reports/Reports';
import _ from 'lodash';
import {
	convertToUnixTimestamp,
	unixTimestampToTimeString,
} from '../../date-period-selector/DatePeriodFormatter';
import {
	Schedule,
	ScheduleInterval,
	TNewSchedule,
	TSchedule,
} from '../../../api/schedules/Schedules';

export type AddEditScheduleModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSetSubSide?: Dispatch<React.SetStateAction<IConfig>>;
	existingReportSchedule?: TSchedule;
	setExistingReportSchedule?: Dispatch<React.SetStateAction<TSchedule | undefined>>;
	existingReport?: TReport;
};

function AddEditScheduleModal(props: AddEditScheduleModalProps) {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { addToast } = useToast();
	const [isScheduleDropdownOpen, setIsScheduleDropdownOpen] = React.useState<boolean>(false);
	const [isPeriodDropdownOpen, setIsPeriodDropdownOpen] = React.useState<boolean>(false);
	const [isPeriodTypeDropdownOpen, setIsPeriodTypeDropdownOpen] = React.useState<boolean>(false);
	const [scheduleName, setScheduleName] = React.useState<string>();
	const [scheduleDescription, setScheduleDescription] = React.useState<string>();
	const [descriptionError, setDescriptionError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [reportError, setReportError] = useState(false);
	const [time, setTime] = useState<string>('');
	const [timeError, setTimeError] = useState(false);
	const [chosenSchedule, setChosenSchedule] = React.useState<string>();
	const [chosenReportPeriod, setchosenReportPeriod] = useState<number>();
	const [chosenReportPeriodRange, setchosenReportPeriodRange] = useState<string>();
	const [showWeeklyConfig, setShowWeeklyConfig] = useState(false);
	const [showMonthlyConfig, setShowMonthlyConfig] = useState(false);
	const [showYearlyConfig, setShowYearlyConfig] = useState(false);
	const [showAdvancedConfig, setShowAdvancedConfig] = useState(false);
	const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState<
		SelectOptionObject | undefined
	>();
	const [intervalJson, setIntervalJson] = useState<string>();

	const [selectedDays, setSelectedDays] = useState<string[]>([]);
	const [selectedWeeks, setSelectedWeeks] = useState<string[]>([]);
	const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

	const [groupedReportItems, setGroupedReportItems] = useState<JSX.Element[]>([]);
	const [filteredItems, setFilteredItems] = useState<TreeViewDataItem[]>([]);
	const [selectedReport, setSelectedReport] = useState<TreeViewDataItem>();
	const isEdit = props.existingReportSchedule !== undefined;

	const handleDayChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { id, checked } = e.currentTarget;
		setSelectedDays((prev) => (checked ? [...prev, id] : prev.filter((day) => day !== id)));
	};

	const handleWeekChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { id, checked } = e.currentTarget;
		setSelectedWeeks((prev) => (checked ? [...prev, id] : prev.filter((day) => day !== id)));
	};

	const handleMonthChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { id, checked } = e.currentTarget;
		setSelectedMonths((prev) => (checked ? [...prev, id] : prev.filter((day) => day !== id)));
	};

	const days = [
		{ label: 'Sunday', value: 'sunday' },
		{ label: 'Monday', value: 'monday' },
		{ label: 'Tuesday', value: 'tuesday' },
		{ label: 'Wednesday', value: 'wednesday' },
		{ label: 'Thursday', value: 'thursday' },
		{ label: 'Friday', value: 'friday' },
		{ label: 'Saturday', value: 'saturday' },
	];

	const months = [
		{ value: 'january', label: 'January' },
		{ value: 'february', label: 'February' },
		{ value: 'march', label: 'March' },
		{ value: 'april', label: 'April' },
		{ value: 'may', label: 'May' },
		{ value: 'june', label: 'June' },
		{ value: 'july', label: 'July' },
		{ value: 'august', label: 'August' },
		{ value: 'september', label: 'September' },
		{ value: 'october', label: 'October' },
		{ value: 'november', label: 'November' },
		{ value: 'december', label: 'December' },
	];

	useMount(() => {
		BuildReportDropdown();
	});

	const fetchEditSchedule = useCallback(() => {
		//load edit values
		if (props.existingReportSchedule) {
			const existingSchedule = props.existingReportSchedule;

			setScheduleName(existingSchedule.name);
			setScheduleDescription(existingSchedule.description);

			const reportTreeViewDataItem: TreeViewDataItem = {
				id: props.existingReport?.id.toString(),
				name: props.existingReport?.name,
			};
			setSelectedReport(reportTreeViewDataItem);

			const timeString = unixTimestampToTimeString(existingSchedule.run_time);
			setTime(timeString);
			setChosenSchedule(existingSchedule.interval);

			const intervalJsonString = JSON.stringify(existingSchedule.interval_options);
			setIntervalJson(intervalJsonString);
			setchosenReportPeriod(existingSchedule.period);
			setchosenReportPeriodRange(existingSchedule.period_range);

			handleDropdownToggle(false, existingSchedule.interval);
			setCurrentSelectedPeriod;
		}
	}, [props.existingReportSchedule]);

	useEffect(() => {
		fetchEditSchedule();
	}, [fetchEditSchedule]);

	const updateSelectedIntervalJson = useCallback(() => {
		const updatedJsonObject = { selectedDays, selectedWeeks, selectedMonths };
		const jsonString = JSON.stringify(updatedJsonObject);
		setIntervalJson(jsonString);
	}, [selectedDays, selectedWeeks, selectedMonths]);

	useEffect(() => {
		updateSelectedIntervalJson();
	}, [updateSelectedIntervalJson]);

	useEffect(() => {
		if (props.existingReportSchedule?.interval_options) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
			const intervalObject = props.existingReportSchedule
				?.interval_options as unknown as ScheduleInterval;

			const existingDays = intervalObject.selectedDays;
			const existingWeeks = intervalObject.selectedWeeks;
			const existingMonths = intervalObject.selectedMonths;

			setSelectedDays(existingDays);
			setSelectedWeeks(existingWeeks);
			setSelectedMonths(existingMonths);
		}
	}, [props.existingReportSchedule?.interval_options]);

	useEffect(() => {
		if (!props.existingReportSchedule) {
			resetFormState();
		}
	}, [props.isOpen]);

	const BuildReportDropdown = () => {
		void Report.GetAll(['folder'], false).then((response) => {
			const responseItems: TReport[] = response;

			const groupedReports = _.groupBy(response, 'folder.id');
			const reportFolderKeys = Object.keys(groupedReports);
			const dropdownHtml: JSX.Element[] = [];
			const reportFolders = response.map((x) => x.folder as TFolder);
			const kvpReports: GenericStringKeyValueResponse<string>[] =
				buildFolderDisplayForDropdown(reportFolderKeys, reportFolders);

			kvpReports.forEach((folderKey) => {
				let itemsByKey: TReport[] = [];
				const folder = reportFolders.find((x) => x?.id == +folderKey.key);
				let folderName = folder?.name;

				//condition for when chart does not belong to a folder
				if (!folderName) {
					folderName = 'Unassigned';
					itemsByKey = response.filter((x) => (x?.folder as TFolder) === null);
				} else {
					itemsByKey = response.filter(
						(x) => (x?.folder as TFolder)?.id === +folderKey.key
					);
				}

				dropdownHtml.push(
					<DropdownGroup
						label={`Folder: ${folderKey.value != '' ? folderKey.value : 'Unassigned'}`}
						key={folderKey.key}
					>
						{itemsByKey.map((item) => (
							<DropdownItem
								id={item.id?.toString()}
								key={item.name}
							>
								{item.name}
							</DropdownItem>
						))}
					</DropdownGroup>
				);
			});
			setGroupedReportItems(dropdownHtml);

			const parsedItems: { id: number; name: string; display_name?: string | null }[] = [];

			responseItems.map((report) => {
				const parsedItem: { id: number; name: string; display_name?: string | null } = {
					id: report.id ?? 0,
					name: report.name,
				};
				parsedItems.push(parsedItem);
			});

			setFilteredItems(parsedItems.map((_) => BuildTreeViewItem(_, [])));
			setIsLoading(false);
		});
	};

	const resetFormState = () => {
		setNameError(false);
		setDescriptionError(false);
		setTimeError(false);
		setCurrentSelectedPeriod(undefined);
		setSelectedReport(undefined);
		setReportError(false);

		setShowWeeklyConfig(false);
		setShowMonthlyConfig(false);
		setShowYearlyConfig(false);
		setShowAdvancedConfig(false);
	};

	const handleDropdownToggle = (
		isOpen: boolean,
		selection: string | SelectOptionObject | undefined
	) => {
		setIsScheduleDropdownOpen(isOpen);
		setChosenSchedule(selection?.toString());

		if (selection) {
			setCurrentSelectedPeriod(selection);

			if (selection == 'WEEKLY') {
				setShowWeeklyConfig(true);
			} else {
				setShowWeeklyConfig(false);
			}

			if (selection == 'MONTHLY') {
				setShowMonthlyConfig(true);
			} else {
				setShowMonthlyConfig(false);
			}

			if (selection == 'ADVANCED') {
				setShowAdvancedConfig(true);
			} else {
				setShowAdvancedConfig(false);
			}

			if (selection == 'YEARLY') {
				setShowYearlyConfig(true);
			} else {
				setShowYearlyConfig(false);
			}
		}
	};

	const handleReportPeriodSelection = (selection: string | SelectOptionObject | undefined) => {
		const value = +(selection?.toString() ?? 0);
		setchosenReportPeriod(value);
	};

	const handlePeriodDropdownToggle = (isOpen: boolean) => {
		setIsPeriodDropdownOpen(isOpen);
	};

	const handlePeriodTypeDropdownToggle = (isOpen: boolean) => {
		setIsPeriodTypeDropdownOpen(isOpen);
	};

	const handleDescriptionChange = (value: string, event: React.FormEvent<HTMLInputElement>) => {
		setScheduleDescription(value);
		setDescriptionError(value.trim() === '');
	};

	const handleNameChange = (value: string, event: React.FormEvent<HTMLInputElement>) => {
		setScheduleName(value);
		setNameError(value.trim() === '');
	};

	const handleTimeChange = (newTime: string, isValid: boolean | undefined) => {
		setTime(newTime);
		setTimeError(newTime.trim() === '');
	};

	const selectReport = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem
	): void => {
		setSelectedReport(item);
		setReportError(false);
	};

	const onselectReportPeriodType = (selection: string | SelectOptionObject | undefined) => {
		setchosenReportPeriodRange(selection?.toString());
	};

	const saveReportSchedule = () => {
		let anyErrors = false;

		if (!scheduleName) {
			setNameError(true);
			anyErrors = true;
		}
		if (!scheduleDescription) {
			setDescriptionError(true);
			anyErrors = true;
		}
		if (!time) {
			setTimeError(true);
			anyErrors = true;
		}
		if (!selectedReport) {
			setReportError(true);
			anyErrors = true;
		}

		if (anyErrors) {
			return;
		} else {
			const formValues = {
				name: scheduleName ?? '',
				description: scheduleDescription ?? '',
				report: selectedReport ? +(selectedReport.id ?? 0) : 0,
				run_time: convertToUnixTimestamp(time),
				interval: chosenSchedule ?? 'DAILY',
				interval_options: intervalJson ?? '',
				period: chosenReportPeriod ?? 5,
				period_range: chosenReportPeriodRange ?? 'CURRENT',
			};

			if (props.existingReportSchedule && isEdit) {
				//edit
				const request: TSchedule = {
					id: props.existingReportSchedule.id,
					name: formValues.name,
					description: formValues.description,
					report: formValues.report,
					run_time: formValues.run_time,
					interval: formValues.interval,
					interval_options: formValues.interval_options,
					period: formValues.period,
					period_range: formValues.period_range,
				};

				Schedule.Patch(request)
					.then((_response) => {
						addToast('Report Schedule updated succesfully', AlertVariant.success);
						props.onClose();
						props.setExistingReportSchedule &&
							props.setExistingReportSchedule(undefined);
						navigate(`/report/schedules`);
					})
					.catch(() => {
						addToast(
							'An error occured while updating the Report Schedule',
							AlertVariant.danger
						);
					});
			} else {
				//add
				const request: TNewSchedule = {
					name: formValues.name,
					description: formValues.description,
					report: formValues.report,
					run_time: formValues.run_time,
					interval: formValues.interval,
					interval_options: formValues.interval_options,
					period: formValues.period,
					period_range: formValues.period_range,
				};

				Schedule.New(request)
					.then((_response) => {
						addToast('Report Schedule created succesfully', AlertVariant.success);
						props.onClose();
						props.setExistingReportSchedule &&
							props.setExistingReportSchedule(undefined);
						navigate(`/report/schedules`);
					})
					.catch(() => {
						addToast(
							'An error occured while creating the Report Schedule',
							AlertVariant.danger
						);
					});
			}
		}
	};

	const handleCronChange = (value: string) => {
		//clear previous selection states
		setSelectedDays([]);
		setSelectedWeeks([]);
		setSelectedMonths([]);

		try {
			const parts = value.split(' ');
			if (parts.length < 5) {
				return;
			}

			const [minute, hour, dayOfMonth, month, dayOfWeek] = parts;

			// Update Day of Month Checkboxes
			if (dayOfMonth === '*' || dayOfMonth === '?') {
				setSelectedDays(['every-day-month']);
			} else {
				const days = dayOfMonth.split(',').map((d) => `day-${d}`);
				setSelectedDays(days);
			}

			// Update Day of Week Checkboxes
			if (dayOfWeek === '*' || dayOfWeek === '?') {
				setSelectedWeeks(['every-day']);
			} else {
				const weekdaysMap: Record<string, string> = {
					'0': 'sunday',
					'1': 'monday',
					'2': 'tuesday',
					'3': 'wednesday',
					'4': 'thursday',
					'5': 'friday',
					'6': 'saturday',
				};
				const weekdays = dayOfWeek.split(',').map((d) => weekdaysMap[d]);
				setSelectedWeeks(weekdays);
			}

			// Update Month Checkboxes
			if (month === '*' || month === '?') {
				setSelectedMonths(['every-month']);
			} else {
				const monthsMap: Record<string, string> = {
					'1': 'january',
					'2': 'february',
					'3': 'march',
					'4': 'april',
					'5': 'may',
					'6': 'june',
					'7': 'july',
					'8': 'august',
					'9': 'september',
					'10': 'october',
					'11': 'november',
					'12': 'december',
				};
				const months = month.split(',').map((m) => monthsMap[m]);
				setSelectedMonths(months);
			}
		} catch (error) {
			console.error('Error parsing cron:', error);
		}
	};

	const daysCheckboxHtml = (
		<>
			<div className="every-day-container">
				<Checkbox
					label={'Every Day of Month'}
					id={'every-day-month'}
					name="every-day-month"
					isChecked={selectedDays.includes('every-day-month')}
					onChange={(checked, e) => {
						handleDayChange(e);
					}}
				/>
			</div>
			<div className="checkbox-grid">
				{[...(Array(31) as number[])].map((_, i) => {
					const dayId = `day-${i + 1}`;
					return (
						<Checkbox
							key={dayId}
							label={(i + 1).toString()}
							id={dayId}
							name="day"
							isChecked={selectedDays.includes(dayId)}
							onChange={(checked, e) => {
								handleDayChange(e);
							}}
						/>
					);
				})}
				<Checkbox
					key={'day-last'}
					label={'Last Day'}
					id={'day-last'}
					name="day-last"
					isChecked={selectedDays.includes('day-last')}
					onChange={(checked, e) => {
						handleDayChange(e);
					}}
				/>
			</div>
		</>
	);

	const weeklyCheckboxHtml = (
		<>
			<div className="every-day-container">
				<Checkbox
					label={'Every Day of Week'}
					id={'every-day'}
					name="day"
					isChecked={selectedWeeks.includes('every-day')}
					onChange={(checked, e) => {
						handleWeekChange(e);
					}}
				/>
			</div>
			<div className="checkbox-grid">
				{days.map((day) => (
					<Checkbox
						key={day.value}
						label={day.label}
						id={day.value}
						name="day"
						isChecked={selectedWeeks.includes(day.value)}
						onChange={(checked, e) => {
							handleWeekChange(e);
						}}
					/>
				))}
			</div>
		</>
	);

	const monthlyCheckboxHtml = (
		<>
			<div className="every-day-container">
				<Checkbox
					label={'Every Month'}
					id={'every-month'}
					name="month"
					isChecked={selectedMonths.includes('every-month')}
					onChange={(checked, e) => {
						handleMonthChange(e);
					}}
				/>
			</div>
			<div className="checkbox-grid">
				{months.map((month) => (
					<Checkbox
						key={month.value}
						label={month.label}
						id={month.value}
						name="month"
						isChecked={selectedMonths.includes(month.value)}
						onChange={(checked, e) => {
							handleMonthChange(e);
						}}
					/>
				))}
			</div>
		</>
	);

	const yearlyCheckboxHtml = [
		monthlyCheckboxHtml,
		<>
			{daysCheckboxHtml} <br />
		</>,
	];

	const advancedCheckboxHtml = [
		weeklyCheckboxHtml,
		<>
			{monthlyCheckboxHtml}
			<br />
		</>,
		<>
			{daysCheckboxHtml} <br />
		</>,
	];

	const emptyTree = [BuildTreeViewItem({ id: -1, name: `No Reports were found` }, [])];
	const treeViewData = dropdownToTreeViewData(groupedReportItems);

	return (
		<Modal
			title={`${!isEdit ? 'Create New' : 'Update Existing'}  Report Schedule`}
			variant={ModalVariant.medium}
			isOpen={props.isOpen}
			onClose={props.onClose}
			actions={[
				<Button
					data-testid="create-button"
					key="create"
					variant="primary"
					onClick={() => {
						saveReportSchedule();
					}}
				>
					Save
				</Button>,
				<Button
					data-testid="cancel-button"
					key="cancel"
					variant="link"
					onClick={props.onClose}
				>
					Cancel
				</Button>,
			]}
		>
			<div>
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<br />
						<Grid span={12}>
							<label
								htmlFor={'schedule-report'}
								className="input-label"
							>
								Report: <span className="pf-m-danger">*</span>
							</label>
							<GridItem span={12}>
								<span className="spacer-xs"></span>
								<SearchableTreeViewSelect
									className={`no-innerscroll ${reportError ? 'error' : ''}`}
									data-testid="report-tree"
									data={
										filteredItems && filteredItems.length > 0
											? treeViewData
											: emptyTree
									}
									placeHolderText={
										selectedReport?.name?.toString() ?? `Search for a Report`
									}
									onSelect={selectReport}
									treeItemsExpanded={true}
									isTopLvlSearchOnly={false}
									validated={reportError ? 'error' : 'default'}
								/>
							</GridItem>
						</Grid>
						<span className="spacer-xs"></span>
						<div className="inline-label-input">
							<label
								htmlFor={'schedule-name'}
								className="input-label"
							>
								Name:<span className="pf-m-danger">*</span>
							</label>
							<TextInput
								className={`form-control ptb-10 ${nameError ? 'error' : ''}`}
								value={scheduleName}
								id="schedule-name"
								onChange={handleNameChange}
								validated={nameError ? 'error' : 'default'}
								isRequired
							/>
							{nameError && (
								<small className="error-message">This field is required</small>
							)}
						</div>
						<div className="inline-label-input">
							<label
								htmlFor={'schedule-description'}
								className="input-label"
							>
								Description:<span className="pf-m-danger">*</span>
							</label>
							<TextInput
								className={`form-control ptb-10 ${descriptionError ? 'error' : ''}`}
								value={scheduleDescription}
								id="schedule-description"
								onChange={handleDescriptionChange}
								validated={descriptionError ? 'error' : 'default'}
								isRequired
							/>
							{descriptionError && (
								<small className="error-message">This field is required</small>
							)}
						</div>
						<Grid span={12}>
							<GridItem span={2}>
								<label
									htmlFor={'time-picker'}
									className="input-label"
								>
									Run at: <span className="pf-m-danger">*</span>
								</label>
							</GridItem>
							<GridItem span={5}>
								<TimePicker
									id={'time-picker'}
									name={'tdpRunAt'}
									className={timeError ? 'error-required' : ''}
									value={time}
									time={time}
									onChange={(e, time, hour, minute, seconds, isValid) => {
										handleTimeChange(time, isValid);
									}}
									menuAppendTo={'parent'}
								/>

								{timeError && (
									<>
										<br />
										<small className="error-message time-error">
											This field is required
										</small>
									</>
								)}
							</GridItem>
						</Grid>
						<br />
						<Grid span={12}>
							<GridItem span={2}>
								<label
									htmlFor={'time-picker'}
									className="input-label"
								>
									Schedule: <span className="pf-m-danger">*</span>
								</label>
							</GridItem>
							<GridItem span={3}>
								<Select
									variant={SelectVariant.single}
									onToggle={(isOpen) => handleDropdownToggle(isOpen, undefined)}
									onSelect={(event, selection) =>
										handleDropdownToggle(false, selection)
									}
									selections={currentSelectedPeriod ?? []}
									isOpen={isScheduleDropdownOpen}
									isDisabled={false}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{[
										<SelectOption
											value={'DAILY'}
											key={'daily'}
										>
											Daily
										</SelectOption>,
										<SelectOption
											value={'WEEKLY'}
											key={'weekly'}
										>
											Weekly
										</SelectOption>,
										<SelectOption
											value={'MONTHLY'}
											key={'monthly'}
										>
											Monthly
										</SelectOption>,
										<SelectOption
											value={'YEARLY'}
											key={'yearly'}
										>
											Yearly
										</SelectOption>,
										<SelectOption
											value={'QUARTERLY'}
											key={'quarterly'}
										>
											Quarterly
										</SelectOption>,
										<SelectOption
											value={'ADVANCED'}
											key={'advanced'}
										>
											Advanced
										</SelectOption>,
									]}
								</Select>
							</GridItem>
							{showAdvancedConfig && (
								<>
									<GridItem span={2}></GridItem>
									<GridItem
										span={5}
										style={{ display: 'inline-flex' }}
									>
										<label
											style={{ width: '144px' }}
											htmlFor={'cron-string'}
											className="input-label"
										>
											Cron String:
										</label>
										<TextInput
											className="form-control"
											id="cron-string"
											onChange={handleCronChange}
										/>
									</GridItem>
								</>
							)}
						</Grid>
						<br />
						{showWeeklyConfig && weeklyCheckboxHtml}
						{showMonthlyConfig && daysCheckboxHtml}
						{showYearlyConfig && yearlyCheckboxHtml}
						{showAdvancedConfig && advancedCheckboxHtml}
						<br />
						<Grid span={12}>
							<GridItem span={2}>
								<label
									htmlFor={'time-picker'}
									className="input-label"
								>
									Report Period: <span className="pf-m-danger">*</span>
								</label>
							</GridItem>
							<GridItem span={3}>
								<Select
									variant={SelectVariant.single}
									onToggle={(isOpen) => handlePeriodDropdownToggle(isOpen)}
									onSelect={(event, selection) => {
										handlePeriodDropdownToggle(false);
										handleReportPeriodSelection(selection);
									}}
									selections={chosenReportPeriod ?? []}
									isOpen={isPeriodDropdownOpen}
									isDisabled={false}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{[
										<SelectOption
											value={5}
											key={'day'}
										>
											Day
										</SelectOption>,
										<SelectOption
											value={4}
											key={'week'}
										>
											Week
										</SelectOption>,
										<SelectOption
											value={3}
											key={'month'}
										>
											Month
										</SelectOption>,
										<SelectOption
											value={1}
											key={'year'}
										>
											Year
										</SelectOption>,
										<SelectOption
											value={2}
											key={'quarter'}
										>
											Quarter
										</SelectOption>,
									]}
								</Select>
							</GridItem>
							<GridItem span={3}>
								<Select
									variant={SelectVariant.single}
									onToggle={(isOpen) => handlePeriodTypeDropdownToggle(isOpen)}
									onSelect={(event, selection) => {
										handlePeriodTypeDropdownToggle(false);
										onselectReportPeriodType(selection);
									}}
									selections={chosenReportPeriodRange ?? []}
									isOpen={isPeriodTypeDropdownOpen}
									isDisabled={false}
									direction={SelectDirection.down}
									menuAppendTo={() => document.body}
								>
									{[
										<SelectOption
											value={'CURRENT'}
											key={'current'}
										>
											Current
										</SelectOption>,
										<SelectOption
											value={'PRIOR'}
											key={'previous'}
										>
											Previous
										</SelectOption>,
									]}
								</Select>
							</GridItem>
						</Grid>
					</div>
				)}
			</div>
		</Modal>
	);
}

export default AddEditScheduleModal;
