import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bullseye, Flex, FlexItem, Grid, GridItem } from '@patternfly/react-core';
import React from 'react';
// import { PresentationSlide } from '../../types/presentation';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { PresentationSlide } from '../../api/present/PresentationSlide';

interface Props {
	displayOnly?: boolean;
	slides: PresentationSlide[];
	selectedPage: number;
	addNewPage: () => void;
	removePage: (index: number) => void;
	updateSelectedPage: (slideNumber: number) => void;
	isView?: boolean;
}

const PresentationPageList = (props: Props) => {
	const { slides, addNewPage, removePage, selectedPage, updateSelectedPage } = props;

	return (
		<Grid span={12}>
			{slides.map((slide, index) => {
				return (
					<GridItem span={10}>
						<Flex
							align={{ default: 'alignRight' }}
							justifyContent={{ default: 'justifyContentFlexEnd' }}
							className={`presentation-page-preview ${
								selectedPage === index ? 'selected' : ''
							}`}
							onClick={() => updateSelectedPage(index)}
						>
							<FlexItem
								style={{ position: 'absolute', marginRight: 0 }}
								// alignSelf={{ default: 'alignSelfFlexStart' }}
								// align={{ default: 'alignRight' }}
							>
								{!props.isView && (
									<FontAwesomeIcon
										icon={faTrash}
										className="delete-icon"
										onClick={(e) => {
											e.stopPropagation();
											removePage(index);
										}}
									/>
								)}
							</FlexItem>
							<Bullseye style={{ fontSize: '3rem', width: '100%' }}>
								{index + 1}
							</Bullseye>
						</Flex>
					</GridItem>
				);
			})}
			<GridItem span={10}>
				<Flex
					className="presentation-page-preview new"
					alignItems={{ default: 'alignItemsCenter' }}
					justifyContent={{ default: 'justifyContentCenter' }}
					onClick={() => addNewPage()}
				>
					<FontAwesomeIcon
						icon={faPlus}
						size="xl"
					/>
				</Flex>
			</GridItem>
		</Grid>
	);
};

export default PresentationPageList;
