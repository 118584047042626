import { CardProps } from '../../types/cards/card-props';
import './ZiCard.scss';
import {
	AlertVariant,
	Badge,
	Dropdown,
	DropdownItem,
	Flex,
	KebabToggle,
	Tooltip,
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderBlank, faThumbtack as faThumtackSolid } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import React from 'react';
import { CardTypesEnum } from '../../enums/card-types.enum';
import {
	faPenToSquare,
	faTrashAlt,
	faCube,
	faEye,
	faThumbTack,
	faCopy,
	faCalendar,
} from '@fortawesome/pro-regular-svg-icons';
import { Permission } from '../../enums/permission.enum';
import { Action } from '../table/ZiTable';
import { useMount } from 'react-use';
import { Folder } from '../../api/folders/Folder';
import { useIsGranted, useUser } from '../user/ApplicationProvider';
import DeleteConfirmationModal from '../../helpers/helper-components/DeleteConfirmationModal';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import AddFoldersModal, { AddFoldersModalProps } from '../modals/folders/AddFoldersModal';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useNavigate } from 'react-router';
import { Chart } from '../../api/charts/Chart';
import { Dataframe } from '../../api/dataframes/Dataframes';
import { Dashboard } from '../../api/dashbboards/Dashboards';
import { Report } from '../../api/reports/Reports';
import { faCircleUser } from '@fortawesome/pro-duotone-svg-icons';
import { Favorites } from '../../api/favorites/Favorites';
import { Present } from '../../api/present/Present';
import { ChartTypesEnum } from '../../enums/chart-types.enum';

function ZiCard(props: CardProps) {
	const [toUrl, setUrl] = useState<string>('');
	const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState<boolean>(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isFolderModalOpen, setIsFolderModalOpen] = useState<boolean>(false);
	const [actions, setActions] = useState<Action<any>[]>([]);
	const isGranted = useIsGranted();

	const hasEditChartPerm = isGranted(Permission.EditChart);
	const hasViewChartPerm = isGranted(Permission.ViewChart);
	const hasDeleteChartPerm = isGranted(Permission.DeleteChart);

	const hasEditReportPerm = isGranted(Permission.EditReport);
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const hasDeleteReportPerm = isGranted(Permission.DeleteReport);

	const hasEditTablePerm = isGranted(Permission.EditTable);
	const hasViewTablePerm = isGranted(Permission.ViewTable);
	const hasDeleteTablePerm = isGranted(Permission.DeleteTable);

	const hasEditFolderPerm = isGranted(Permission.EditFolder);
	const hasDeleteFolderPerm = isGranted(Permission.DeleteFolder);

	const hasEditDataframePerm = isGranted(Permission.EditDataframe);
	const hasViewDataframePerm = isGranted(Permission.ViewDataframe);
	const hasDeleteDataframePerm = isGranted(Permission.DeleteDataframe);

	const hasEditDashboardPerm = isGranted(Permission.EditDashboard);
	const hasViewDashboardPerm = isGranted(Permission.ViewDashboard);
	const hasDeleteDashboardPerm = isGranted(Permission.DeleteDashboard);

	const hasEditPresentationPerm = isGranted(Permission.EditPresentation);
	const hasViewPresentationPerm = isGranted(Permission.ViewPresentation);
	const hasDeletePresentationPerm = isGranted(Permission.DeletePresentation);

	const hasEditSchedulePerm = isGranted(Permission.EditReportSchedule);

	const [isOpen, setIsOpen] = React.useState(false);
	const { addToast } = useToast();
	const navigate = useNavigate();
	const isFavTab = !props.hidePin;
	const currentUser = useUser();
	const [pinIcon, setPinIcon] = useState(faThumbTack);

	const getActions = () => {
		let canEdit = false;
		let canView = false;
		let canDelete = false;
		let canCopy = false;
		let canEditSchedule = false;

		switch (props.type) {
			case CardTypesEnum.Chart:
				canEdit = hasEditChartPerm;
				canView = hasViewChartPerm;
				canDelete = hasDeleteChartPerm;
				canCopy = hasEditChartPerm;
				break;
			case CardTypesEnum.Dashboard:
				canEdit = hasEditDashboardPerm;
				canView = hasViewDashboardPerm;
				canDelete = hasDeleteDashboardPerm;
				canCopy = hasEditDashboardPerm;
				break;
			case CardTypesEnum.Presentation:
				canEdit = hasEditPresentationPerm;
				canView = hasViewPresentationPerm;
				canDelete = hasDeletePresentationPerm;
				canCopy = hasEditPresentationPerm;
				break;
			case CardTypesEnum.DataFrame:
				canEdit = hasEditDataframePerm;
				canView = hasViewDataframePerm;
				canDelete = hasDeleteDataframePerm;
				canCopy = hasEditDataframePerm;
				break;
			case CardTypesEnum.Folders:
				canEdit = hasEditFolderPerm;
				canDelete = hasDeleteFolderPerm;
				canCopy = false;
				break;
			case CardTypesEnum.Report:
				canEdit = hasEditReportPerm;
				canView = hasViewReportPerm;
				canDelete = hasDeleteReportPerm;
				canCopy = hasEditReportPerm;
				canEditSchedule = hasEditSchedulePerm;
				break;
			case CardTypesEnum.Table:
				canEdit = hasEditTablePerm;
				canView = hasViewTablePerm;
				canDelete = hasDeleteTablePerm;
				canCopy = hasEditTablePerm;
				break;
		}

		return [
			...(canView && !canEdit
				? [
						{
							name: (
								<>
									<FontAwesomeIcon icon={faEye} />
									View
								</>
							),
							callback: () => {
								onSelectAction(true);
							},
						},
				  ]
				: []),
			...(canEdit
				? [
						{
							name: (
								<>
									<FontAwesomeIcon icon={faPenToSquare} />
									Edit
								</>
							),
							callback: () => {
								onSelectAction();
							},
						},
				  ]
				: []),
			...(canDelete
				? [
						{
							name: (
								<>
									<FontAwesomeIcon icon={faTrashAlt} /> Delete
								</>
							),
							callback: () => {
								onSelectDelete();
							},
						},
				  ]
				: []),
			...(canCopy
				? [
						{
							name: (
								<>
									<FontAwesomeIcon icon={faCopy} /> Copy
								</>
							),
							callback: () => {
								onSelectCopy();
							},
						},
				  ]
				: []),
			...(canEditSchedule
				? [
						{
							name: (
								<>
									<FontAwesomeIcon icon={faCalendar} /> Schedule
								</>
							),
							callback: () => {
								onSelectEditSchedule();
							},
						},
				  ]
				: []),
		];
	};

	useMount(() => {
		setActions(getActions());
	});

	const onSelectDelete = () => {
		setIsDeleteFolderModalOpen(true);
	};

	const handleFolderModalToggle = () => {
		const toggleValue = !isFolderModalOpen;
		setIsFolderModalOpen(toggleValue);
	};

	const onSelectAction = (isView = false) => {
		switch (props.type) {
			case CardTypesEnum.Chart:
				navigate(
					isView ? `view/chart/${props.id!}` : `/analyze/charts/build/chart/${props.id!}`
				);
				break;
			case CardTypesEnum.Dashboard:
				navigate(`/analyze/dashboards/${props.id!}`);
				break;
			case CardTypesEnum.Presentation:
				navigate(`/present/view/${props.id!}`);
				break;
			case CardTypesEnum.DataFrame:
				navigate(
					isView
						? `analyze/dataframes/view/${props.id!}`
						: `/analyze/dataframes/edit/${props.id!}`
				);
				break;
			case CardTypesEnum.Folders:
				handleFolderModalToggle();
				break;
			case CardTypesEnum.Report:
				navigate(isView ? `/report/view/${props.id!}` : `/report/edit/${props.id!}`);
				break;
			case CardTypesEnum.Table:
				navigate(isView ? `/table/view/${props.id!}` : `/table/edit/${props.id!}`);
				break;
		}
	};

	const onSelectCopy = () => {
		switch (props.type) {
			case CardTypesEnum.Chart:
				navigate(`/analyze/charts/build/chart/${props.id!}?isCopy=true`);
				break;
			case CardTypesEnum.DataFrame:
				navigate(`/analyze/dataframes/edit/${props.id!}?isCopy=true`);
				break;
			case CardTypesEnum.Table:
				navigate(`/table/create?tableId=${props.id!}&isCopy=true`);
				break;
			case CardTypesEnum.Report:
				navigate(`/report/create?reportId=${props.id!}&isCopy=true`);
				break;
			case CardTypesEnum.Dashboard:
				navigate(`/analyze/dashboards/${props.id!}?isCopy=true`);
				break;
			case CardTypesEnum.Presentation:
				navigate(`/present/edit/${props.id!}?isCopy=true`);
				break;
		}
	};

	const onSelectEditSchedule = () => {
		navigate(`/report/schedules?reportId=${props.id!}`);
	};

	const handleMouseEnter = (isFocusEvent = false, id = 0) => {
		setIsHovered(true);

		if (isFocusEvent == false) {
			setIsOpen(false);
		}

		if (props.type == CardTypesEnum.Chart || props.type == CardTypesEnum.Dashboard) {
			void Favorites.Get(id, currentUser.id).then((response) => {
				if (response.showOnMain == true) {
					setPinIcon(faThumtackSolid);
				} else {
					setPinIcon(faThumbTack);
				}
			});
		}
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const closeDeleteModal = () => {
		setIsDeleteFolderModalOpen(false);
	};

	const DeleteFolder = () => {
		const folderId = props.id!;
		if (folderId != 0) {
			Folder.DeleteFolder(folderId)
				.then((response) => {
					closeDeleteModal();

					if (response.success) {
						props.refreshList && props.refreshList();
					}
				})
				.catch(() => {
					addToast('An error occured while Deleting the Folder', AlertVariant.danger);
				});
		}
	};

	const DeleteChart = () => {
		const chartId = props.id!;
		if (chartId != 0) {
			Chart.Delete(chartId)
				.then((response) => {
					closeDeleteModal();

					if (response.success) {
						props.refreshList && props.refreshList();
					}
				})
				.catch(() => {
					addToast('An error occured while Deleting the Chart', AlertVariant.danger);
				});
		}
	};

	const DeleteDataframe = () => {
		const dataframeId = props.id!;
		if (dataframeId != 0) {
			Dataframe.DeleteDataframe(dataframeId)
				.then((_response) => {
					closeDeleteModal();
					props.refreshList && props.refreshList();
				})
				.catch(() => {
					addToast(
						`Cannot delete dataframe "${props.title}", it is linked to one or more charts`,
						AlertVariant.danger
					);
				});
		}
	};

	const DeleteDashboard = () => {
		const dashboardId = props.id!;
		if (dashboardId != 0) {
			Dashboard.Delete(dashboardId)
				.then((_response) => {
					closeDeleteModal();
					props.refreshList && props.refreshList();
					addToast('Dashboard deleted succesfully', AlertVariant.success);
				})
				.catch(() => {
					addToast('An error occured while Deleting the Dashboard', AlertVariant.danger);
				});
		}
	};

	const DeletePresentation = () => {
		const presentationId = props.id!;
		if (presentationId != 0) {
			Present.Delete(presentationId)
				.then((_response) => {
					closeDeleteModal();
					props.refreshList && props.refreshList();
					addToast('Presentation deleted succesfully', AlertVariant.success);
				})
				.catch(() => {
					addToast(
						'An error occured while Deleting the Presentation',
						AlertVariant.danger
					);
				});
		}
	};

	const DeleteReport = () => {
		const reportId = props.id!;
		if (reportId != 0) {
			Report.Delete(reportId)
				.then((_response) => {
					closeDeleteModal();
					props.refreshList && props.refreshList();
				})
				.catch(() => {
					addToast(
						`An error occured while Deleting the ${
							props.type === CardTypesEnum.Report ? 'Report' : 'Table'
						}`,
						AlertVariant.danger
					);
				});
		}
	};

	const DeleteItem = () => {
		switch (props.type) {
			case CardTypesEnum.Chart:
				DeleteChart();
				break;
			case CardTypesEnum.Dashboard:
				DeleteDashboard();
				break;
			case CardTypesEnum.Presentation:
				DeletePresentation();
				break;
			case CardTypesEnum.DataFrame:
				DeleteDataframe();
				break;
			case CardTypesEnum.Folders:
				DeleteFolder();
				break;
			case CardTypesEnum.Report:
				DeleteReport();
				break;
			case CardTypesEnum.Table:
				DeleteReport();
				break;
		}
	};

	const RecordFolderId = () => {
		setUrl(props.url);

		setTimeout(() => {
			const url = window.location.href;
			const pathname = window.location.pathname;
			const segments = url.split('/');

			if (segments.indexOf('folder') > -1) {
				const lastSegment = segments[segments.length - 1];
				const folderId = lastSegment;

				localStorage.setItem('currentSelectedFolderId', folderId);

				if (url.includes('/analyze')) {
					localStorage.setItem('prevAnalyzePage', pathname.replace('/analyze/', ''));
				} else if (url.includes('/report')) {
					localStorage.setItem('prevReportPage', pathname);
				} else if (url.includes('/present')) {
					localStorage.setItem('prevPresentPage', pathname.replace('/present/', ''));
				} else if (url.includes('/analyze/folder') || url.includes('/analyze/dashboards')) {
					localStorage.setItem('prevDashboardPage', pathname.replace('/analyze/', ''));
				}
			}
			if (pathname.includes('/folder/')) {
				localStorage.setItem('currentNestedFolderPath', pathname);
			}
		}, 50);
	};

	useEffect(() => {
		setUrl(props.url);
	});

	const onToggle = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const onFocus = () => {
		const element = document.getElementById('toggle-kebab');

		if (element) element.focus();
	};

	const onSelect = () => {
		setIsOpen(false);
		onFocus();
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (event.key === 'Enter' || event.keyCode === 13) {
			onToggle(true);
		}
	};

	const actionsHtml = () => {
		const dropdownItems = actions.map((action, index) => {
			return (
				<DropdownItem
					key={index}
					tabIndex={index}
					component="button"
					role="menuitem"
					onClick={(e) => action.callback(e)}
				>
					{action.name}
				</DropdownItem>
			);
		});

		if (
			dropdownItems.length &&
			isHovered &&
			(!props.hideEllipsis || props.hideEllipsis === undefined)
		) {
			return (
				<div
					onClick={(e) => {
						setUrl('');
						e.stopPropagation();
						e.preventDefault();
					}}
				>
					<Dropdown
						onSelect={onSelect}
						toggle={
							<KebabToggle
								id="toggle-kebab"
								onToggle={onToggle}
								onKeyDown={handleKeyDown}
							/>
						}
						isOpen={isOpen}
						isPlain
						dropdownItems={dropdownItems}
						menuAppendTo="parent" // Ensures dropdown is fully visible
					/>
				</div>
			);
		}

		return;
	};

	const getFolderEnumType = () => {
		const url = window.location.pathname;
		const segments = url.split('/');
		const secondSegment = segments[2];

		return secondSegment as FolderTypesEnum;
	};

	const addFolderModalProps: AddFoldersModalProps = {
		isOpen: isFolderModalOpen,
		folderHeadingTitle: '',
		folderId: props.id,
		folderName: props.title,
		folderType: getFolderEnumType(),
		onClose: handleFolderModalToggle,
		onSetSubSide: () => ({}),
		mustNotRedirect: true,
		successNotification: 'Updated Folder Successfully',
		refreshList: props.refreshList,
	};

	const pinToHomepage = (
		event: React.MouseEvent<SVGSVGElement, MouseEvent>,
		entityId: number
	) => {
		event.preventDefault();

		void Favorites.Get(entityId, currentUser.id).then((response) => {
			const favourite = response;
			favourite.showOnMain = !favourite.showOnMain;
			void Favorites.Update(favourite).then(() => {
				addToast(
					`Favorite Succesfully ${
						!favourite.showOnMain ? 'Un-' : ''
					}Toggled for Home Page`,
					AlertVariant.success
				);
			});
		});
	};

	const cardType = Object.keys(CardTypesEnum)[Object.values(CardTypesEnum).indexOf(props.type)];
	const chartType = props.chartType
		? Object.keys(ChartTypesEnum)[Object.values(ChartTypesEnum).indexOf(props.chartType)]
		: '';
	const cardTooltip =
		props.type == CardTypesEnum.Category
			? props.title
			: `${props.title} - ${
					props.type == CardTypesEnum.Chart ? cardType + ': ' + chartType : cardType
			  }`;

	return (
		<React.Fragment>
			<Link
				to={toUrl}
				onClick={RecordFolderId}
				className="ziCard"
				data-testid={`zi-card-${props.title}`}
				onMouseEnter={() => handleMouseEnter(false, props.id)}
				onMouseLeave={handleMouseLeave}
				onFocus={() => handleMouseEnter(true, props.id)}
				title={cardTooltip}
			>
				<span>{props.title}</span>
				<div className="footer">
					<Flex direction={{ default: 'row' }}>
						{(props.isClustered == false || !props.isClustered) && (
							<span className="fa-stack fa-1x">
								<FontAwesomeIcon
									icon={props.icon}
									size="2x"
									className="non-stacked-icon"
									style={{
										color: props.icon === faFolderBlank ? '#6a6e73' : 'black',
									}}
								/>
								{props.isShared && (
									<Tooltip content={`Shared by: ${props.ownerName ?? 'none'}`}>
										<FontAwesomeIcon
											icon={faCircleUser}
											size="1x"
											className="fa-stack-1x fa-cx share-icon"
										/>
									</Tooltip>
								)}
							</span>
						)}
						{props.isClustered && (
							<span className="fa-stack fa-2x">
								<FontAwesomeIcon
									icon={props.icon}
									style={{
										color: props.icon === faFolderBlank ? '#6a6e73' : 'black',
									}}
									className="stacked-icon"
								/>
								<FontAwesomeIcon
									icon={faCube}
									size="1x"
									className="fa-stack-1x fa-cx stacked-icon"
								/>
							</span>
						)}
					</Flex>

					{props.isNew ? <Badge>New</Badge> : null}

					{actionsHtml()}

					{isHovered &&
						isFavTab &&
						(props.type == CardTypesEnum.Chart ||
							props.type == CardTypesEnum.Dashboard) && (
							<FontAwesomeIcon
								onClick={(e) => pinToHomepage(e, props.id!)}
								icon={pinIcon}
							/>
						)}
				</div>
			</Link>
			<DeleteConfirmationModal
				isOpen={isDeleteFolderModalOpen}
				onClose={closeDeleteModal}
				onSubmit={DeleteItem}
			/>
			<AddFoldersModal {...addFolderModalProps} />
		</React.Fragment>
	);
}

export default ZiCard;
