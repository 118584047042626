import { useEffect, useRef, useState } from 'react';
import { AlertVariant, Card, CardBody } from '@patternfly/react-core';
import {
	DataBuilderPropsV2,
	DraggableMenuItemData,
	Size,
} from '../../types/databuilder/databuilder';
import './BuildChart.scss';
import ZiChart, { ZiChartProps } from '../../components/charts/ZiChart';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { useParams, useOutletContext, useNavigate, useLocation } from 'react-router';
import { Dimension } from '../../api/analytics/Dimension';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { TUnitType } from '../../api/analytics/UnitType';
import { Dataframe, TNewDataframe, TNewDataframeOrder } from '../../api/dataframes/Dataframes';
import { TFolder, Folder } from '../../api/foundational-elements/Folder';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	DimSettings,
	LambdaInsightsRequest,
	TDataframe,
	TDataframeOrder,
	TDateRange,
} from '../../api/types';
import { MultipartResponse, formatCsvDataToColumns } from '../../helpers/multipart-response.helper';
import { OutletContext } from '../../layout/Layout';
import Preview, { IPreviewProps } from '../../components/data-builder/Preview';
import { Chart, TChart } from '../../api/charts/Chart';
import { parseInt } from 'lodash';
import { ChartSeriesChartTypes } from '../../types/charts/chart-options';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import { useApplication, useUser } from '../../../src/components/user/ApplicationProvider';
import Loader from '../../components/util/Loader';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { DimensionAttribute } from '../../api/analytics/DimensionAttribute';
import { TKeyMeasureFact } from '../../api/analytics/KeyMeasureFact';
import { Widget } from '../../api/dashbboards/DashboardWidgets';
import { ChartSeries, TChartSeries } from '../../api/chart-series/ChartSeries';
import { addNewRecentChart } from '../../helpers/helper-components/recents-factory-helper';
import { FilterOperatorEnum } from '../../enums/operators.enum';
import { useMount } from 'react-use';
import { ZiChartSettings } from '../../types/charts/chart-settings';
import _ from 'lodash';
import {
	buildDataframeRequest,
	populateDefaultDatRangeDim,
	populateDroppedFactsByDataframe,
	populateDroppedFactsByKeyMeasure,
	populateDroppedFactsByKeyMeasureFact,
	populateDroppedFilterByDimension,
	populateDroppedFilterByDimensionAttribute,
	populateDroppedFiltersByDashboardFilters,
	populateDroppedFiltersByDataframe,
	populateDroppedRowByDimension,
	populateDroppedRowByDimensionAttribute,
	populateDroppedRowsByDataframe,
	populateDroppedRowsByKeyMeasure,
	populateDroppedRowsByKeyMeasureFact,
} from '../../hooks/DataBuilderHooks';
import { DataBuilderTypes } from '../../enums/data-builder-types';
import ZiDataBuilderV2 from '../../components/data-builder/ZiDataBuilderV2';
import { TSharedEntity } from '../../api/shared-entity/SharedEntity';
import { getPreviewDataAndCalculateGrandTotal } from '../../helpers/chart.helper';
import { DatePeriodReformatter } from '../../components/date-period-selector/DatePeriodFormatter';
import { PeriodTypesEnum } from '../../enums/period-types-enum';
import { timestampToMMDDYYYY } from '../../utilities';
import { Favorites, TFavorites } from '../../api/favorites/Favorites';
import { FavoriteTypes } from '../../enums/favorite-types';
import { SortTypes } from '../../types/common/sort-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useCommonStoreContext } from '../../components/common-store/CommonStoreProvider';
import DeleteConfirmationModal from '../../helpers/helper-components/DeleteConfirmationModal';

export const BuildChart = () => {
	const {
		frameId,
		measureFactId,
		chartId,
		seriesId,
		seriesAttributeId,
		dashboardId,
		presentationId,
	} = useParams();
	const { setSubSide } = useOutletContext<OutletContext>();
	const navigator = useNavigate();
	const [previewData, setPreviewData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isPreviewLoading, setPreviewIsLoading] = useState<boolean>(true);
	const [isChartLoading, setIsChartLoading] = useState<boolean>(true);
	const [chartLoaded, setChartLoaded] = useState<boolean>(false);
	const [dataframe, setDataframe] = useState<TDataframe>();
	const [parentDataframe, setParentDataframe] = useState<TDataframe>();
	const [chart, setChart] = useState<TChart | null>(null);
	const [folders, setFolders] = useState<TFolder[]>([]);
	const { addToast } = useToast();
	const [keyMeasureFact, setkeyMeasureFact] = useState<TKeyMeasureFact | null | undefined>(null);
	const [hasMultipleSeries, sethasMultipleRecords] = useState<boolean>(false);
	const [isOdometer, setIsOdometer] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [chartRetrevialData, setChartRetrevialData] = useState<any[]>();
	const [dimConfigValues, setDimConfigValues] = useState<DimSettings[]>();
	const [defaultChartType, _setDefaultChartType] = useState<ChartSeriesChartTypes>(undefined);
	const [chartType, setChartType] = useState<ChartSeriesChartTypes>(defaultChartType);
	const [secondChartType, setSecondChartType] = useState<ChartSeriesChartTypes>(defaultChartType);
	const [favorite, setFavoriteData] = useState<TFavorites>();
	const [yAxisHeader, _setYaxisHeader] = useState<string | undefined>();
	const [xAxisHeader, setXaxisHeader] = useState<string | undefined>('Number (Total)');
	const [chartSettings, setChartSettings] = useState<ZiChartSettings>({
		chartColor: '',
		hideLegend: true,
		hideYAxis: false,
		limitResults: 0,
		showAverage: false,
		showDataValues: true,
		showPercentages: false,
		showScrollbar: false,
		showTargets: false,
		showValues: false,
		overrideValueDefinedColors: false,
	});
	const [order, setOrder] = useState<(TDataframeOrder | TNewDataframeOrder)[]>([]);
	const [selectedUnitType, setSelectedUnitType] = useState<TUnitType>();
	const [selected2ndUnitType, setSelected2ndUnitType] = useState<TUnitType>();
	const [selectedNumDecimals, setSelectedNumDecimals] = useState<number | undefined>(2);
	const [selected2ndNumDecimals, setSelected2ndNumDecimals] = useState<number | undefined>(2);
	const [overrideRequest, setOverrideRequest] = useState<TNewDataframe | undefined>(undefined);
	const [overrideRequestOld, setOverrideRequestOld] = useState<TNewDataframe | undefined>(
		undefined
	);

	const [isSingleSeries, setIsSingleSeries] = useState<boolean>(true);

	/* */
	const [droppedFacts, setDroppedFacts] = useState<DraggableMenuItemData[]>([]);
	const [droppedFields, setDroppedFields] = useState<DraggableMenuItemData[]>([]);
	const [droppedFilters, setDroppedFilters] = useState<DraggableMenuItemData[]>([
		{
			id: '-1',
			entityType: OptionsBuilderItemTypes.DatePeriodSelector,
			allowedZones: [],
			data: null,
			entity: {},
			itemType: 'date',
			static: true,
		},
	]);
	const [chartName, setChartName] = useState<string | undefined>('');
	const [chartFolder, setChartFolder] = useState<TFolder | undefined>();
	const [chartFolderId, setChartFolderId] = useState<number | undefined>();
	const [drillSequenceOpen, setDrillSequenceOpen] = useState(false);
	const [insightsOpen, setInsightsOpen] = useState(false);
	const [size, setSize] = useState<Size>();
	const [mustResetSelectedChart, setMustResetSelectedChart] = useState<boolean>(false);
	const [hasLoadedDroppedSeries, setHasLoadedDroppedSeries] = useState<boolean>(false);
	const [droppedFieldsCount, setDroppedFieldsCount] = useState<number>(0);
	const [droppedFactsCount, setDroppedFactsCount] = useState<number>(0);
	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedEndDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedEndPeriod
			? JSON.parse(previousSelectedEndPeriod)
			: null;
	const [chartSeriesData, setChartSeriesData] = useState<TChartSeries[]>([]);

	const { user, currentDatePeriods, measures, dimensions, unitTypes, periods } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [selectedDate, setSelectedDate] = useState<TNewDateRange | undefined>({
		begin_date:
			hasPreviouslySelectedPeriod && previouslySelectedStartDate
				? previouslySelectedStartDate?.begin_date
				: defaultDatePeriod?.begin_date ?? 0,
		end_date:
			hasPreviouslySelectedPeriod && previouslySelectedEndDate
				? previouslySelectedEndDate.end_date
				: defaultDatePeriod?.end_date ?? 0,
		period:
			hasPreviouslySelectedPeriod && previouslySelectedStartDate
				? previouslySelectedStartDate.period
				: defaultDatePeriod?.period ?? 0,
		sequence:
			hasPreviouslySelectedPeriod && previouslySelectedStartDate
				? previouslySelectedStartDate.period
				: defaultDatePeriod?.sequence ?? 0,
	});
	const [datePeriodSelectorOverride, setDatePeriodSelectorOverride] = useState<TDateRange>();
	const [datePeriodSelectorLabel, setDatePeriodSelectorLabel] = useState<string>();
	const [datePeriodSelectorDisabledMessage, setDatePeriodSelectorDisabledMessage] =
		useState<string>();

	// validation
	const [validated, setValidated] = useState<
		'default' | 'warning' | 'success' | 'error' | undefined
	>();

	const [drilldownSequenceItems, setDrilldownSequenceItems] = useState<DraggableMenuItemData[]>(
		[]
	);
	const [isLoadingDrillDown, setIsLoadingDrillDown] = useState(false);
	const [drillLevel, setDrillLevel] = useState(1);
	const [removeFilterIds, setRemoveFilterIds] = useState<number[]>();
	const [intialDrilldownSeriesItem, setIntialDrilldownSeriesItem] =
		useState<DraggableMenuItemData[]>();
	const [invalidFilters, setInvalidFilters] = useState<DraggableMenuItemData[]>([]);

	// Widget Related
	const [widgetRow, setWidgetRow] = useState<number>(0);

	const [showLimitWarning, setShowLimitWarning] = useState<boolean>(false);
	const [grandTotal, setGrandTotal] = useState<[{ [key: string]: number }]>([{}]);
	const prevChartSettings = usePrevious({ chartSettings });
	const currentUser = useUser();
	const [lambdaInsightsRequest, setLambdaInsightsRequest] = useState<LambdaInsightsRequest>();

	const pathname = useLocation().pathname;
	const queryParams = useLocation().search;
	const isView = pathname.includes('/view');
	const isCopy = queryParams.includes('isCopy=true');

	const { sharedDashboardFilters, setCurrentSelectedChart } = useCommonStoreContext();
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
	const isCopyAndNameProvided = isCopy ? chartName != '' : true;

	useMount(() => {
		if (dashboardId) {
			Widget.GetAll({ dashboard: dashboardId })
				.then((widgetsResponse) => {
					let row = widgetsResponse.length;
					if (row > 0) {
						row = widgetsResponse[row - 1].sizey + 1;
					}
					setWidgetRow(row);
				})
				.catch(() => {
					console.log('failed widget getall');
				});
		}
		initPageLoad();
	});

	useEffect(() => {
		initPageLoad();
	}, [window.location.pathname]);

	const initPageLoad = () => {
		setSubSide({ isLoading: true });
		setIsLoading(true);

		getPageData();

		if (chartId) {
			//log entry into recents for loaded chart
			addNewRecentChart(chartId);
		}
	};

	useEffect(() => {
		if (unitTypes.length > 0) {
			if (droppedFacts.length > 0) {
				const firstFactData = droppedFacts[0].data;
				const firstUnitType = unitTypes.find((type) => type.id === firstFactData?.unitType);
				setSelectedUnitType(firstUnitType);
				setSelectedNumDecimals(firstFactData?.numDecimals ?? chart?.num_decimals);
				setXaxisHeader(firstUnitType?.name);
			}

			if (droppedFacts.length > 1) {
				const secondFactData = droppedFacts[1].data;
				const secondUnitType = unitTypes.find(
					(type) => type.id === secondFactData?.unitType
				);
				setSelected2ndUnitType(secondUnitType);
				setSelected2ndNumDecimals(secondFactData?.numDecimals ?? 2);
			}
		}
	}, [droppedFacts]);

	useEffect(() => {
		if (dataframe) {
			if (chart) {
				setChartSettings({
					chartColor: chart.color_set,
					hideLegend: chart.hide_legend,
					hideYAxis: chart.hide_y_axis,
					limitResults: dataframe.datasets[0].data_limit ?? 0,
					showAverage: chart.show_average,
					showDataValues: chart.show_data_values,
					showPercentages: chart.show_percentages,
					showScrollbar: chart.show_scrollbar,
					showTargets: chart.show_targets,
					showValues: chart.show_targets,
					overrideValueDefinedColors: chart.override_value_defined_colors,
				});
			} else {
				setChartSettings({
					...chartSettings,
					limitResults: dataframe.datasets[0].data_limit ?? 0,
				});
			}
		}
	}, [dataframe]);

	useEffect(() => {
		if (chartSeriesData && dataframe) {
			const mappedChartSeries: DraggableMenuItemData[] = [];
			let initialItem: DraggableMenuItemData | null = null;
			if (dataframe.rowEntry && dataframe.rowEntry.length > 0) {
				if (dataframe.rowEntry[0].entity_type === OptionsBuilderItemTypes.Dimension) {
					initialItem = populateDroppedRowByDimension(
						dataframe.rowEntry[0].entity_id,
						dimensions
					);
				} else if (
					dataframe.rowEntry[0].entity_type === OptionsBuilderItemTypes.DimensionAttribute
				) {
					initialItem = populateDroppedRowByDimensionAttribute(
						dataframe.rowEntry[0].entity_id,
						dimensions
					);
				} else if (
					dataframe.rowEntry[0].entity_type === OptionsBuilderItemTypes.DateSeries
				) {
					initialItem = populateDefaultDatRangeDim();
				}
			}

			if (initialItem) {
				initialItem.icon = 'vertical-grip';
				initialItem.itemType = 'icon-text';
				initialItem.static = true;
				initialItem.drilldownActive = true;
			}

			chartSeriesData
				.sort((a, b) => a.sequence - b.sequence)
				.map((item) => {
					let dim: DraggableMenuItemData | null = null;
					if (item.entity_type == OptionsBuilderItemTypes.Dimension) {
						dim = populateDroppedRowByDimension(item.entity_id, dimensions);
					} else if (item.entity_type == OptionsBuilderItemTypes.DimensionAttribute) {
						dim = populateDroppedRowByDimensionAttribute(item.entity_id, dimensions);
					} else if (item.entity_type == OptionsBuilderItemTypes.DateSeries) {
						dim = populateDefaultDatRangeDim();
					}

					if (dim && dim.data) {
						dim.data.sequence = item.sequence;
						dim.icon = 'vertical-grip';
						dim.itemType = 'icon-text';
						mappedChartSeries.push(dim);
					}
				});

			if (initialItem) {
				setDrilldownSequenceItems([initialItem, ...mappedChartSeries]);
			} else {
				setDrilldownSequenceItems(mappedChartSeries);
			}
		}
	}, [dataframe, chartSeriesData]);

	useEffect(() => {
		if (
			droppedFields &&
			droppedFields.length > 0 &&
			drilldownSequenceItems &&
			drilldownSequenceItems.length > 0
		) {
			let firstField = droppedFields[0];
			const firstDrillItem = drilldownSequenceItems[0];

			if (firstField.data?.id != firstDrillItem.data?.id && drillLevel <= 1) {
				firstField = _.cloneDeep(firstField);
				firstField.icon = 'vertical-grip';
				firstField.itemType = 'icon-text';
				setDrilldownSequenceItems(
					drilldownSequenceItems.map((item) => {
						if (item.data?.id === firstDrillItem.data?.id) {
							item = firstField;
						}

						return item;
					})
				);
			}
		} else {
			setDrilldownSequenceItems([]);
			setIntialDrilldownSeriesItem([]);
		}
	}, [droppedFields]);

	// UseEffect purpose is to not display preview and chart before data is available
	useEffect(() => {
		if (previewData) {
			setPreviewIsLoading(false);
			setIsLoading(false);

			setSubSide({
				isLoading: false,
				isLeftSidebarCollapsable: true,
				startCollapsed: isView,
			});
		}
	}, [previewData]);

	const hasInvalidFilters = (showError: boolean) => {
		const filterValidations = droppedFilters.filter(
			(filter) =>
				filter.entityType != OptionsBuilderItemTypes.DatePeriodSelector &&
				filter.data &&
				((filter.data.isExistingValue && !filter.data.value) ||
					(!filter.data.isExistingValue && !filter.data.operator))
		);

		if (filterValidations.length > 0) {
			if (!_.isEqual(filterValidations, invalidFilters)) {
				setInvalidFilters(filterValidations);
			}

			showError &&
				addToast(
					'Please ensure that values have been set for all Dataframe filters.',
					AlertVariant.danger
				);

			return true;
		}

		setInvalidFilters([]);
		return false;
	};

	const getPageData = () => {
		Folder.GetAll()
			.then((folders) => {
				if (folders && folders.length > 0) {
					setFolders(folders.filter((f) => f.type === 'charts'));
				} else {
					setFolders([
						{
							id: -1,
							name: 'No folders found',
							type: 'charts',
							items: [],
						},
					]);
				}

				if (chartId) {
					getChartData();
				} else if (frameId) {
					getDataframeData();
				} else if (measureFactId && seriesId && seriesAttributeId) {
					const measure = measures.find((measure: TKeyMeasure) => {
						return measure.keyMeasureFacts.some((fact: TKeyMeasureFact) => {
							return fact.id === +measureFactId;
						});
					});

					const datasets = populateDroppedFactsByKeyMeasure(
						measure ? measure.id : 0,
						+measureFactId,
						measures,
						unitTypes
					);

					datasets.forEach((set) => {
						if (set.data) {
							set.data.unitType =
								typeof set.data.unitType === 'number' ? set.data.unitType : 0;
						}
					});

					const fields = populateDroppedRowsByKeyMeasure(
						dimensions,
						seriesId != '0' ? +seriesId : undefined,
						seriesAttributeId != '0' ? +seriesAttributeId : undefined
					);

					const childDataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
						datasets,
						fields,
						[],
						[],
						'',
						[],
						false,
						false,
						undefined,
						undefined,
						true
					);

					createParentDataframe(measureFactId, childDataframeRequest);
				}
			})
			.catch(() => {
				setIsLoading(false);
				addToast('There was an issue retrieving data', AlertVariant.danger);
			});
	};

	const createParentDataframe = (
		measureFactId: string,
		childDataframeRequest: TNewDataframe | TDataframe
	) => {
		const datasets = populateDroppedFactsByKeyMeasureFact(+measureFactId, measures, unitTypes);

		datasets.forEach((set) => {
			if (set.data) {
				set.data.unitType = typeof set.data.unitType === 'number' ? set.data.unitType : 0;
			}
		});

		const fields = populateDroppedRowsByKeyMeasureFact(+measureFactId, measures, dimensions);

		//work around for when creating a chart via selecting a key measure
		const dataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
			datasets,
			fields,
			[],
			[],
			'',
			[],
			false,
			false,
			undefined,
			undefined
		);

		Dataframe.NewDataframe(dataframeRequest as TNewDataframe)
			.then((response: TDataframe) => {
				createChildDataframe(childDataframeRequest as TNewDataframe, response.id);
			})
			.catch((): void => {
				addToast('Error creating parent dataframe.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const createChildDataframe = (dataframeRequest: TNewDataframe, parentDataframeId: number) => {
		//populate parentId on child dataframe
		dataframeRequest.parent = parentDataframeId;

		Dataframe.NewDataframe(dataframeRequest)
			.then((response: TDataframe): void => {
				//route to newly created dataframe Id for build chart
				setTimeout(() => {
					navigator(`/analyze`);
					setTimeout(() => {
						if (measureFactId) {
							if (dashboardId) {
								navigator(
									`/analyze/dashboards/${dashboardId}/chart/dataframe/${response.id}/${measureFactId}`
								);
							} else if (presentationId) {
								navigator(
									`/analyze/presentations/${presentationId}/chart/dataframe/${response.id}/${measureFactId}`
								);
							} else {
								navigator(
									`/analyze/charts/build/dataframe/${response.id}/${measureFactId}`
								);
							}
						}
					}, 100);
				}, 200);
			})
			.catch((): void => {
				addToast('Error creating child dataframe.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const getDataframeData = (dataframeId?: number) => {
		const id = dataframeId ?? frameId;
		if (id) {
			Dataframe.Get(+id, [
				'datasets',
				'filters',
				'rowEntry',
				'columnEntry',
				'order',
				'sharedDataframe',
				'folder',
			])
				.then((response) => {
					if (response.parent) {
						Dataframe.Get(response.parent, [
							'datasets',
							'filters',
							'rowEntry',
							'columnEntry',
							'order',
							'folder',
						])
							.then((parentResponse) => {
								setParentDataframe(parentResponse);
								setDataframe(response);
								if (response.order && response.order.length) {
									setOrder(response.order);
								} else {
									setOrder([
										{
											dataframe: response.id,
											direction: SortTypes.Asc,
											entity_id: response.rowEntry[0].entity_id,
											entity_type: response.rowEntry[0].entity_type,
											useSequence: false,
											pivotSort: '',
										},
									]);
								}

								setIsLoading(false);

								if (response.folder) {
									localStorage.setItem(
										'savedDataframeFolderId',
										response.folder.toString()
									);
								}
							})
							.catch(() => {
								addToast(
									'There was an issue retrieving the parent dataframe',
									AlertVariant.danger
								);
								setIsLoading(false);
								setSubSide({ isLoading: false, startCollapsed: isView });
							});
					} else {
						setDataframe(response);
						setOrder(response.order ? response.order : []);
						setIsLoading(false);
						if (response.folder) {
							localStorage.setItem(
								'savedDataframeFolderId',
								response.folder.toString()
							);
						}
					}
				})
				.catch(() => {
					addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
					setIsLoading(false);
					setSubSide({ isLoading: false, startCollapsed: isView });
				});
		}
	};

	const getPreviewData = (dataframeId?: number) => {
		const id = dataframeId ?? frameId;
		const newOverrideRequest: TNewDataframe | TDataframe = buildDataframeRequest(
			droppedFacts,
			droppedFields,
			[],
			droppedFilters,
			'override',
			order,
			false,
			true,
			dataframe
		);

		// prevents charts from re-rendering multiple times for the same/similar override requests
		if (newOverrideRequest && _.isEqual(overrideRequestOld, newOverrideRequest) && chartId) {
			return;
		}

		if (id) {
			setPreviewIsLoading(true);

			const dataRetrievalRequest: DataframeDataRetrievalRequest = {
				dataframeId: +id,
				begin_date: datePeriodSelectorOverride?.begin_date ?? selectedDate?.begin_date ?? 0,
				end_date: datePeriodSelectorOverride?.end_date ?? selectedDate?.end_date ?? 0,
				periodId: datePeriodSelectorOverride?.period ?? selectedDate?.period ?? 0,
			};

			if (newOverrideRequest && newOverrideRequest.datasets.length > 0) {
				dataRetrievalRequest.override = newOverrideRequest;
				if (chartSettings && dataRetrievalRequest.override.datasets.length) {
					dataRetrievalRequest.override.datasets[0].data_limit =
						chartSettings.limitResults;
				}
			}

			if (droppedFieldsCount > 0) {
				setChartLoaded(false);

				Dataframe.Retrieve(dataRetrievalRequest)
					.then((retrievalData) => {
						// Data Retrieval
						const formattedData = formatCsvDataToColumns(
							retrievalData.json?.columns ?? [],
							retrievalData.csvData ?? [],
							selectedDate?.period ?? 3
						);
						setChartRetrevialData(formattedData);
						setDimConfigValues(retrievalData.json?.dim_setting);

						if (!formattedData.length) {
							setChartLoaded(true);
						}

						getInsightsDataRequest(retrievalData);
						setPreviewAndGrandTotal(retrievalData);

						if (
							retrievalData.json?.result_size &&
							retrievalData.json?.result_size.limitReached == true &&
							(chartSettings.limitResults == 0 || chartSettings.limitResults > 500)
						) {
							setShowLimitWarning(true);
						} else if (
							retrievalData.json?.result_size &&
							retrievalData.json?.result_size.limitReached == false &&
							(chartSettings.limitResults > 0 || chartSettings.limitResults <= 500)
						) {
							setShowLimitWarning(false);
						}
					})
					.catch(() => {
						setIsLoading(false);
						setPreviewIsLoading(false);
						setChartLoaded(true);
						setSubSide({ isLoading: false, startCollapsed: isView });
						addToast(
							'There was an issue retrieving the dataframe data',
							AlertVariant.danger
						);
					});
			} else {
				setPreviewIsLoading(false);
			}
		}
	};

	const getInsightsDataRequest = (data: MultipartResponse<DataframeDataRetrievalResponse>) => {
		setLambdaInsightsRequest({
			metadata: {
				key_measures: droppedFacts.map((fact) => {
					return {
						name: fact.data?.title ?? '',
						description: '',
					};
				}),
				dimensions: droppedFields.map((dim) => {
					return {
						name: dim.data?.title ?? '',
						description: '',
					};
				}),
				columns: data.json?.columns ?? [],
				...getPeriodData(),
				filters_applied: droppedFilters
					.filter(
						(filter) => filter.entityType != OptionsBuilderItemTypes.DatePeriodSelector
					)
					.map((filter) => {
						return `${filter.data?.title ?? ''}: ${filter.data?.value ?? ''}`;
					}),
			},
			data: data.csvDataUnformatted ?? '',
			logging: {
				user_id: currentUser.id,
				entity_type: DataBuilderTypes.chart,
				entity_id: chart?.id ?? 0,
			},
		});
	};

	const getPeriodData = () => {
		let date: TNewDateRange | TDateRange | undefined;

		if (datePeriodSelectorOverride) {
			date = datePeriodSelectorOverride;
		} else {
			date = selectedDate;
		}

		const response = {
			time_period: '',
			time_range: {
				start_date: '',
				end_date: '',
			},
		};

		if (date) {
			response.time_range.start_date = timestampToMMDDYYYY(date.begin_date);
			response.time_range.end_date = timestampToMMDDYYYY(date.end_date);

			switch (date.period) {
				case 1: {
					response.time_period = PeriodTypesEnum.Year;
					break;
				}
				case 2: {
					response.time_period = PeriodTypesEnum.Quarter;
					break;
				}
				case 3: {
					response.time_period = PeriodTypesEnum.Month;
					break;
				}
				case 4: {
					response.time_period = PeriodTypesEnum.Week;
					break;
				}
				default: {
					response.time_period = PeriodTypesEnum.Month;
					break;
				}
			}
		}

		return response;
	};

	const getChartData = () => {
		if (chartId) {
			setIsLoadingDrillDown(true);
			Promise.all([
				Chart.GetOne(parseInt(chartId)),
				ChartSeries.GetAllForChart(parseInt(chartId)),
			])
				.then(([chartData, chartSeriesData]): void => {
					if (chartData) {
						setChart(chartData);
						setChartType(chartData.chart_type as ChartSeriesChartTypes);
						setCurrentSelectedChart(chartData);
						setSecondChartType(
							chartData.second_series_chart_type as ChartSeriesChartTypes
						);
						setSelectedNumDecimals(chartData.num_decimals);
						getDataframeData(chartData.dataframe);

						if (chartData.folder) {
							setChartFolderId(chartData.folder as number);
						}

						getFavoriteData();
					}

					if (chartSeriesData && chartSeriesData.length) {
						setChartSeriesData(chartSeriesData);
					}

					setIsLoadingDrillDown(false);
				})
				.catch((): void => {
					addToast('Error fetching chart data.', AlertVariant.danger);
					setIsLoading(false);
					setIsLoadingDrillDown(false);
				});
		}
	};

	const getFavoriteData = () => {
		if (chartId) {
			Favorites.Get(parseInt(chartId), currentUser.id)
				.then((response: TFavorites) => {
					if (response) {
						setFavoriteData(response);
					} else {
						setFavoriteData({
							user: currentUser.id,
							object_id: parseInt(chartId),
							type: FavoriteTypes.chart,
							name: chart?.name ?? '',
						});
					}
				})
				.catch(() => {
					addToast(
						'There was an issue setting the chart as a favorite',
						AlertVariant.danger
					);
				});
		}
	};

	useEffect(() => {
		updateMappedChartSeries();
	}, [isLoadingDrillDown]);

	useEffect(() => {
		const folder = folders.find((x) => x.id == chartFolderId);

		if (folder) {
			setChartFolder({
				id: chartFolderId ?? folder?.id ?? 0,
				items: folder?.items ?? [],
				name: folder?.name ?? '',
				type: folder?.type ?? 'chart',
			});
		}
	}, [folders, chartFolderId]);

	const updateMappedChartSeries = () => {
		if (dimensions.length > 0 && drilldownSequenceItems && drilldownSequenceItems.length > 0) {
			const newDrilldownItems: DraggableMenuItemData[] = [];

			drilldownSequenceItems.map((item) => {
				let dim: DraggableMenuItemData | null = null;
				if (item.entityType == OptionsBuilderItemTypes.Dimension) {
					dim = populateDroppedRowByDimension(item.data?.id ?? 0, dimensions);
				} else if (item.entityType == OptionsBuilderItemTypes.DimensionAttribute) {
					dim = populateDroppedRowByDimensionAttribute(item.data?.id ?? 0, dimensions);
				} else if (item.entityType == OptionsBuilderItemTypes.DateSeries) {
					dim = populateDefaultDatRangeDim();
				}

				if (dim && dim.data) {
					dim.data.sequence = item.data?.sequence;
					newDrilldownItems.push(dim);
				}
			});

			setDrilldownSequenceItems(newDrilldownItems);
		}
	};

	const checkForSharedFiltersBeforeSave = (): void => {
		if (dashboardId && chartId) {
			const sharedDashboardFilter = sharedDashboardFilters.find(
				(filter) => filter.id === +dashboardId
			);

			if (sharedDashboardFilter) {
				const sharedFilterItems = sharedDashboardFilter.widgets.find(
					(widget) => widget.itemId === +chartId
				);

				if (
					sharedFilterItems &&
					sharedFilterItems.filters.length &&
					droppedFilters.length &&
					(sharedFilterItems.filters.some((obj1) =>
						droppedFilters.some(
							(obj2) =>
								obj1.entity_id === obj2.data?.id &&
								obj1.entity_type === obj2.entityType
						)
					) ||
						sharedFilterItems.filters.some((obj1) =>
							droppedFilters.some(
								(obj2) =>
									obj1.entity_id === obj2.data?.id &&
									obj1.entity_type === obj2.entityType &&
									obj1.value === obj2.data?.value &&
									obj1.operator === obj2.data?.operator
							)
						))
				) {
					setShowConfirmationModal(true);
				} else {
					onSave();
				}
			}
		} else {
			onSave();
		}
	};

	const onSave = (): void => {
		setShowConfirmationModal(false);

		if (hasInvalidFilters(true)) {
			return;
		}

		if (!isCopyAndNameProvided) {
			setValidated('error');
			return;
		}

		const realChartType = chartType
			? chartType == 'odometer'
				? 'solidgauge'
				: chartType == 'treemapsquarified' || chartType == 'treemapstripes'
				? 'treemap'
				: chartType
			: 'column';

		const request: TChart = {
			name: chartName ?? '',
			chart_type: realChartType,
			second_series_chart_type: secondChartType,
			color_override: chartSettings.chartColor !== undefined,
			color_set: chartSettings.chartColor ?? '#0073FA, #3B95FF',
			dataframe: dataframe ? dataframe.id : frameId ? parseInt(frameId) : 0,
			folder: chartFolder ? chartFolder.id : null,
			future_periods: 0,
			hide_legend: chartSettings.hideLegend,
			hide_y_axis: chartSettings.hideYAxis,
			historical_periods: 0,
			lowest_period: 1,
			maxium: null,
			minumum: null,
			num_decimals: selectedNumDecimals ? selectedNumDecimals : 2,
			selected_data_point: null,
			share_common_y_axis: false,
			show_average: chartSettings.showAverage,
			show_data_values: chartSettings.showDataValues,
			show_percentages: chartSettings.showPercentages,
			show_scrollbar: chartSettings.showScrollbar,
			show_targets: chartSettings.showTargets,
			show_values: chartSettings.showValues,
			significant_digits: null,
			target_value: null,
			top_results: false,
			owner: user.id,
			period: 1,
			is_from_dataframe: chart
				? chart.is_from_dataframe
				: frameId && !measureFactId
				? true
				: false,
			override_value_defined_colors: chartSettings.overrideValueDefinedColors,
		};

		if (chartId && !isCopy) {
			request.id = parseInt(chartId);
		}

		setIsLoading(true);
		localStorage.removeItem('currentSelectedFolderId');

		if (chartId && !isCopy) {
			const dataframeRequest = buildDataframeRequest(
				droppedFacts,
				droppedFields,
				[],
				droppedFilters,
				chartName ?? '',
				order,
				false,
				true,
				dataframe,
				dataframe?.folder ? (dataframe.folder as TFolder) : undefined
			);

			if (chartSettings && dataframeRequest.datasets.length) {
				dataframeRequest.datasets[0].data_limit = chartSettings.limitResults;
			}

			Dataframe.PatchDataframe(dataframeRequest as TNewDataframe)
				.then((response: TDataframe): void => {
					request.dataframe = response.id;
					updateChart(request);
				})
				.catch((): void => {
					addToast('Error updating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		} else {
			//enters here if the chart does not exist yet but is part of a dataframe created via key measure selection
			if (measureFactId) {
				const dataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
					droppedFacts,
					droppedFields,
					[],
					droppedFilters,
					chartName ?? '',
					order,
					false,
					true,
					dataframe,
					dataframe?.folder ? (dataframe.folder as TFolder) : undefined
				);

				if (chartSettings && dataframeRequest.datasets.length) {
					dataframeRequest.datasets[0].data_limit = chartSettings.limitResults;
				}

				Dataframe.PatchDataframe(dataframeRequest as TNewDataframe)
					.then((response: TDataframe): void => {
						request.dataframe = response.id;
						createChart(request);
					})
					.catch((): void => {
						addToast('Error updating dataframe.', AlertVariant.danger);
						setIsLoading(false);
					});
			} else {
				const dataframeRequest = buildDataframeRequest(
					droppedFacts,
					droppedFields,
					[],
					droppedFilters,
					chartName ?? '',
					order,
					true,
					false,
					dataframe,
					dataframe?.folder ? (dataframe.folder as TFolder) : undefined,
					true
				);

				if (chartSettings && dataframeRequest.datasets.length) {
					dataframeRequest.datasets[0].data_limit = chartSettings.limitResults;
				}

				if (isCopy) {
					dataframeRequest.parent = dataframe?.parent;
				}

				Dataframe.NewDataframe(dataframeRequest as TNewDataframe)
					.then((response: TDataframe): void => {
						request.dataframe = response.id;
						getDataframeData(response.id);
						createChart(request);
					})
					.catch((): void => {
						addToast('Error creating dataframe.', AlertVariant.danger);
						setIsLoading(false);
					});
			}
		}
	};

	// Initialize dataframe data on dataframe change
	useEffect(() => {
		if (dataframe) {
			// Set Name
			if (!isCopy) {
				setChartName(chart?.name);
			}

			if (!chartId || !isCopyAndNameProvided) {
				setValidated('error');
			} else {
				setValidated('success');
			}

			const factsToSet = populateDroppedFactsByDataframe(dataframe, measures);

			// Set Dropped Fields
			const dimsToSet: DraggableMenuItemData[] = populateDroppedRowsByDataframe(
				dataframe,
				dimensions
			);

			dimsToSet.forEach((dim, index) => {
				if (dim.data) {
					dim.data.isVisible = index === 0 || chart ? true : false;
				}
			});

			if (frameId) {
				setDroppedFacts(factsToSet.length ? [factsToSet[0]] : []);
				setDroppedFields(dimsToSet.length ? [dimsToSet[0]] : []);
			} else {
				setDroppedFacts(factsToSet);
				setDroppedFields(dimsToSet);
			}
			setHasLoadedDroppedSeries(true);

			const dateRangeSelector: DraggableMenuItemData = {
				id: '-1',
				entityType: OptionsBuilderItemTypes.DatePeriodSelector,
				allowedZones: [],
				data: null,
				entity: {},
				itemType: 'date',
				static: true,
			};

			const sharedDashboardFilter = dashboardId
				? sharedDashboardFilters.find((filter) => filter.id === +dashboardId)
				: null;

			const sharedFilterItems =
				chartId && sharedDashboardFilter
					? sharedDashboardFilter.widgets.find((widget) => widget.itemId === +chartId)
							?.filters
					: [];

			setDroppedFilters([
				...[dateRangeSelector],
				...(dashboardId
					? populateDroppedFiltersByDashboardFilters(
							sharedFilterItems ?? [],
							dataframe,
							dimensions,
							false
					  )
					: populateDroppedFiltersByDataframe(dataframe, dimensions)),
			]);
		}
	}, [dataframe]);

	// Retrieve data for date or override request change
	useEffect(() => {
		const handler = setTimeout(() => {
			if (!hasInvalidFilters(false)) {
				getPreviewData(dataframe?.id);
			}
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [overrideRequest, datePeriodSelectorOverride, selectedDate, order]);

	function usePrevious(value: any) {
		const ref = useRef();
		useEffect(() => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			ref.current = value;
		});
		return ref.current;
	}

	const setPreviewAndGrandTotal = (
		previewData: MultipartResponse<DataframeDataRetrievalResponse> | undefined
	) => {
		const previewDataAndGrandTotal = getPreviewDataAndCalculateGrandTotal(
			droppedFacts,
			previewData,
			false,
			chartSettings?.showPercentages
		);

		setPreviewData(previewDataAndGrandTotal?.previewData);
		setGrandTotal(previewDataAndGrandTotal?.grandTotal);
	};

	useEffect(() => {
		if (overrideRequest) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const previousSettings = (prevChartSettings as any)?.chartSettings as
				| ZiChartSettings
				| undefined;

			//Add <fact> Percent of Total column for Preview table
			if (previousSettings?.showPercentages != chartSettings.showPercentages) {
				if (chartSettings.showPercentages) {
					setPreviewAndGrandTotal(previewData);
				} else {
					const clone = _.cloneDeep(previewData);

					droppedFacts?.forEach(() => {
						const keyIndexes: number[] = [];

						const cols = previewData?.json?.columns;
						if (cols) {
							cols[cols.length]?.forEach((col, index) => {
								if (col.replace(/\s+/g, '').includes('PercentofTotal')) {
									keyIndexes.push(index);
								}
							});
						}

						if (keyIndexes.length > 0) {
							keyIndexes.forEach(function (i) {
								if (typeof clone?.json?.columns[i] !== 'undefined')
									clone?.json?.columns.splice(i, 1);
							});
						}
					});

					setPreviewData(clone);
				}
			}

			const current = _.cloneDeep(overrideRequest);

			if (current.datasets.length) {
				if (!current.datasets[0].data_limit && chartSettings.limitResults > 0) {
					current.datasets[0].data_limit = chartSettings.limitResults;
					setOverrideRequest(current);
				}

				if (
					current.datasets[0].data_limit != undefined &&
					current.datasets[0].data_limit != chartSettings.limitResults
				) {
					current.datasets[0].data_limit = chartSettings.limitResults;
					setOverrideRequest(current);
				}
			}
		}
	}, [chartSettings]);

	// Set override request object
	useEffect(() => {
		if (!hasInvalidFilters(false)) {
			const newOverrideRequest: TNewDataframe | TDataframe = buildDataframeRequest(
				droppedFacts,
				droppedFields,
				[],
				droppedFilters,
				'override',
				order,
				false,
				true,
				dataframe,
				chartFolder
			);
			const localIsSingleSeries =
				droppedFields.filter((field) => field.data?.isVisible).length > 1 ? false : true;
			const localDroppedFieldsCount = droppedFields.filter(
				(field) => field.data?.isVisible
			).length;
			let changedToMultiSeries = false;

			// condition is handled for a null of first paramter in areTNewDataframeEqual
			if (newOverrideRequest && !_.isEqual(overrideRequest, newOverrideRequest)) {
				setOverrideRequestOld(overrideRequest);
				setOverrideRequest(newOverrideRequest as TNewDataframe);
			}

			setIsSingleSeries(
				droppedFields.filter((field) => field?.data?.isVisible).length > 1 ? false : true
			);
			setSize(undefined);
			if (hasLoadedDroppedSeries) {
				setMustResetSelectedChart(true);
			}

			if (localDroppedFieldsCount > droppedFieldsCount) {
				changedToMultiSeries = true;
			}

			if ((!intialDrilldownSeriesItem && droppedFields.length > 0) || changedToMultiSeries) {
				if (drillLevel > 1) {
					const ids = drilldownSequenceItems.map((item) => item.data?.id);
					droppedFields.forEach((item) => {
						if (
							!ids.includes(item.data?.id) &&
							intialDrilldownSeriesItem &&
							!intialDrilldownSeriesItem.find(
								(drillItem) => item.data?.id === drillItem.data?.id
							)
						) {
							setIntialDrilldownSeriesItem([...intialDrilldownSeriesItem, ...[item]]);
						}
					});
				} else {
					setIntialDrilldownSeriesItem(droppedFields);
				}
				changedToMultiSeries = false;
			}

			const measureFact = measures
				? measures
						.flatMap((measure) => measure.keyMeasureFacts)
						.find((fact) => fact.id === droppedFacts[0]?.data?.id)
				: null;

			setDroppedFieldsCount(localDroppedFieldsCount);
			setDroppedFactsCount(droppedFacts.length);
			setkeyMeasureFact(measureFact);

			const isNewChart = !chart && !chartId;

			const chartSeriesHasChanged =
				(localDroppedFieldsCount != droppedFieldsCount && droppedFieldsCount > 0) ||
				(chart?.chart_type == 'odometer' &&
					chartRetrevialData &&
					chartRetrevialData.length > 1) ||
				(isOdometer && hasMultipleSeries) ||
				(droppedFacts.length == 1 && chartType == 'columnrange');

			//if the data-retrieval response contains more than one record from the response and the saved charts chart_type was an odometer/solidguage
			//then default the chart to a vertical bar when the dropped fields count is less than two
			const isOdometerRetrievalFormat =
				localIsSingleSeries && hasMultipleSeries && chart && chart.chart_type == 'odometer';

			let chartTypeToSet: ChartSeriesChartTypes;
			// Default a multi series chart type to 'clusteredcolumn' and a single series chart type except for 'solidgauge' to 'column'
			if (localDroppedFieldsCount >= 2 && localIsSingleSeries == false) {
				chartTypeToSet = 'clusteredcolumn';
			} else if (
				(localDroppedFieldsCount <= 1 && localIsSingleSeries) ||
				isOdometerRetrievalFormat
			) {
				chartTypeToSet = 'column';
			}

			if (!isNewChart) {
				const realChartType =
					chartType == 'solidgauge'
						? 'odometer'
						: localIsSingleSeries && chartType == 'treemap'
						? 'treemapsquarified'
						: !localIsSingleSeries && chartType == 'treemap'
						? 'treemapstripes'
						: chartType;

				setChartType(realChartType);
			}

			// If the chart series count has changed or if the chart type is not set then set the chart type
			if (chartSeriesHasChanged || !chartType) {
				setChartType(chartTypeToSet);
			}

			if (droppedFacts.length > 1) {
				if (chart && chart.second_series_chart_type) {
					setSecondChartType(secondChartType);
				} else {
					setSecondChartType('line');
				}
			} else {
				setSecondChartType(defaultChartType);
			}
		}
	}, [droppedFacts, droppedFields, droppedFilters, hasMultipleSeries]);

	//The current level you are in is the drillLevel state variable - 1
	const ApplyDrilldownDrillIn = (name: string, isKMF: boolean) => {
		//cant drill any further if you have reached the bottom of your drill sequence items
		if (drillLevel == drilldownSequenceItems.length) {
			return;
		}

		//cant drill down if the chart hasnt been saved yet as chart series hasnt been saved either
		if (!chartId) {
			return;
		}

		//if the drill action is coming from the preview table we dont filter by Key Measure Facts currently
		if (isKMF) {
			return;
		}

		createNewFilterFromDrilldown(name, isKMF);
		updateDroppedFieldsForDrilldown();

		setDrillLevel((prev) => prev + 1);
	};

	const createNewFilterFromDrilldown = (name: string, isKMF: boolean) => {
		if (drilldownSequenceItems.length > 1) {
			const drilldownSequenceItemToUse: DraggableMenuItemData =
				drilldownSequenceItems[drillLevel - 1];
			const dimensionIdToUse =
				drillLevel == 1
					? droppedFields[0].data?.id ?? 0
					: drilldownSequenceItemToUse.data?.id ?? 0;
			const type =
				drillLevel == 1
					? droppedFields[0].entityType
					: drilldownSequenceItemToUse.entityType;

			if (!isKMF) {
				if (type == OptionsBuilderItemTypes.Dimension) {
					void Dimension.RetrievalWithType(dimensionIdToUse).then((response) => {
						const matchingFilterValue = response.items.find(
							(x) => x.value.toLowerCase() === name.toLowerCase()
						);

						const title =
							drillLevel == 1
								? droppedFields[0].data?.title
								: drilldownSequenceItemToUse.data?.title;

						const newFilterItem = populateDroppedFilterByDimension(
							dimensionIdToUse,
							dimensions
						);

						if (newFilterItem && newFilterItem.data) {
							newFilterItem.data.title = title ?? '';
							newFilterItem.data.drillable = false;
							newFilterItem.data.operator = FilterOperatorEnum.EQUALS;
							newFilterItem.data.value = matchingFilterValue?.id.toString();
							newFilterItem.data.isExistingValue = true;

							const filterIds: number[] = [dimensionIdToUse];
							filterIds.push(dimensionIdToUse);
							setRemoveFilterIds((prevIds) => [...(prevIds ?? []), ...filterIds]);
							setDroppedFilters((prev) => [...prev, { ...newFilterItem }]);
						}
					});
				}

				if (type == OptionsBuilderItemTypes.DimensionAttribute) {
					void DimensionAttribute.Retrieval(dimensionIdToUse).then((response) => {
						const matchingFilterValue = response.find(
							(x) => x.value.toLowerCase() === name.toLowerCase()
						);

						const title =
							drillLevel == 1
								? droppedFields[0].data?.title
								: drilldownSequenceItemToUse.data?.title ?? '';

						const newFilterItem = populateDroppedFilterByDimensionAttribute(
							dimensionIdToUse,
							dimensions
						);

						if (newFilterItem && newFilterItem.data) {
							newFilterItem.data.title = title ?? '';
							newFilterItem.data.drillable = false;
							newFilterItem.data.operator = FilterOperatorEnum.EQUALS;
							newFilterItem.data.value = matchingFilterValue?.id.toString();
							newFilterItem.data.isExistingValue = true;

							const filterIds: number[] = [dimensionIdToUse];
							filterIds.push(dimensionIdToUse);
							setRemoveFilterIds((prevIds) => [...(prevIds ?? []), ...filterIds]);
							setDroppedFilters((prev) => [...prev, { ...newFilterItem }]);
						}
					});
				}

				if (type == OptionsBuilderItemTypes.DateSeries) {
					if (selectedDate) {
						setDatePeriodSelectorDisabledMessage(
							'Please drill out if you would like to change the date filter.'
						);
						setDatePeriodSelectorLabel(name);
						setDatePeriodSelectorOverride(
							DatePeriodReformatter(name, selectedDate.period)
						);
					}
				}
			}
		}
	};

	const updateDroppedFieldsForDrilldown = () => {
		const fieldIdToRemove = droppedFields[0].data?.id;
		const drilldownSequenceItemToUse: DraggableMenuItemData =
			drilldownSequenceItems[drillLevel];

		if (drilldownSequenceItemToUse) {
			const remainingDroppedFields = droppedFields.filter(
				(x) => x.data?.id != fieldIdToRemove
			);

			let seriesToAdd: DraggableMenuItemData | null = null;

			if (
				drilldownSequenceItemToUse.entityType == OptionsBuilderItemTypes.DimensionAttribute
			) {
				seriesToAdd = populateDroppedRowByDimensionAttribute(
					drilldownSequenceItemToUse.data?.id ?? 0,
					dimensions
				);
			} else if (drilldownSequenceItemToUse.entityType == OptionsBuilderItemTypes.Dimension) {
				seriesToAdd = populateDroppedRowByDimension(
					drilldownSequenceItemToUse.data?.id ?? 0,
					dimensions
				);
			} else if (
				drilldownSequenceItemToUse.entityType == OptionsBuilderItemTypes.DateSeries
			) {
				seriesToAdd = populateDefaultDatRangeDim();
			}

			if (seriesToAdd && seriesToAdd.data) {
				seriesToAdd.data.drillable = false;
				seriesToAdd.data.title = drilldownSequenceItemToUse.data?.title ?? '';

				const newDroppedFields = [seriesToAdd, ...remainingDroppedFields];
				setDrilldownSequenceItems(
					drilldownSequenceItems.map((item) => {
						if (item.data?.id === fieldIdToRemove) {
							item.drilldownActive = false;
						} else if (item.data?.id === drilldownSequenceItemToUse.data?.id) {
							item.drilldownActive = true;
						}

						return item;
					})
				);
				setDroppedFields(newDroppedFields);
			}
		}
	};

	const resetDrillSequence = () => {
		const updatedFilterList: DraggableMenuItemData[] = droppedFilters.filter(
			(item) => !removeFilterIds?.includes(item.data?.id ? item.data.id : 0)
		);

		setDroppedFields(intialDrilldownSeriesItem!);
		setDroppedFilters(updatedFilterList);
		setDrilldownSequenceItems(
			drilldownSequenceItems.map((item, index) => {
				item.drilldownActive = false;
				if (index === 0) {
					item.drilldownActive = true;
				}

				return item;
			})
		);
		setDrillLevel(1);
		setDatePeriodSelectorOverride(undefined);
		setDatePeriodSelectorDisabledMessage(undefined);
		setDatePeriodSelectorLabel(undefined);
	};

	const saveChartSeries = (chartId: number | undefined) => {
		drilldownSequenceItems.forEach((drillSequence, index) => {
			if (index != 0) {
				const chartSeriesRequest: TChartSeries = {
					chart_id: chartId ?? 0,
					entity_id: drillSequence.data?.id ?? 0,
					entity_type: drillSequence.entityType,
					sequence: index,
				};
				void ChartSeries.New(chartSeriesRequest);
			}
		});
	};

	const createChart = (request: TChart): void => {
		Chart.New(request)
			.then((response: TChart): void => {
				if (dashboardId) {
					Widget.New({
						...Widget.Default(),
						name: response.name,
						dashboard: parseInt(dashboardId),
						chart: response.id,
						widget_type: 'chart',
						col: 0,
						row: widgetRow,
						sizex: 6,
						sizey: 6,
					})
						.then((_createWidgetResponse) => {
							navigator(`/analyze/dashboards/${dashboardId}`);
						})
						.catch(() => {
							addToast('Create dashboard widget failed.', AlertVariant.danger);
						});
				} else if (presentationId) {
					navigator(`/present/blank`);
				} else {
					navigator(`/analyze/charts/build/chart/${response.id ?? 0}`);
				}

				if (drilldownSequenceItems && drilldownSequenceItems.length > 0) {
					saveChartSeries(response.id);
				}

				setChart(response);
				setIsLoading(false);
				addToast('Created chart succesfully.', AlertVariant.success);
			})
			.catch((): void => {
				addToast('Error creating chart.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const updateChart = (request: TChart): void => {
		Chart.Update(request)
			.then((response: TChart): void => {
				if (dashboardId) {
					navigator(`/analyze/dashboards/${dashboardId}`);
				} else if (presentationId) {
					navigator(`/present/blank`);
				}

				const fetchedChartSeries: DraggableMenuItemData[] = [];

				ChartSeries.GetAllForChart(parseInt(chartId!))
					.then((chartSeries) => {
						chartSeries.map((item) => {
							if (item.entity_type == OptionsBuilderItemTypes.Dimension) {
								const dim = populateDroppedRowByDimension(
									item.entity_id ?? 0,
									dimensions
								);
								if (dim && dim.data) {
									dim.data.sequence = item.sequence;
									fetchedChartSeries.push(dim);
								}
							} else if (
								item.entity_type == OptionsBuilderItemTypes.DimensionAttribute
							) {
								const dim = populateDroppedRowByDimensionAttribute(
									item.entity_id ?? 0,
									dimensions
								);
								if (dim && dim.data) {
									dim.data.sequence = item.sequence;
									fetchedChartSeries.push(dim);
								}
							}
						});

						if (drilldownSequenceItems && drilldownSequenceItems.length > 0) {
							//delete old chart series and create anew if there is a new chart series and its not the same as the existing series
							if (
								fetchedChartSeries.length > 0 &&
								!_.isEqual(fetchedChartSeries, drilldownSequenceItems)
							) {
								chartSeries.map((item) => {
									void ChartSeries.Delete(item.chart ?? item.chart_id, item.id!);
								});
								saveChartSeries(response.id);
							}

							//save nett new chart series
							if (fetchedChartSeries.length == 0) {
								saveChartSeries(response.id);
							}

							//delete old chart series if they were all cleared out via UI
							if (
								fetchedChartSeries.length > 0 &&
								drilldownSequenceItems.length == 0
							) {
								chartSeries.map((item) => {
									void ChartSeries.Delete(item.chart ?? item.chart_id, item.id!);
								});
							}
						}
					})
					.catch((): void => {
						addToast('Error fetching chart series data.', AlertVariant.danger);
						setIsLoading(false);
					});

				setChart(response);
				addToast('Updated chart succesfully.', AlertVariant.success);
				setIsLoading(false);
			})
			.catch((): void => {
				addToast('Error updating chart.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const getChartSharedPermissions = () => {
		let userSharedRecord: { canEdit: boolean; canShare: boolean } = {
			canEdit: false,
			canShare: false,
		};
		if (!chart || currentUser.id === chart?.owner) {
			userSharedRecord = {
				canEdit: true,
				canShare: true,
			};
		} else {
			chart?.sharedChart?.map((sc) => {
				const thisUser = (sc as TSharedEntity).shared_entity_users?.find(
					(u) => u.user === currentUser.id
				);
				const thisGroup = (sc as TSharedEntity).shared_entity_groups?.find(
					(u) => currentUser.groups.findIndex((ug) => ug === u.group) > -1
				);
				if (thisUser) {
					userSharedRecord = { canEdit: thisUser.can_edit, canShare: thisUser.can_share };
				} else if (thisGroup) {
					userSharedRecord = {
						canEdit: thisGroup.can_edit,
						canShare: thisGroup.can_share,
					};
				}
			});
		}

		return userSharedRecord;
	};

	const chartProps: ZiChartProps = {
		isConfigMode: true,
		displayChart: true,
		title: chartName,
		categoryTitle: yAxisHeader ?? '',
		valueTitle: xAxisHeader,
		chartRetrevialData: chartRetrevialData ?? [],
		dimConfigValues: dimConfigValues ?? [],
		chartData: chart ?? undefined,
		isLoading,
		isDataRetrievalLoading: isPreviewLoading,
		setChartType,
		chartType: chartType,
		setSecondChartType,
		secondChartType: secondChartType,
		setChartSettings,
		chartSettings,
		setChart,
		setChartRetrevialData,
		unitType: selectedUnitType,
		setSelectedUnitType,
		selected2ndUnitType,
		selectedUnitType,
		selectedNumDecimals,
		selected2ndNumDecimals,
		isSingleSeries,
		setDrillSequenceOpen,
		setInsightsOpen,
		size: size ?? undefined,
		setMustResetSelectedChart,
		mustResetSelectedChart,
		droppedFieldsCount,
		droppedFactsCount,
		isExistingChart: chartId ? true : false,
		ApplyDrilldownDrillIn,
		drillLevel,
		resetDrillSequence,
		keyMeasureFact,
		sethasMultipleRecords,
		setIsOdometer,
		showLimitWarning,
		setShowLimitWarning,
		grandTotal: grandTotal,
		droppedFacts,
		droppedFields,
		unitTypes,
		chartRenderedCallback: () => {
			setTimeout(() => {
				setChartLoaded(true);
			}, 750);
		},
	};

	const builderProps: DataBuilderPropsV2 = {
		dataframe,
		parentDataframe,
		formLabel: 'Chart Name',
		onSave: checkForSharedFiltersBeforeSave,
		isEdit: chart ? true : false,
		isView,
		measures,
		dimensions,
		unitTypes,
		periods,
		folders,
		selectedDate,
		setSelectedDate,
		facts: droppedFacts,
		drills: drilldownSequenceItems,
		rows: droppedFields,
		filters: droppedFilters,
		invalidFilters,
		setFacts: setDroppedFacts,
		setDrills: setDrilldownSequenceItems,
		setRows: setDroppedFields,
		setFilters: setDroppedFilters,
		name: chartName ?? '',
		setName: setChartName,
		folder: chartFolder,
		setFolder: setChartFolder,
		sidebarHeader: 'Add Data',
		sidebarSubheader: 'Drag & drop data to add it to your chart',
		validated,
		setValidated,
		type: DataBuilderTypes.chart,
		hidePreviewBtn: true,
		drillSequenceOpen,
		setDrillSequenceOpen,
		insightsOpen,
		setInsightsOpen,
		isWidgetSave: dashboardId || presentationId ? true : false,
		isLoadingDrillDown,
		drillLevel,
		showLimitWarning: showLimitWarning,
		limitDimensionOptions: frameId ? true : chart?.is_from_dataframe,
		sharedPermissions: getChartSharedPermissions(),
		previewData,
		rowLimit: 2,
		datePeriodSelectorLabel,
		datePeriodSelectorDisabledMessage,
		hideConfigureCalculationOption: true,
		favorite: favorite,
		setFavorite: getFavoriteData,
		lambdaInsightsRequest,
		hideFolderSelection: isView,
	};

	const previewProps: IPreviewProps = {
		data: previewData,
		facts: droppedFacts,
		rows: droppedFields,
		chartType: chartType,
		unitTypes,
		isLoading: isPreviewLoading,
		setIsLoading: setPreviewIsLoading,
		order: order ?? dataframe?.order?.[0] ?? undefined,
		setOrder,
		setSelectedUnitType,
		ApplyDrilldownDrillIn,
		chartLoaded,
	};

	return (
		<>
			<Card className="chart-container">
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<>
							{isCopy && (
								<>
									<div className="pf-c-alert pf-m-inline pf-m-info">
										<p className="pf-c-alert__title display-inline">
											<FontAwesomeIcon
												size="2x"
												icon={faInfoCircle}
												className="bolder"
											/>
											<span className="nudge-right"></span>
											<div className="fs-large">
												{`Note! You are currently creating a copy of the chart '${
													chart?.name ?? ''
												}'`}
											</div>
										</p>
									</div>
									<br />
								</>
							)}

							<ZiDataBuilderV2 {...builderProps} />
							{chartLoaded == false && <Loader />}
							<div style={{ display: chartLoaded ? 'block' : 'none' }}>
								<ZiChart {...chartProps} />
								<Preview {...previewProps} />
							</div>
						</>
					)}
				</CardBody>
			</Card>
			<DeleteConfirmationModal
				isOpen={showConfirmationModal}
				onClose={() => {
					setShowConfirmationModal(false);
				}}
				onSubmit={onSave}
				titleText="Confirm Save"
				confirmLabel="Yes"
				cancelLabel="No"
				textLabel="Dashboard filters have been carried to this chart and will be saved. Do you want to continue and save the changes?"
			/>
		</>
	);
};
export default BuildChart;
