import React from 'react';
import { Backdrop, Bullseye, Spinner } from '@patternfly/react-core';
import './Loader.scss';

type Props = {
	size?: 'lg' | 'md' | 'xl' | 'sm' | undefined;
	isFullScreen?: boolean;
};

function Loader(props: Props) {
	const { size, isFullScreen } = props;

	if (isFullScreen) {
		return (
			<Backdrop style={{ zIndex: 10000000 }}>
				<Bullseye>
					<Spinner
						aria-label="Backdrop content"
						aria-valuetext="Loading..."
						size={size ?? 'xl'}
					/>
				</Bullseye>
			</Backdrop>
		);
	} else {
		return (
			<div
				className="loader"
				data-testid="loader"
			>
				<Spinner size={size ?? 'xl'} />
			</div>
		);
	}
}

export default Loader;
