import axiosConfig from '../axiosConfig';

export type TSchedule = {
	id: number;
	name: string;
	description: string;
	report: number;
	run_time: number;
	interval: string;
	interval_options: string;
	period: number;
	period_range: string;
	last_run_date?: string;
	next_run_date?: string;
};

export type ScheduleInterval = {
	selectedDays: string[];
	selectedMonths: string[];
	selectedWeeks: string[];
};

export type TNewSchedule = Omit<TSchedule, 'id' | 'last_run_date' | 'next_run_date'>;

export class Schedule implements TSchedule {
	id: number;
	name: string;
	description: string;
	report: number;
	run_time: number;
	interval: string;
	interval_options: string;
	period: number;
	period_range: string;
	last_run_date?: string;
	next_run_date?: string;

	constructor(schedule: TSchedule) {
		this.name = schedule.name;
		this.next_run_date = schedule.next_run_date;
		this.last_run_date = schedule.last_run_date;
		this.interval = schedule.interval;
		this.id = schedule.id;
		this.description = schedule.description;
		this.report = schedule.report;
		this.run_time = schedule.run_time;
		this.interval_options = schedule.interval_options;
		this.period = schedule.period;
		this.period_range = schedule.period_range;
	}

	public static New(report: TNewSchedule): Promise<TNewSchedule> {
		return axiosConfig.instance.post('app/report-schedule', report).then((res) => {
			return new Schedule(res.data as TSchedule);
		});
	}

	public static Delete(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/report-schedule/${id}`).then(() => {
			return true;
		});
	}

	public static Patch(report: TNewSchedule): Promise<TSchedule> {
		return axiosConfig.instance.patch('app/report-schedule', report).then((res) => {
			return new Schedule(res.data as TSchedule);
		});
	}

	public static Get(id: number, expand: (keyof TSchedule)[] = []): Promise<TSchedule> {
		return axiosConfig.instance
			.get<TSchedule>(`app/report-schedule/${id}`, { params: { expand } })
			.then((res) => {
				return res.data;
			});
	}

	public static GetAll(expand: (keyof TSchedule)[] = [], is_table = false): Promise<TSchedule[]> {
		return axiosConfig.instance
			.get<TSchedule[]>(`app/report-schedules`, { params: { expand } })
			.then((res) => {
				return res.data?.length > 0 ? res.data : [];
			});
	}
}
