import React, { ReactElement, useState } from 'react';
import { SSOMetadata } from '../../api/login/Login';
import { useMount } from 'react-use';
import { AlertVariant, Tooltip } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import './SamlMetadata.scss';
import CopyButton from '../../../src/components/button/CopyButton';

export default function SamlMetadata(): ReactElement {
	const [metadata, setMetadata] = useState<string>('');
	const { addToast } = useToast();

	useMount(() => {
		SSOMetadata()
			.then((response: string) => {
				setMetadata(response);
			})
			// eslint-disable-next-line unused-imports/no-unused-vars
			.catch(() => {
				addToast('Error fetching SSO Metadata', AlertVariant.danger);
			});
	});

	const copyToClipboard = () => {
		if (metadata) {
			navigator.clipboard
				.writeText(metadata)
				.then(() => {
					addToast('Metadata copied to clipboard! ', AlertVariant.success);
				})
				.catch(() => {
					addToast('Error copying to clipboard', AlertVariant.danger);
				});
		}
	};

	return (
		<div>
			<h1>
				SAML Metadata
				<span className="clipboard-icon">
					<Tooltip
						aria="none"
						content={'Copy to Clipboard'}
						flipBehavior={['bottom', 'bottom-end']}
					/>
					<CopyButton
						hasIcon={true}
						textToCopy={metadata}
					/>
				</span>
			</h1>
			<textarea
				className="metadata-container"
				value={metadata}
				readOnly
			/>
		</div>
	);
}
