import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { Period, TPeriod } from '../../api/analytics/Period';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { DateRange, TDateRange } from '../../api/analytics/DateRange';
import { useMount } from 'react-use';
import { timestampToMMDDYYYY } from '../../utilities';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

export default function DateRanges(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TDateRange[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeDateRange, setActiveDateRange] = useState<TDateRange>(
		DateRange.Default() as TDateRange
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [periods, setPeriods] = useState<TPeriod[]>([]);

	const selectedColumns: Column<TDateRange>[] = [
		{
			title: 'Title',
			columnName: 'title',
			sortable: true,
		},
		{
			title: 'Begin Date',
			columnName: 'begin_date',
			sortable: true,
			customAccessor: (item: TDateRange) => timestampToMMDDYYYY(item.begin_date),
		},
		{
			title: 'End Date',
			columnName: 'end_date',
			sortable: true,
			customAccessor: (item: TDateRange) => timestampToMMDDYYYY(item.end_date),
		},
		{
			title: 'Period',
			columnName: 'period',
			sortable: false,
			customAccessor: (item: TDateRange) => {
				if (typeof item.period === 'object') {
					return item.period.name;
				}

				return item.period;
			},
		},
		{
			title: 'ID',
			columnName: 'id',
			sortable: false,
		},
	];

	const actions: Action<TDateRange>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			callback: (item) => {
				setActiveDateRange(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditDateRange,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			callback: (item) => {
				setTableLoading(true);
				DateRange.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Date Range deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete the date range. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteDateRange,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Date Ranges"
					pageDescription="Manage your Date Ranges."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		DateRange.GetAll()
			.then((dateRange) => {
				setData(dateRange);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load date ranges. Please try again later.',
					AlertVariant.danger
				);
			});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	useMount(() => {
		Period.GetAll()
			.then((periods) => {
				setPeriods(periods);
				setIsFormLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load periods. Please try again later.',
					AlertVariant.danger
				);
			});
	});

	const handleSuccess = (value: TDateRange) => {
		const index = data.findIndex((dateRange) => dateRange.id === value.id);

		if (index >= 0) {
			setData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const addButton = (
		<PermissionButton
			data-testid={'dateRange-add-button'}
			variant={'primary'}
			permission={Permission.CreateDateRange}
			onClick={() => {
				setActiveDateRange(DateRange.Default() as DateRange);
				setIsModalOpen(true);
			}}
		>
			New Date Range
		</PermissionButton>
	);

	const formProperties: Field<TDateRange>[] = [
		{
			title: 'Title',
			columnName: 'title',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The title of the date range.',
			},
			required: true,
		},
		{
			title: 'Begin Date',
			columnName: 'begin_date',
			uiSchema: {
				type: UIType.DATE,
				placeholder: 'DD/MM/YYYY',
			},
			required: true,
		},
		{
			title: 'End Date',
			columnName: 'end_date',
			uiSchema: {
				type: UIType.DATE,
				placeholder: 'DD/MM/YYYY',
			},
			required: true,
		},
		{
			title: 'Period',
			columnName: 'period',
			uiSchema: {
				type: UIType.SELECT,
				options: periods.map((period) => ({
					value: period.name,
					key: period.id,
				})),
				onSelect: (value: ISelectOption) => {
					return Number(value.key);
				},
				initialSelection:
					typeof activeDateRange.period === 'object' ? activeDateRange.period.name : null,
			},
			required: true,
		},
	];

	const dateRangeTable = (
		<ZiTable<TDateRange>
			ariaLabel={'Date Range table'}
			columns={selectedColumns}
			data={data}
			caption="Date Ranges"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Date Range Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TDateRange>
					title={'Period'}
					fields={formProperties}
					initialSubject={activeDateRange}
					isLoading={isFormLoading}
					onSubmit={(dateRange) => {
						setIsFormLoading(true);

						dateRange.end_date = Number(dateRange.end_date);
						dateRange.begin_date = Number(dateRange.begin_date);

						if (dateRange.id) {
							DateRange.Update(dateRange)
								.then(() => {
									handleSuccess(dateRange);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save date range. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							DateRange.New(dateRange)
								.then(() => {
									handleSuccess(dateRange);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to create date range. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dateRangeTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
