import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Flex,
	FormGroup,
	Grid,
	GridItem,
	TextInput,
} from '@patternfly/react-core';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import PresentationPageList from './PresentationPageList';
import { PresentionationContext } from '../../types/presentation';
import { useOutletContext, useParams } from 'react-router';
import { GridLayoutOutletContext } from '../../layout/Layout';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { TWidget, Widget } from '../../api/dashbboards/DashboardWidgets';
import PresentationSlide from './PresentationSlide';
import { useMount } from 'react-use';
import { Folder, TFolder } from '../../api/foundational-elements/Folder';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import SelectFolderDropdown from '../../helpers/helper-components/SelectFolderDropdown';
import { Present } from '../../api/present/Present';
import { addNewRecentPresentation } from '../../helpers/helper-components/recents-factory-helper';
import DashboardFilters from '../analyze/dashboard/DashboardFilters';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { TPresentationSlide } from '../../api/present/PresentationSlide';
import PptxGenJS from 'pptxgenjs';
import html2canvas from 'html2canvas';
import Loader from '../../components/util/Loader';
import { useCommonStoreContext } from '../../components/common-store/CommonStoreProvider';

interface Props {
	pageContext: PresentionationContext;
}

const PresentationDetail = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { presentationId } = useParams();
	const { addToast } = useToast();
	const { gridLayout } = useOutletContext<GridLayoutOutletContext>();
	const [selectedSlide, setSelectedSlide] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [isExporting, setIsExporting] = useState<boolean>(false);
	// const [presentationModel, setPresentationModel] = useState<Present>({ ...Present.Default() });
	const presentationModel: Present = Present.Default();
	// if (presentationId) {
	// 	presentationModel =
	// 		pageContext.presentations.find((p) => p.id === parseInt(presentationId)) ??
	// 		presentationModel;
	// }
	const [currentSlide, setCurrentSlide] = useState<TPresentationSlide>(
		presentationModel.presentationSlides[selectedSlide]
	);
	const [pageModel, setPageModel] = useState<Present>(presentationModel);
	const [folders, setFolders] = useState<TFolder[]>([]);
	// const [widget, setWidget] = useState<Widget>();
	const [widgets, setWidgets] = useState<Widget[]>([]);
	const [unSavedChanges, setUnSavedChanges] = useState(false);
	const [existingPresentationName, setExistingPresentationName] = useState<string>();
	const [selectedDate, setSelectedDate] = useState<TNewDateRange>();
	const [dashboardFilters, setDashboardFilters] = useState<DashboardFilter[] | undefined>([]);
	const [dashboardFiltersToDelete, setDashboardFiltersToDelete] = useState<
		DashboardFilter[] | undefined
	>([]);

	const { setCurrentSelectedPresentation } = useCommonStoreContext();

	const updateSelectedDate = useCallback(
		(startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
			if (setSelectedDate) {
				setSelectedDate({
					begin_date: startDateRange.begin_date,
					end_date: endDateRange.end_date,
					period: startDateRange.period,
					sequence: startDateRange.sequence,
				});
			}
		},
		[selectedDate, setSelectedDate]
	);

	useMount(() => {
		getPageData();
	});

	useEffect(() => {
		getPageData();
	}, [window.location.pathname]);

	const getPageData = () => {
		Folder.GetAll({ type: 'presentations' })
			.then((response) => {
				if (response) {
					setFolders(response.filter((f) => f.type === 'presentations'));
				}
			})
			.catch(() => {
				addToast('Get folders failed.', AlertVariant.danger);
			});
		if (presentationId) {
			setLoading(true);
			Present.Get(parseInt(presentationId), ['presentationSlides'])
				.then((presentationResponse) => {
					if (presentationResponse) {
						setCurrentSelectedPresentation(presentationResponse);
						const newModel = { ...presentationModel, ...presentationResponse };

						const promiseAll: Promise<TPresentationSlide>[] = [];
						presentationResponse.presentationSlides.forEach((slide) => {
							if (typeof slide != 'number' && slide.id)
								promiseAll.push(
									Present.GetPresentationSlide(Number(slide.id), ['widgets'])
								);
						});

						Promise.all(promiseAll)
							.then((responses: TPresentationSlide[]) => {
								responses.forEach((slideResponse) => {
									const presentSlide = newModel.presentationSlides.find(
										(slide) => slide.id === slideResponse.id
									) as TPresentationSlide;
									presentSlide.widgets = (slideResponse.widgets as TWidget[]).map(
										(w) => {
											return { ...w, presentationSlide: presentSlide.id };
										}
									);
								});
								setPageModel(newModel);
								setExistingPresentationName(presentationResponse.name);
								setCurrentSlide({
									...newModel.presentationSlides[0],
								});
							})
							.catch((_) => {
								addToast('Failed to get Presentation Slides');
							});
					}
				})
				.catch(() => {
					addToast('Get presentation failed.', AlertVariant.danger);
				});

			//log entry into recents for loaded presentation
			addNewRecentPresentation(presentationId);
		}
	};

	const getHiddenDOM = (slides: TPresentationSlide[]): ReactElement[] => {
		return slides.map((slide) => (
			<PresentationSlide
				slide={slide}
				gridLayout={gridLayout}
				filters={dashboardFilters}
				selectedDate={selectedDate}
				isEdit={false}
				noAnimation={true}
				displayOnly
			/>
		));
	};

	const updateModel = useCallback(
		(widgets: Widget[]) => {
			pageModel.presentationSlides[selectedSlide].widgets = widgets;
			setPageModel(pageModel);
		},
		[pageModel, setPageModel, selectedSlide]
	);

	const addNewPage = useCallback(() => {
		pageModel.presentationSlides.push({
			widgets: [],
			page: pageModel.presentationSlides.length,
		});
		setPageModel(pageModel);
		setSelectedSlide(pageModel.presentationSlides.length - 1);
		setCurrentSlide({
			...pageModel.presentationSlides[pageModel.presentationSlides.length - 1],
		});
	}, [pageModel, setPageModel, selectedSlide, setSelectedSlide]);

	const removePage = useCallback(
		(index: number) => {
			pageModel.presentationSlides.splice(index, 1);
			if (selectedSlide + 1 === index && index > 1) {
				setSelectedSlide(index - 1);
				setCurrentSlide({ ...pageModel.presentationSlides[index - 1] });
			} else {
				setSelectedSlide(0);
				setCurrentSlide({ ...pageModel.presentationSlides[0] });
			}
			setPageModel(pageModel);
		},
		[pageModel, setPageModel, selectedSlide, setSelectedSlide]
	);

	const updateSelectedPage = useCallback(
		(slideNumber: number) => {
			setSelectedSlide(slideNumber);
			setCurrentSlide({ ...pageModel.presentationSlides[slideNumber] });
		},
		[pageModel, setPageModel, selectedSlide, setSelectedSlide, setCurrentSlide]
	);

	const handlePresentationWidgetChange = useCallback(
		(widget: Widget) => {
			!unSavedChanges && setUnSavedChanges(true);
			widget.page = selectedSlide;

			const newPageModel = { ...pageModel };
			if (widget.isNew && !widget.widgetChanged) {
				widget.id = newPageModel.presentationSlides[selectedSlide].widgets.length + 1;
				newPageModel.presentationSlides[selectedSlide].widgets = [
					...(newPageModel.presentationSlides[selectedSlide].widgets as Widget[]),
					widget,
				];
			} else {
				const newWidgets = [
					...(newPageModel.presentationSlides[selectedSlide].widgets as Widget[]),
				];

				let widgetIndex = (
					newPageModel.presentationSlides[selectedSlide].widgets as Widget[]
				).findIndex((w: Widget) => w.id === widget.id);
				if (widgetIndex < 0) {
					widgetIndex = newWidgets.length;
				}
				newWidgets[widgetIndex] = {
					...widget,
				};
				newPageModel.presentationSlides[selectedSlide].widgets = newWidgets;
			}
			updateModel(newPageModel.presentationSlides[selectedSlide].widgets as Widget[]);
			setCurrentSlide({ ...newPageModel.presentationSlides[selectedSlide] });
		},
		[pageModel, selectedSlide]
	);

	const handleChartEditClick = useCallback(
		(widget: Widget) => {
			if (widget.chart) {
				presentationModel.id &&
					navigate(`/present/${presentationModel.id ?? 0}/chart/${widget.chart}`);
			}
		},
		[presentationModel]
	);

	const removeWidget = useCallback((widget: Widget) => {
		setPageModel((currentPageModel) => {
			currentPageModel.presentationSlides[selectedSlide].widgets = [
				...(currentPageModel.presentationSlides[selectedSlide].widgets as Widget[]).filter(
					(w: Widget) => {
						return w.id != widget.id;
					}
				),
			];
			return currentPageModel;
		});
		setCurrentSlide(pageModel.presentationSlides[selectedSlide]);
	}, []);

	const handleNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
		setPageModel({ ...pageModel, name: value });
	};

	const handlePresentaionSave: (isPresentationSaveAndNavigate?: boolean) => void = (
		isPresentationSaveAndNavigate?: boolean
	) => {
		setUnSavedChanges(false);
		setLoading(true);

		let newPresentationId = 0;
		let presentationSlidesResponse: TPresentationSlide[] = [];
		const presentApiModel = getPresentModel();
		if (!presentApiModel.id) {
			Present.New(presentApiModel)
				.then((presentationResponse) => {
					newPresentationId = presentationResponse.id ?? 0;
					presentationSlidesResponse = presentationResponse.presentationSlides;
					setPageModel((currentModel) => {
						return { ...currentModel, id: newPresentationId };
					});
					navigate(`/present/edit/${newPresentationId}`);
				})
				.catch((_) => {
					setLoading(false);
					addToast('Presentation create failed.', AlertVariant.danger);
				});
		} else {
			Present.Edit(presentApiModel)
				.then((_editResponse) => {
					setLoading(false);
					addToast('Presentation updated.', AlertVariant.success);
				})
				.catch((_) => {
					setLoading(false);
					addToast('Presentation update failed.', AlertVariant.danger);
				});
		}
	};

	const getPresentModel = (): Present => {
		const presentModel = Present.Default();
		presentModel.id = pageModel.id;
		presentModel.name = pageModel.name;
		presentModel.owner = pageModel.owner;
		presentModel.folder = pageModel.folder;
		presentModel.presentationSlides = pageModel.presentationSlides.map((s) => {
			return {
				page: s.page,
				presentation: s.presentation,
				id: s.id,
				widgets: s.widgets.map((w) => {
					const thisWidget = w as Widget;
					return {
						id: thisWidget.isNew ? undefined : thisWidget.id,
						presentationSlide: thisWidget.presentationSlide,
						col: thisWidget.col,
						row: thisWidget.row,
						sizex: thisWidget.sizex,
						sizey: thisWidget.sizey,
						page: thisWidget.page,
						name: thisWidget.name,
						widget_type: thisWidget.widget_type,
						content: thisWidget.content,
						chart: thisWidget.chart,
						report: thisWidget.report,
						source: thisWidget.source,
						isNew: thisWidget.isNew,
						widgetChanged: thisWidget.widgetChanged,
						isEdit: thisWidget.isEdit,
						conditionalRules: thisWidget.conditionalRules,
					};
				}),
			};
		});
		presentModel.presentationFilters = (dashboardFilters ?? []).map((df) => {
			const filter: DashboardFilter = { ...df };
			if (df.isNew) {
				filter.id = undefined;
				filter.isNew = false;
			}
			return filter;
		});
		return presentModel;
	};

	const createPresentation = () => {
		setIsExporting(true);
		setTimeout(() => {
			const pptx = new PptxGenJS();
			pptx.author = 'ZeroedIn Technologies, LLC';
			pptx.company = 'ZeroedIn Technologies, LLC';
			pptx.revision = '1';
			pptx.subject = pageModel.name;
			pptx.title = pageModel.name;

			const imagePromises: Promise<void>[] = [];
			const hiddenSlide = document.querySelector(
				'#presentation-hidden-render .react-grid-layout'
			);
			const slideWidth = (hiddenSlide as HTMLElement).offsetWidth;
			const slideHeight = (hiddenSlide as HTMLElement).offsetHeight;
			const widthScaleFactor = 10 / calculateInches(slideWidth);
			const heightScaleFactor = 5.625 / calculateInches(slideHeight);
			pageModel.presentationSlides.forEach((s) => {
				// Default Size: 10 x 5.625 inches
				const slide = pptx.addSlide();
				(s.widgets as Widget[]).forEach((w: Widget) => {
					const horizontalPaddingInches = calculateInches(10) * widthScaleFactor;
					const verticalPaddingInches = calculateInches(10) * heightScaleFactor;
					if (w.widget_type === 'text') {
						const textObjects = htmlToText(w.content ?? '');
						const texts: PptxGenJS.TextProps[] = [];
						textObjects.forEach((textObject: TextType) => {
							const isBold = textObject.format?.includes('strong');
							const isItalic = textObject.format?.includes('em');
							const isUnderlined = textObject.format?.includes('ins');
							texts.push({
								text: textObject.text ?? '',
								options: {
									bold: isBold,
									italic: isItalic,
									underline: isUnderlined ? { style: 'sng' } : { style: 'none' },
									fontSize: textObject.fontSize,
								},
							});
						});

						slide.addText(texts, {
							x: calculateInches(w.col * 75) + horizontalPaddingInches,
							y: calculateInches(w.row * 40) + verticalPaddingInches,
							w: calculateInches(w.sizex * 73),
							h: calculateInches(w.sizey * 42) * heightScaleFactor,
							valign: 'top',
						});
					} else if (w.widget_type == 'chart') {
						// TODO: Add slide to chart
						const chartElem = document.querySelector(
							`#presentation-hidden-render #widget-${w.id ?? 0}`
						);
						if (chartElem) {
							const chartWidthinPX = (chartElem as HTMLElement).offsetWidth;
							const chartHeightinPX = (chartElem as HTMLElement).offsetHeight;
							imagePromises.push(
								html2canvas(chartElem as HTMLElement).then(
									(canvas: HTMLCanvasElement) => {
										slide.addImage({
											x:
												calculateInches(w.col * 80) +
												horizontalPaddingInches,
											y:
												calculateInches(w.row * 36) +
												horizontalPaddingInches,
											w: calculateInches(chartWidthinPX) * widthScaleFactor,
											h: calculateInches(chartHeightinPX) * heightScaleFactor,
											data: canvas.toDataURL(),
										});
									}
								)
							);
						}
					} else if (w.widget_type == 'pivot_table') {
						// TODO: Add slide to chart
						const chartElem = document.querySelector(
							`#presentation-hidden-render #widget-${w.id ?? 0}`
						);
						if (chartElem) {
							const chartWidthinPX = (chartElem as HTMLElement).offsetWidth;
							const chartHeightinPX = (chartElem as HTMLElement).offsetHeight;
							imagePromises.push(
								html2canvas(chartElem as HTMLElement).then(
									(canvas: HTMLCanvasElement) => {
										slide.addImage({
											x:
												calculateInches(w.col * 80) +
												horizontalPaddingInches,
											y:
												calculateInches(w.row * 36) +
												horizontalPaddingInches,
											w: calculateInches(chartWidthinPX) * widthScaleFactor,
											h: calculateInches(chartHeightinPX) * heightScaleFactor,
											data: canvas.toDataURL(),
										});
									}
								)
							);
						}
					}
				});
			});
			Promise.allSettled(imagePromises)
				.then(() => {
					pptx.writeFile({
						fileName: `${pageModel.name.replaceAll(' ', '-')}.pptx`,
					})
						.then(() => {
							setIsExporting(false);
						})
						.catch(() => {
							console.log('export to pptx failed');
						});
				})
				.catch(() => {
					console.log('export to pptx failed');
				});
		}, 1000);
	};

	const calculateInches = (cssPixels: number) => {
		const devicePixelRatio = window.devicePixelRatio;
		const dotsPerInch = 96;
		const physicalPixels = cssPixels * devicePixelRatio;
		return physicalPixels / dotsPerInch;
	};

	const htmlToText = (htmlString: string): any[] => {
		const results: any[] = [];
		if (htmlString) {
			const nodes = new DOMParser().parseFromString(htmlString, 'text/html').body.childNodes;

			nodes.forEach((node) => {
				results.push([...getText(node)]);
			});
		}

		return results.flat();
	};

	const FORMAT_NODES = ['strong', 'em', 'ins'];
	const getText = (
		node: ChildNode,
		parents: string[] = [],
		res: TextType[] = [],
		fontSize?: string
	): TextType[] => {
		if (node.nodeName === '#text') {
			const text = node.textContent;
			if (text) {
				const format = parents.filter((p) => FORMAT_NODES.includes(p));
				res.push({
					text,
					format: format.length ? format : null,
					fontSize: fontSize ? parseInt(fontSize) - 5 : 16 - 5,
				});
			}
		} else {
			node.childNodes.forEach((node: ChildNode) => {
				const newFontSize = (node as HTMLElement).style?.fontSize;
				if (newFontSize) {
					fontSize = newFontSize.replace('px', '');
				}
				getText(node, parents.concat(node.nodeName.toLowerCase()), res, fontSize);
			});
		}
		return res;
	};

	const updateFiltersToDelete = (filter: DashboardFilter) => {
		setDashboardFiltersToDelete([...(dashboardFiltersToDelete ?? []), filter]);
	};
	const updateFilters = (filters: DashboardFilter[]) => {
		setDashboardFilters(filters);
	};

	interface TextType {
		text: string;
		format: any[] | null;
		fontSize: number;
	}

	return (
		<>
			{isExporting && <Loader isFullScreen />}
			<Card
				className={'present-details-container'}
				style={{ marginBottom: '0.24rem' }}
			>
				<CardBody style={{ paddingBottom: '1.5rem' }}>
					<Grid
						className="row-container"
						span={12}
					>
						<div className="start-container">
							<FormGroup
								label={'Presentation Name'}
								type="text"
								isRequired
								fieldId="name"
								helperTextInvalid={'Presentation Name is required'}
								helperTextInvalidIcon={<ExclamationCircleIcon />}
							>
								<TextInput
									isRequired
									type="text"
									aria-label={'Enter a Presentation Name'}
									placeholder={'Enter a Presentation Name'}
									value={pageModel.name}
									onChange={handleNameChange}
								/>
							</FormGroup>
							<SelectFolderDropdown
								folderId={pageModel.folder as number}
								folders={folders}
								onFolderSelect={(e, item) => {
									setPageModel({
										...pageModel,
										folder: parseInt(item.id ?? '0'),
									});
								}}
							/>
						</div>
						<GridItem span={6}>
							<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
								<Button onClick={() => handlePresentaionSave()}>
									Save Presentation
								</Button>

								<Button onClick={() => createPresentation()}>
									{isExporting ? 'Exporting...' : 'Export'}
								</Button>
							</Flex>
						</GridItem>
					</Grid>
					<DashboardFilters
						presentationId={presentationId ? parseInt(presentationId) : 0}
						updateFilters={updateFilters}
						setSelectedDate={updateSelectedDate}
						setUnSavedChanges={setUnSavedChanges}
						updateFiltersToDelete={updateFiltersToDelete}
					/>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Grid hasGutter>
						<GridItem span={2}>
							<PresentationPageList
								slides={pageModel.presentationSlides}
								selectedPage={selectedSlide}
								addNewPage={addNewPage}
								removePage={removePage}
								updateSelectedPage={updateSelectedPage}
							/>
						</GridItem>
						<GridItem span={10}>
							<PresentationSlide
								slide={pageModel.presentationSlides[selectedSlide]}
								handlePresentationWidgetSave={handlePresentationWidgetChange}
								removeWidget={removeWidget}
								handleEditClick={handleChartEditClick}
								gridLayout={gridLayout}
								isEdit
								filters={dashboardFilters}
								selectedDate={selectedDate}
							/>
						</GridItem>
					</Grid>
				</CardBody>
			</Card>
			<div
				id="presentation-hidden-render"
				style={{ height: 0, overflow: 'hidden' }}
			>
				{getHiddenDOM(pageModel.presentationSlides)}
			</div>
		</>
	);
};

export default PresentationDetail;
