import { faPlay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Flex,
	Grid,
	GridItem,
	Label,
	SearchInput,
	Switch,
	Tab,
	Tabs,
	Text,
} from '@patternfly/react-core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import ChartWidgets from '../../dnd/widgets/ChartWidgets';
import TextWidgets from '../../dnd/widgets/TextWidgets';
import ImageWidgets from './ImageWidgets';
import './WidgetLibrary.scss';
import TableWidgets from './TableWidgets';

enum FilterType {
	All,
	Charts,
	Tables,
	Text,
	Images,
}

type Props = {
	isDashboard?: boolean;
	gridLayout?: boolean;
	gridBorder?: boolean;
	updateGridLayout?: (value: boolean) => void;
	updateGridBorder?: (value: boolean) => void;
};

const WidgetLibrary = (props: Props) => {
	const { isDashboard, updateGridLayout, updateGridBorder } = props;
	const [searchTerm, setSearchTerm] = useState<string>();
	const [selectedFilter, setSelectedFilter] = useState<FilterType>(FilterType.All);
	const [gridLayout, setGridLayout] = useState<boolean>(props.gridLayout ?? false);
	const [gridBorder, setGridBorder] = useState<boolean>(props.gridBorder ?? false);
	const [activeTabKey, setActiveTabKey] = React.useState<string | number>(0);

	const handleTabClick = (
		_event: React.MouseEvent<Element> | React.KeyboardEvent | MouseEvent,
		tabIndex: string | number
	) => {
		setActiveTabKey(tabIndex);
	};

	const updateSelectedFilter = (filterName: string) => {
		let filter = FilterType.All;
		switch (filterName) {
			case 'Charts':
				filter = FilterType.Charts;
				break;
			case 'Text':
				filter = FilterType.Text;
				break;
			case 'Images':
				filter = FilterType.Images;
				break;
			case 'Tables':
				filter = FilterType.Tables;
				break;
			default:
				break;
		}

		setSelectedFilter(filter);
	};
	return (
		<Grid
			className="right-sidebar"
			hasGutter
			span={12}
		>
			{!isDashboard && (
				<>
					<GridItem span={4}>
						<Flex alignSelf={{ default: 'alignSelfCenter' }}>
							<Text component="a">
								<FontAwesomeIcon icon={faPlay} /> Present
							</Text>
						</Flex>
					</GridItem>
					<GridItem span={8}>
						<Flex alignContent={{ default: 'alignContentFlexEnd' }}>
							<Switch
								label="Grid slide layout"
								isChecked={gridLayout}
								onChange={(
									checked: boolean,
									_event: React.FormEvent<HTMLInputElement>
								) => {
									setGridLayout(checked);
									updateGridLayout && updateGridLayout(checked);
								}}
							/>
						</Flex>
					</GridItem>
				</>
			)}
			{isDashboard && (
				<>
					<GridItem span={11}>
						<Flex>
							<Switch
								label="Grid layout"
								isChecked={gridLayout}
								onChange={(
									checked: boolean,
									_event: React.FormEvent<HTMLInputElement>
								) => {
									setGridLayout(checked);
									updateGridLayout && updateGridLayout(checked);
								}}
							/>
							<Switch
								label="Borders"
								isChecked={gridBorder}
								onChange={(
									checked: boolean,
									_event: React.FormEvent<HTMLInputElement>
								) => {
									setGridBorder(checked);
									updateGridBorder && updateGridBorder(checked);
								}}
							/>
						</Flex>
					</GridItem>
					<GridItem span={1}>
						<Flex justifyContent={{ default: 'justifyContentFlexEnd' }}>
							<Text component="a">
								<FontAwesomeIcon icon={faEye} />
							</Text>
						</Flex>
					</GridItem>
				</>
			)}
			<GridItem span={12}>
				<SearchInput
					id="widget-library-search"
					placeholder="Search"
					value={searchTerm}
					onChange={(_event, value: string) => setSearchTerm(value)}
				/>
			</GridItem>
			<Grid
				span={12}
				hasGutter
			>
				<Tabs
					activeKey={activeTabKey}
					onSelect={handleTabClick}
					aria-label="Tabs in the default example"
					role="region"
					isFilled
				>
					<Tab
						eventKey={0}
						title="Assets"
						aria-label="Default content - Assets"
					>
						<Grid hasGutter>
							<GridItem span={12}>
								<Flex>
									{['All', 'Charts', 'Tables', 'Text', 'Images'].map(
										(item, index) => (
											<Label
												key={index}
												className={
													selectedFilter === index ? 'selected' : ''
												}
												onClick={() => updateSelectedFilter(item)}
												render={({ className, content }) => (
													<Link
														to="#"
														className={className}
														onClick={(event) => {
															event.preventDefault();
														}}
													>
														{content}
													</Link>
												)}
												data-testid={`present-quickfilter-${item}`}
											>
												{item}
											</Label>
										)
									)}
								</Flex>
							</GridItem>
							<div className="tab-scroll-container">
								{(selectedFilter === FilterType.All ||
									selectedFilter === FilterType.Charts) && (
									<ChartWidgets searchTerm={searchTerm} />
								)}
								{(selectedFilter === FilterType.All ||
									selectedFilter === FilterType.Text) && (
									<TextWidgets searchTerm={searchTerm} />
								)}
								{(selectedFilter === FilterType.All ||
									selectedFilter === FilterType.Images) && (
									<ImageWidgets searchTerm={searchTerm} />
								)}
								{(selectedFilter === FilterType.All ||
									selectedFilter === FilterType.Tables) && (
									<TableWidgets searchTerm={searchTerm} />
								)}
							</div>
						</Grid>
					</Tab>
					<Tab
						eventKey={1}
						title="Templates"
					>
						Templates
					</Tab>
				</Tabs>
			</Grid>
		</Grid>
	);
};

export default WidgetLibrary;
