import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../layout/Layout';
import { Card, CardBody, Tabs, Tab, Text, Label } from '@patternfly/react-core';
import MainSubNavContext from '../helpers/sub-nav-contexts/main-sub-nav-context';
import ListCards from '../components/card/ListCards';
import AlertCardsHomePage from '../helpers/helper-components/alert-cards-home-page';
import { CardProps } from '../types/cards/card-props';
import {
	faTable,
	faDisplayChartUpCircleCurrency,
	faNewspaper,
	faPersonChalkboard,
	faChartSimpleHorizontal,
} from '@fortawesome/pro-light-svg-icons';
import { Recent } from '../api/recents/recents';
import { CardTypesEnum } from '../enums/card-types.enum';
import { RecentTypesEnum } from '../enums/recent-types.enum';
import { useMount } from 'react-use';
import { sortByTimestamp } from '../helpers/helper-components/recents-factory-helper';
import { ChartIconMap } from '../helpers/helper-components/chart-helper';
import { ChartTypesEnum } from '../enums/chart-types.enum';
import { useIsGranted, useUser } from '../components/user/ApplicationProvider';
import { Permission } from '../enums/permission.enum';
import { SubNavContext } from '../helpers/sub-nav-contexts/sub-nav-context';
import { TUser } from '../api/security/User';
import { Favorites, TFavorites } from '../api/favorites/Favorites';
import { FavoriteTypes } from '../enums/favorite-types';
import { faTableList } from '@fortawesome/pro-regular-svg-icons';

function GettingStarted(): ReactElement {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [activeTabKey, setActiveTabKey] = useState(-1);
	const [nestedActiveTabKey, setNestedActiveTabKey] = useState(0);
	const [recentCardsDataframes, setRecentCardsDataframes] = useState<CardProps[]>();
	const [recentCardsCharts, setRecentCardsCharts] = useState<CardProps[]>();
	const [recentCardsDashboards, setRecentCardsDashboards] = useState<CardProps[]>();
	const [recentCardsReports, setRecentCardsReports] = useState<CardProps[]>();
	const [recentCardsPresents, setRecentCardsPresents] = useState<CardProps[]>();
	const [recentCardsTables, setRecentCardsTables] = useState<CardProps[]>();
	const [recentCardsAll, setRecentCardsAll] = useState<CardProps[]>();
	const [favoriteCardsDataframes, setFavoriteCardsDataframes] = useState<CardProps[]>();
	const [favoriteCardsCharts, setFavoriteCardsCharts] = useState<CardProps[]>();
	const [favoriteCardsDashboards, setFavoriteCardsDashboards] = useState<CardProps[]>();
	const [favoriteCardsReports, setFavoriteCardsReports] = useState<CardProps[]>();
	const [favoriteCardsTables, setFavoriteCardsTables] = useState<CardProps[]>();
	const [favoriteCardsAll, setFavoritesCardsAll] = useState<CardProps[]>();
	const isGranted = useIsGranted();
	const hasViewFavouritePerm = isGranted(Permission.ViewFavorite);
	const hasViewRecentPerm = isGranted(Permission.ViewRecent);
	const hasViewChartPerm = isGranted(Permission.ViewChart);
	const hasViewDataframePerm = isGranted(Permission.ViewDataframe);
	const hasViewDashboardPerm = isGranted(Permission.ViewDashboard);
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const hasViewTablePerm = isGranted(Permission.ViewTable);
	const canAccessAlerts = isGranted(Permission.ViewAlert);
	const [subNavContext, setSubNavContext] = useState<SubNavContext>(MainSubNavContext);
	const currentUser = useUser();

	useMount(() => {
		const prevSelectedParentTabIdx = localStorage.getItem('hp_selected_parent_tabIdx');
		const prevSelectedChildTabIdx = localStorage.getItem('hp_selected_child_tabIdx');

		if (prevSelectedParentTabIdx) {
			setActiveTabKey(Number(prevSelectedParentTabIdx));
			if (prevSelectedParentTabIdx == '-1' && prevSelectedChildTabIdx) {
				setNestedActiveTabKey(Number(prevSelectedChildTabIdx));
			}
		}
	});

	// Define a function to handle tab selection
	const handleTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveTabKey(Number(tabIndex));
		localStorage.setItem('hp_selected_parent_tabIdx', tabIndex.toString());

		if (tabIndex == -1) {
			localStorage.removeItem('hp_selected_child_tabIdx');
		}
	};
	const handleNestedTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setNestedActiveTabKey(Number(tabIndex));
		localStorage.setItem('hp_selected_child_tabIdx', tabIndex.toString());
	};

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle=""
					pageDescription=""
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={subNavContext}
				/>
			),
			sidebarContext: null,
			additionalClassNames: 'home-page-subbar no-title',
			hideLeftSideBar: true,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, subNavContext]);

	useMount(() => {
		if (!hasViewRecentPerm) {
			return;
		}

		GetAllRecents();
		GetAllFavorites();
	});

	const clusteredChartTypes = ['clusteredcolumn', 'clusteredbar'];

	function GetAllRecents() {
		const promises = [
			Recent.GetAll(RecentTypesEnum.Dataframe),
			Recent.GetAll(RecentTypesEnum.Charts),
			Recent.GetAll(RecentTypesEnum.Dashboard),
			Recent.GetAll(RecentTypesEnum.Report),
			Recent.GetAll(RecentTypesEnum.Presentation),
			Recent.GetAll(RecentTypesEnum.Report, true),
		];

		// Use Promise.all to wait for all promises to complete
		Promise.all(promises)
			.then(([dataframes, charts, dashboards, reports, presentations, tables]) => {
				//process dataframes
				const recentsDataframesCards = dataframes.map((item) => ({
					icon: faTable,
					type: CardTypesEnum.DataFrame,
					url: `/analyze/${CardTypesEnum.DataFrame}/view/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process charts
				const recentsChartCards = charts.map((item) => ({
					icon: ChartIconMap[item.chart_type as ChartTypesEnum],
					type: CardTypesEnum.Chart,
					chartType: item.chart_type as ChartTypesEnum,
					url: `/analyze/${CardTypesEnum.Chart}/view/chart/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: item.chart_type
						? clusteredChartTypes.includes(item.chart_type)
						: false,
					id: item.entity_id,
				}));

				//process dashboards
				const recentsDashboardCards = dashboards.map((item) => ({
					icon: faDisplayChartUpCircleCurrency,
					type: CardTypesEnum.Dashboard,
					url: `/analyze/${CardTypesEnum.Dashboard}/view/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process reports
				const recentsReportCards = reports.map((item) => ({
					icon: faNewspaper,
					type: CardTypesEnum.Report,
					url: hasViewReportPerm ? `/report/view/${item.entity_id}` : '',
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process presentations
				const recentsPresentationCards = presentations.map((item) => ({
					icon: faPersonChalkboard,
					type: CardTypesEnum.Presentation,
					url: `/present/view/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process tables (yes tables uses the same enum type as reports)
				const recentsTablesCards = tables
					.filter((x) => x.entity_type == RecentTypesEnum.Report)
					.map((item) => ({
						icon: faTableList,
						type: CardTypesEnum.Table,
						url: `/table/view/${item.entity_id}`,
						title: item.name ?? '',
						isShared: (item.owner as TUser).id !== currentUser.id,
						ownerName: (item.owner as TUser).name,
						isRecent: true,
						timestamp: item.accessed_at,
						isClustered: false,
						id: item.entity_id,
					}));

				//sorting
				const sortedDataframeRecents = sortByTimestamp(recentsDataframesCards);
				const sortedChartRecents = sortByTimestamp(recentsChartCards);
				const sortedDashboardRecents = sortByTimestamp(recentsDashboardCards);
				const sortedReportRecents = sortByTimestamp(recentsReportCards);
				const sortedPresentRecents = sortByTimestamp(recentsPresentationCards);
				const sortedTableRecents = sortByTimestamp(recentsTablesCards);
				setRecentCardsDataframes(sortedDataframeRecents);
				setRecentCardsCharts(sortedChartRecents);
				setRecentCardsDashboards(sortedDashboardRecents);
				setRecentCardsReports(sortedReportRecents);
				setRecentCardsPresents(sortedPresentRecents);
				setRecentCardsTables(sortedTableRecents);

				const combinedRecents = [
					...recentsDataframesCards,
					...recentsChartCards,
					...recentsDashboardCards,
					...recentsReportCards,
					...recentsPresentationCards,
					...recentsTablesCards,
				];
				const sortedCombinedRecents = sortByTimestamp(combinedRecents).slice(0, 18);
				setRecentCardsAll(sortedCombinedRecents);
			})
			.catch((error) => {
				// Handle errors
				console.error('Error fetching recent data:', error);
			});
	}

	function GetAllFavorites() {
		const promises = [
			Favorites.GetByType(currentUser.id, FavoriteTypes.dataframe),
			Favorites.GetByType(currentUser.id, FavoriteTypes.chart),
			Favorites.GetByType(currentUser.id, FavoriteTypes.report),
			Favorites.GetByType(currentUser.id, FavoriteTypes.dashboard),
			Favorites.GetByType(currentUser.id, FavoriteTypes.table),
		];

		// Use Promise.all to wait for all promises to complete
		Promise.all(promises)
			.then(([dataframes, charts, reports, dashboards, tables]) => {
				//process dataframes
				const favoritesDataframesCards = dataframes.map((item: TFavorites) => ({
					icon: faTable,
					type: CardTypesEnum.DataFrame,
					url: hasViewDataframePerm
						? `/analyze/${CardTypesEnum.DataFrame}/view/${item.object_id ?? 0}`
						: '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//process charts
				const favoritesChartCards = charts.map((item: TFavorites) => ({
					icon: item.chartType
						? ChartIconMap[item.chartType as ChartTypesEnum]
						: faChartSimpleHorizontal,
					type: CardTypesEnum.Chart,
					chartType: item.chartType as ChartTypesEnum,
					url: hasViewChartPerm
						? `/analyze/${CardTypesEnum.Chart}/view/chart/${item.object_id ?? 0}`
						: '',
					title: item.name ?? '',
					isRecent: true,
					id: item.object_id,
				}));

				void Favorites.GetByType(currentUser.id, FavoriteTypes.chart).then((response) => {
					const toggledFavourite = response.find((x) => x.showOnMain == true);

					setSubNavContext({
						...subNavContext,
						reportSnapshot: {
							title: `From Your Favorites: ${toggledFavourite?.name ?? ''}`,
							snapshotText: '',
							chartId: toggledFavourite?.object_id,
							noFavToggled: toggledFavourite === undefined,
						},
					});
				});

				//process dashboards
				const favoritesDashboardCards = dashboards.map((item: TFavorites) => ({
					icon: faDisplayChartUpCircleCurrency,
					type: CardTypesEnum.Dashboard,
					url: hasViewDashboardPerm
						? `/analyze/${CardTypesEnum.Dashboard}/view/${item.object_id ?? 0}`
						: '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//process reports
				const favoritesReportCards = reports.map((item: TFavorites) => ({
					icon: faNewspaper,
					type: CardTypesEnum.Report,
					url: hasViewReportPerm ? `/report/view/${item.object_id ?? 0}` : '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//process tables
				const favoritesTableCards = tables.map((item: TFavorites) => ({
					icon: faTableList,
					type: CardTypesEnum.Table,
					url: hasViewTablePerm ? `/table/view/${item.object_id ?? 0}` : '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//sorting
				const sortedDataframeFavorites = sortByTimestamp(favoritesDataframesCards);
				const sortedChartFavorites = sortByTimestamp(favoritesChartCards);
				const sortedDashboardFavorites = sortByTimestamp(favoritesDashboardCards);
				const sortedReportFavorites = sortByTimestamp(favoritesReportCards);
				const sortedTableFavorites = sortByTimestamp(favoritesTableCards);
				setFavoriteCardsDataframes(sortedDataframeFavorites);
				setFavoriteCardsCharts(sortedChartFavorites);
				setFavoriteCardsDashboards(sortedDashboardFavorites);
				setFavoriteCardsReports(sortedReportFavorites);
				setFavoriteCardsTables(sortedTableFavorites);

				const combinedFavorites = [
					...favoritesDataframesCards,
					...favoritesChartCards,
					...favoritesDashboardCards,
					...favoritesReportCards,
					...favoritesTableCards,
				];
				const sortedCombinedFavorites = sortByTimestamp(combinedFavorites).slice(0, 18);
				setFavoritesCardsAll(sortedCombinedFavorites);
			})
			.catch((error) => {
				// Handle errors
				console.error('Error fetching favorites data:', error);
			});
	}

	return (
		<>
			<React.Fragment>
				<Card className="recents-card-panel">
					<CardBody>
						<Tabs
							className="parent-tabs"
							activeKey={activeTabKey}
							onSelect={handleTabSelect}
						>
							{hasViewRecentPerm && (
								<Tab
									eventKey={-1}
									title={
										<span
											dangerouslySetInnerHTML={{
												__html: '<strong>Recents<strong/>',
											}}
										></span>
									}
								>
									{/* Content for Tab 1 */}
									<div>
										{' '}
										<Tabs
											activeKey={nestedActiveTabKey}
											onSelect={handleNestedTabSelect}
											className="child-tabs"
										>
											<Tab
												eventKey={0}
												title="All"
											>
												{/* Content for Tab All */}
												{recentCardsAll && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsAll}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsAll && recentCardsAll.length < 1 && (
													<div>
														<Label>No recents were found</Label>
													</div>
												)}
											</Tab>
											<Tab
												eventKey={1}
												title="Dashboards"
											>
												{/* Content for Tab Dashboards */}
												{recentCardsDashboards && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsDashboards}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsDashboards &&
													recentCardsDashboards.length < 1 && (
														<div>
															<Label>
																No recent Dashboards were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={2}
												title="Charts"
											>
												{/* Content for Tab Charts */}
												{recentCardsCharts && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsCharts}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsCharts &&
													recentCardsCharts.length < 1 && (
														<div>
															<Label>
																No recent Charts were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={3}
												title="Tables"
											>
												{/* Content for Tab Tables */}
												{recentCardsTables && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsTables}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsTables &&
													recentCardsTables.length < 1 && (
														<div>
															<Label>
																No recent Tables were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={4}
												title="Dataframes"
											>
												{/* Content for Tab Dataframes */}
												{recentCardsDataframes && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsDataframes}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsDataframes &&
													recentCardsDataframes.length < 1 && (
														<div>
															<Label>
																No recent Dataframes were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={5}
												title="Reports"
											>
												{/* Content for Tab Reports */}
												{recentCardsReports && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsReports}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsReports &&
													recentCardsReports.length < 1 && (
														<div>
															<Label>
																No recent Reports were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={6}
												title="Presentations"
											>
												{/* Content for Tab Presentations */}
												{recentCardsPresents && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={recentCardsPresents}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{recentCardsPresents &&
													recentCardsPresents.length < 1 && (
														<div>
															<Label>
																No recent Presentations were found
															</Label>
														</div>
													)}
											</Tab>
										</Tabs>
									</div>
								</Tab>
							)}

							{hasViewFavouritePerm && (
								<Tab
									eventKey={-2}
									title={
										<span
											dangerouslySetInnerHTML={{
												__html: '<strong>Favorites<strong/>',
											}}
										></span>
									}
								>
									{/* Content for Tab 2 */}
									<div>
										<Tabs
											activeKey={nestedActiveTabKey}
											onSelect={handleNestedTabSelect}
											className="child-tabs"
										>
											<Tab
												eventKey={0}
												title="All"
											>
												{/* Content for Tab All */}
												{favoriteCardsAll && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={favoriteCardsAll}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{favoriteCardsAll &&
													favoriteCardsAll.length < 1 && (
														<div>
															<Label>No favorites were found</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={1}
												title="Dashboards"
											>
												{/* Content for Tab Favorites */}
												{favoriteCardsDashboards && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={favoriteCardsDashboards}
															hideEllipsis={true}
															hidePin={nestedActiveTabKey != 1}
														/>
													</div>
												)}
												{favoriteCardsDashboards &&
													favoriteCardsDashboards.length < 1 && (
														<div>
															<Label>
																No favorite Dashboards were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={2}
												title="Charts"
												className="favorite-chart-tab"
											>
												{/* Content for Tab Charts */}
												{favoriteCardsCharts && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={favoriteCardsCharts}
															hideEllipsis={true}
															hidePin={nestedActiveTabKey != 2}
														/>
													</div>
												)}
												{favoriteCardsCharts &&
													favoriteCardsCharts.length < 1 && (
														<div>
															<Label>
																No favorite Charts were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={3}
												title="Tables"
											>
												{/* Content for Tab Tables */}
												{favoriteCardsTables && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={favoriteCardsTables}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{favoriteCardsTables &&
													favoriteCardsTables.length < 1 && (
														<div>
															<Label>
																No favorite Tables were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={4}
												title="Dataframes"
											>
												{/* Content for Tab Dataframes */}
												{favoriteCardsDataframes && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={favoriteCardsDataframes}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{favoriteCardsDataframes &&
													favoriteCardsDataframes.length < 1 && (
														<div>
															<Label>
																No favorite Dataframes were found
															</Label>
														</div>
													)}
											</Tab>
											<Tab
												eventKey={5}
												title="Reports"
											>
												{/* Content for Tab Reports */}
												{favoriteCardsReports && (
													<div>
														<ListCards
															additionalClassNames="scrollable-cards"
															items={favoriteCardsReports}
															hideEllipsis={true}
															hidePin={true}
														/>
													</div>
												)}
												{favoriteCardsReports &&
													favoriteCardsReports.length < 1 && (
														<div>
															<Label>
																No favorite Reports were found
															</Label>
														</div>
													)}
											</Tab>
										</Tabs>
									</div>
								</Tab>
							)}
						</Tabs>
					</CardBody>
				</Card>
			</React.Fragment>
			{canAccessAlerts && (
				<>
					<br />
					<Card>
						<CardBody>
							<Text className="font-size-md">
								<strong>Alerts</strong>
							</Text>
							<br />
							<AlertCardsHomePage />
						</CardBody>
					</Card>
				</>
			)}
		</>
	);
}

export default GettingStarted;
