import React, { ReactElement } from 'react';
import {
	Button,
	Checkbox,
	Form,
	FormGroup,
	Modal,
	ModalVariant,
	NumberInput,
	TextInput,
} from '@patternfly/react-core';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';

export type IDimensionOptionsModalProps = {
	dimension: DraggableMenuItemData | null;
	handleClose: () => void;
	handleSave: (dimension: DraggableMenuItemData) => void;
};

const DimensionOptionsModal = (props: IDimensionOptionsModalProps): ReactElement => {
	const [title, setTitle] = React.useState(props.dimension ? props.dimension.data?.title : '');
	const [previousPeriods, setPreviousPeriods] = React.useState<number>(
		props.dimension?.data?.previousPeriods ?? 0
	);
	const [futurePeriods, setFuturePeriods] = React.useState<number>(
		props.dimension?.data?.futurePeriods ?? 0
	);
	const [useSequence, setUseSequence] = React.useState(
		props.dimension?.data?.useSequence ?? false
	);
	const periodMin = 0;
	const periodMax = 365;

	const onCloseModal = () => {
		props.handleClose();
	};

	const onSaveModal = () => {
		const dimension = Object.assign({}, props.dimension);
		if (dimension.data) {
			dimension.data.useSequence = useSequence;
			dimension.data.title = title ?? '';

			if (dimension.entityType === OptionsBuilderItemTypes.DateSeries) {
				dimension.data.previousPeriods = previousPeriods;
				dimension.data.futurePeriods = futurePeriods;
			}
		}

		props.handleSave(dimension);
	};

	const onTitleChange = (title: string) => {
		setTitle(title);
	};

	const onPeriodChange = (event: React.FormEvent<HTMLInputElement>, isPrevious: boolean) => {
		const value = (event.target as HTMLInputElement).value;
		if (!isNaN(+value)) {
			if (isPrevious) {
				setPreviousPeriods(+value);
			} else {
				setFuturePeriods(+value);
			}
		}
	};

	const normalizeBetween = (value: number) => {
		if (value <= periodMin) {
			return periodMin;
		} else if (value >= periodMax) {
			return periodMax;
		}

		return value;
	};

	const onPeriodBlur = (event: React.FocusEvent<HTMLInputElement>, isPrevious: boolean) => {
		const blurVal = +event.target.value;
		let val: number | null = null;
		if (blurVal < periodMin) {
			val = periodMin;
		} else if (blurVal > periodMax) {
			val = periodMax;
		}

		if (val) {
			if (isPrevious) {
				setPreviousPeriods(val);
			} else {
				setFuturePeriods(val);
			}
		}
	};

	const onPeriodMinus = (isPrevious: boolean) => {
		if (isPrevious) {
			setPreviousPeriods(normalizeBetween(previousPeriods - 1));
		} else {
			setFuturePeriods(normalizeBetween(futurePeriods - 1));
		}
	};

	const onPeriodPlus = (isPrevious: boolean) => {
		if (isPrevious) {
			setPreviousPeriods(normalizeBetween(previousPeriods + 1));
		} else {
			setFuturePeriods(normalizeBetween(futurePeriods + 1));
		}
	};

	return (
		<React.Fragment>
			<Modal
				title="Options"
				variant={ModalVariant.small}
				isOpen={true}
				onClose={onCloseModal}
				actions={[
					<Button
						key="confirm"
						variant="primary"
						onClick={onSaveModal}
					>
						Ok
					</Button>,
					<Button
						key="cancel"
						variant="secondary"
						onClick={onCloseModal}
					>
						Cancel
					</Button>,
				]}
			>
				<Form>
					<FormGroup
						label="Title"
						fieldId="title"
					>
						<TextInput
							type="text"
							id="title"
							name="title"
							value={title}
							onChange={onTitleChange}
						/>
					</FormGroup>
					{props.dimension?.entityType === OptionsBuilderItemTypes.DateSeries && (
						<>
							<FormGroup
								label="Previous Periods"
								fieldId="previous_periods"
							>
								<NumberInput
									type="text"
									id="previous_periods"
									name="previous_periods"
									value={previousPeriods}
									min={periodMin}
									max={periodMax}
									onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
										onPeriodBlur(e, true);
									}}
									onMinus={() => {
										onPeriodMinus(true);
									}}
									onPlus={() => {
										onPeriodPlus(true);
									}}
									onChange={(e: React.FormEvent<HTMLInputElement>) => {
										onPeriodChange(e, true);
									}}
								/>
							</FormGroup>
							<FormGroup
								label="Future Periods"
								fieldId="future_periods"
							>
								<NumberInput
									type="text"
									id="future_periods"
									name="future_periods"
									value={futurePeriods}
									min={periodMin}
									max={periodMax}
									onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
										onPeriodBlur(e, false);
									}}
									onMinus={() => {
										onPeriodMinus(false);
									}}
									onPlus={() => {
										onPeriodPlus(false);
									}}
									onChange={(e: React.FormEvent<HTMLInputElement>) => {
										onPeriodChange(e, false);
									}}
								/>
							</FormGroup>
						</>
					)}
					<FormGroup isInline>
						<Checkbox
							label="Use Assigned Sequence"
							id="use_assigned_sequence"
							isChecked={useSequence}
							onChange={setUseSequence}
						/>
					</FormGroup>
				</Form>
			</Modal>
		</React.Fragment>
	);
};

export default DimensionOptionsModal;
