import { GenericKeyValueResponse } from '../api/types';

export function containsTwoInstances(str: string, term: string): boolean {
	// Construct a regular expression to match the term
	const regex = new RegExp(term, 'g');

	// Use match method to find all occurrences of the term
	const matches = str.match(regex);

	// Check if matches exist and if there are at least two instances
	return matches !== null && matches.length >= 2;
}

export function parseFolderSegments(
	pathname: string
): GenericKeyValueResponse<string | number | JSX.Element>[] {
	const segments = pathname.split('/folder/');
	const result: GenericKeyValueResponse<string | number>[] = [];

	// Start from index 1 to skip the first part of the pathname
	for (let i = 1; i < segments.length; i++) {
		const folderSegments = segments[i].split('/');
		if (folderSegments.length >= 2) {
			const decodedValue = decodeURIComponent(folderSegments[0]); // Decode safely

			result.push({
				id: parseInt(folderSegments[1]),
				value: decodedValue,
			});
		}
	}

	return result;
}

export function constructUrl(pathname: string, selectedSegmentIdx: number): string {
	const segments = parseFolderSegments(pathname);
	let newUrl = pathname.split('/folder/')[0]; // Start with the base of the path

	// Loop through segments up to the selected index + 1 and rebuild the URL
	for (let i = 0; i <= selectedSegmentIdx && i < segments.length; i++) {
		const segment = segments[i];
		//eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		newUrl += `/folder/${segment.value}/${segment.id}`;
	}

	return newUrl;
}
