import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import {
	TUserDimensionAttributeFilter,
	UserDimensionAttributeFilter,
} from '../../api/security/UserDimensionAttributeFilter';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalBoxFooter, ModalVariant } from '@patternfly/react-core';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { User } from '../../api/security/User';
import { Dimension, TDimension } from '../../api/analytics/Dimension';

export default function UserDimensionFilters(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TUserDimensionAttributeFilter[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [activeUserDimFilter, setActiveUserDimFilter] = useState<TUserDimensionAttributeFilter>(
		UserDimensionAttributeFilter.Default() as TUserDimensionAttributeFilter
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const [currentUsername, setCurrentUsername] = useState('');
	const { userId } = useParams();

	const selectedColumns: Column<TUserDimensionAttributeFilter>[] = [
		{
			title: 'User',
			columnName: 'username',
			customAccessor: () => {
				return currentUsername;
			},
		},
		{
			title: 'Dimension Attribute',
			columnName: 'dimension_attribute',
			customAccessor: (item) => {
				const returnItem = filteredDimensions
					.find((x) =>
						x.dimensionAttributes.some((y) => y.id == item.dimension_attribute)
					)
					?.dimensionAttributes.find((x) => x.id == item.dimension_attribute);
				return returnItem?.name ?? '';
			},
		},
		{
			title: 'Dimension Attribute Value',
			columnName: 'value',
		},
		{
			title: 'Logic Group',
			columnName: 'logic_group',
		},
	];

	const actions: Action<TUserDimensionAttributeFilter>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			permission: Permission.EditUser,
			callback: (item) => {
				setActiveUserDimFilter({
					...item,
					username: currentUsername,
				});
				setIsModalOpen(true);
			},
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			permission: Permission.EditUser,
			callback: (item) => {
				setActiveUserDimFilter({
					...item,
					username: currentUsername,
				});
				setIsDeleteModalOpen(true);
			},
		},
	];

	const filteredDimensions = dimensions.filter(
		(dimension) => dimension.dimensionAttributes.length > 0
	);

	const returnDimAttrId = (dimAttrName: string) => {
		const id = filteredDimensions
			.find((x) => x.dimensionAttributes.some((y) => y.name === dimAttrName))
			?.dimensionAttributes.find((y) => y.name === dimAttrName)?.id;

		return id!;
	};

	const formProperties: Field<TUserDimensionAttributeFilter>[] = [
		{
			title: 'User',
			columnName: 'username',
			uiSchema: {
				type: UIType.TEXT,
			},
			disabled: true,
		},
		{
			title: 'Dimension Attribute',
			columnName: 'dimension_attribute',
			uiSchema: {
				type: UIType.DIM_ATTRIBUTE_SELECT,
				dimOptions: filteredDimensions,
				initialSelection:
					'id' in activeUserDimFilter
						? activeUserDimFilter.dimension_attribute
						: undefined,
				onSelect: (value: ISelectOption) => returnDimAttrId(value.key.toString()),
			},
			required: true,
		},
		{
			title: 'Dimension Attribute Value',
			columnName: 'value',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Logic Group',
			columnName: 'logic_group',
			uiSchema: {
				type: UIType.NUMBER,
			},
		},
	];

	useMount(() => {
		const userIdParsed = userId ? +userId : undefined;

		if (userIdParsed) {
			void User.Get(userIdParsed).then((response) => {
				setCurrentUsername(response.name);
				setActiveUserDimFilter({
					...activeUserDimFilter,
					username: response.name,
					user: userIdParsed,
				});
			});

			getallUserDimAttributeFilters(userIdParsed);
		}

		void Dimension.GetAll(['dimensionAttributes']).then((response) => {
			setDimensions(response);
		});
	});

	const getallUserDimAttributeFilters = (userIdParsed: number) => {
		void UserDimensionAttributeFilter.GetAll(userIdParsed).then((response) => {
			setTableData(response);
		});
	};

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Users Dimension Attribute Filters"
					pageDescription="Manage Users Dimension Attribute Filters."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			permission={Permission.CreateUser}
			data-testid={'user-dim-attr-filter-create'}
			variant={'primary'}
			onClick={() => {
				setActiveUserDimFilter({
					...(UserDimensionAttributeFilter.Default() as TUserDimensionAttributeFilter),
					username: currentUsername,
					user: userId ? +userId : 0,
				});

				setIsModalOpen(true);
			}}
		>
			New User Dimension Attribute Filter
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);
		UserDimensionAttributeFilter.Delete(activeUserDimFilter.id)
			.then(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				setTableData((prev) => prev.filter((user) => user.id !== activeUserDimFilter.id));
				addToast('User Attribute Filter deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				addToast(
					'An error occurred while trying to delete User Attribute Filter. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const handleSuccess = (_value: TUserDimensionAttributeFilter) => {
		const userIdParsed = userId ? +userId : 0;

		getallUserDimAttributeFilters(userIdParsed);
		setIsModalOpen(false);
	};

	const userDimFilterTable = (
		<ZiTable<TUserDimensionAttributeFilter>
			ariaLabel={'User Dimension Attribute Filters'}
			columns={selectedColumns}
			data={tableData}
			caption={`User Dimension Attribute Filters for ${currentUsername}`}
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="User Dimension Attribute Filters"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TUserDimensionAttributeFilter>
					title={'User Dimension Attribute Filters'}
					fields={formProperties}
					initialSubject={activeUserDimFilter}
					isLoading={isFormLoading}
					onSubmit={(userDimAttrFilter) => {
						setIsFormLoading(true);
						userDimAttrFilter.logic_group = +userDimAttrFilter.logic_group;

						if (userDimAttrFilter.id) {
							UserDimensionAttributeFilter.Update(userDimAttrFilter)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User Attribute Filters. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							UserDimensionAttributeFilter.New(userDimAttrFilter)
								.then((newUser) => {
									handleSuccess(newUser);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User Attribute Filters. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>

			<Modal
				title="User Attribute Filters Delete Confirmation"
				variant="small"
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>Are you sure you want to delete {activeUserDimFilter.username}?</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right add-question-footer">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal>

			<React.Fragment>
				<FilterTableLayout
					table={userDimFilterTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
