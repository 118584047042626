import React, { useCallback, useEffect, useState } from 'react';
import './Presentation.scss';
import { Outlet, useOutletContext } from 'react-router-dom';
import { OutletContext } from '../../layout/Layout';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import WidgetLibrary from '../../components/dnd/widgets/WidgetLibrary';

const Presentation = () => {
	const outletContext = useOutletContext<OutletContext>();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = outletContext;
	const [gridLayout, setGridLayout] = useState<boolean>(true);
	const [gridBorder, setGridBorder] = useState<boolean>(true);

	const updateGridLayout = useCallback(
		(value: boolean) => {
			setGridLayout(value);
		},
		[gridLayout, setGridLayout]
	);

	const updateGridBorder = useCallback(
		(value: boolean) => {
			setGridBorder(value);
		},
		[gridBorder, setGridBorder]
	);

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Present"
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
			sidebarContext: null,
			sidebarSearch: undefined,
			rightSideBar: (
				<WidgetLibrary
					gridLayout={gridLayout}
					gridBorder
					updateGridLayout={updateGridLayout}
					updateGridBorder={updateGridBorder}
				/>
			),
			hideLeftSideBar: true,
			folderType: FolderTypesEnum.Presentation,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, window.location.pathname]);

	const newOutletContext: OutletContext = {
		setSubSide,
		subNavExpanded,
		setSubNavExpanded,
		html5Backend: HTML5Backend,
	};

	return (
		<>
			<Outlet context={{ ...newOutletContext, gridLayout: gridLayout }} />
		</>
	);
};

export default Presentation;
