import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OutletContext } from '../../layout/Layout';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { PresentContext } from '../../types/present';
import { Card, CardBody } from '@patternfly/react-core';
import { FolderTypesEnum } from '../../enums/folder-types-enum';
import { useMount } from 'react-use';
import AddNewDropdown from '../../components/ui/addNewDropdownButton/AddNewDropdown';
import { PresentSidebarContext } from '../../helpers/sidebar-contexts/present-sidebar-context';

export enum ListType {
	All,
	Recents,
	Favorites,
}

type Props = {
	pageContext: PresentContext;
	listType?: ListType;
};

const Present = (props: Props) => {
	const { pageContext } = props;
	const navigate = useNavigate();
	const { folderId } = useParams();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const pathname = useLocation().pathname;

	useMount(() => {
		const prevPresentPage = localStorage.getItem('prevPresentPage');
		if (prevPresentPage) {
			navigate(`/present/${prevPresentPage}`);
		}
	});

	useEffect(() => {
		const headerContext = {
			header: '',
			subheader: '',
		};

		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Present"
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={pageContext.subNavContext}
				/>
			),
			sidebarContext: PresentSidebarContext,
			sidebarHeaderContext: headerContext,
			sidebarSearch: {
				title: 'Search Presentaions',
				placeholder: 'Search Presentations',
				submit: (term: string | undefined) => {
					let url = `/present`;
					if (folderId) {
						url = `${url}/${folderId}`;
					}
					if (term) {
						url = `${url}/search?term=${term}`;
					}
					navigate(url);
				},
			},
			folderHeadingTitle: 'Presentations',
			folderType: FolderTypesEnum.Presentation,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	return (
		<Card>
			<CardBody>
				{!pathname.includes('/recents') && !pathname.includes('/favorites') && (
					<AddNewDropdown
						folderHeadingTitle="Presentation"
						folderType={FolderTypesEnum.Presentation}
						onSetSubSide={setSubSide}
					/>
				)}

				<Outlet />
			</CardBody>
		</Card>
	);
};

export default Present;
