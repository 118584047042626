import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { AlertVariant, Modal, ModalVariant } from '@patternfly/react-core';
import { Dimension, TDimension } from '../../api/analytics/Dimension';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { KeyMeasure, TKeyMeasure } from '../../api/analytics/KeyMeasure';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import SchnurForm, {
	Field,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';
import { useMount } from 'react-use';
import { Period, TPeriod } from '../../api/analytics/Period';
import {
	EAggregateMethod,
	EAggregateMethodMap,
	EOccurrence,
	KeyMeasureSource,
	TKeyMeasureSource,
} from '../../api/analytics/KeyMeasureSource';
import { timestampToMMDDYYYY } from '../../utilities';
import { ETLSourceDatabase, TETLSourceDatabase } from '../../api/analytics/ETLSourceDatabase';
import { faFileImport, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { AdminActions, DataImportEntityTypeEnum } from '../../api/admin/AdminActions';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';

export default function KeyMeasuresSources(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [data, setData] = useState<TKeyMeasureSource[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [activeKeyMeasureSource, setActiveKeyMeasureSource] = useState<TKeyMeasureSource>(
		KeyMeasureSource.Default() as unknown as TKeyMeasureSource
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true);
	const [_dimensions, setDimensions] = useState<TDimension[]>([]);
	const [keyMeasures, setKeyMeasures] = useState<TKeyMeasure[]>([]);
	const [periods, setPeriods] = useState<TPeriod[]>([]);
	const [ETLSourceDatabases, setETLSourceDatabases] = useState<TETLSourceDatabase[]>([]);

	const selectedColumns: Column<TKeyMeasureSource>[] = [
		{
			title: 'Key Measure',
			columnName: 'keyMeasure',
			customAccessor: (item) => {
				return item.keyMeasure.name;
			},
		},
		{
			title: 'Title',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'ETL Source',
			columnName: 'ETLSourceDatabase',
			customAccessor: (item) => item.ETLSourceDatabase.host ?? 'N/A',
			sortable: false,
		},
		{
			title: 'Collection Period',
			columnName: 'collection_period',
			sortable: true,
			customAccessor: (item) => item.collection_period.name,
		},
		{
			title: 'Start Date',
			columnName: 'start_date',
			customAccessor: (item) => timestampToMMDDYYYY(item.start_date),
			sortable: true,
		},
		{
			title: 'Next Run Date',
			columnName: 'next_run_date',
			customAccessor: (item) =>
				item.next_run_date ? timestampToMMDDYYYY(item.next_run_date) : '-',
			sortable: true,
		},
		{
			title: 'Aggregation Method',
			columnName: 'aggregate_method',
			customAccessor: (item) => EAggregateMethodMap[item.aggregate_method],
			sortable: true,
		},
	];

	useMount(() => {
		Dimension.GetAll()
			.then((dimensions) => {
				setDimensions(dimensions);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load dimensions. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		ETLSourceDatabase.GetAll()
			.then((ETLSourceDatabases) => {
				setETLSourceDatabases(ETLSourceDatabases);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load ETL Source Databases. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		Period.GetAll()
			.then((periods) => {
				setPeriods(periods);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load periods. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		KeyMeasure.GetAll()
			.then((keyMeasures) => {
				setKeyMeasures(keyMeasures);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load key measures. Please try again later.',
					AlertVariant.danger
				);
			});
	});

	const formProperties: Field<TKeyMeasureSource>[] = [
		{
			title: 'Key Measure',
			columnName: 'keyMeasure',
			uiSchema: {
				type: UIType.SELECT,
				helpText: 'The alias of the key measure to use in SQL statements.',
				initialSelection:
					typeof activeKeyMeasureSource.keyMeasure === 'object'
						? activeKeyMeasureSource.keyMeasure.name
						: null,
				options: keyMeasures.map((keyMeasure) => {
					return {
						key: keyMeasure.id,
						value: keyMeasure.name,
					};
				}),
				onSelect: (keyMeasure: ISelectOption) => {
					return keyMeasure.key;
				},
			},
			required: true,
		},
		{
			title: 'Title',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				placeholder: 'title',
				helpText: 'The title of the key measure source.',
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
				helpText: 'The description of the key measure source.',
			},
			maxLength: 255,
		},
		{
			title: 'ETL Source Database',
			columnName: 'ETLSourceDatabase',
			uiSchema: {
				type: UIType.SELECT,
				initialSelection:
					typeof activeKeyMeasureSource.ETLSourceDatabase === 'object'
						? activeKeyMeasureSource.ETLSourceDatabase.dbName
						: null,
				options: ETLSourceDatabases.map((etl) => {
					return {
						key: etl.id,
						value: etl.dbName ?? 'N/A',
					};
				}),
				onSelect: (etl: ISelectOption) => {
					return etl.key;
				},
			},
			required: true,
		},
		{
			title: 'SQL Statement',
			columnName: 'sql',
			uiSchema: {
				type: UIType.TEXTAREA,
				helpText: 'The SQL statement to use to calculate the key measure source.',
			},
			required: true,
		},
		{
			title: 'Period Override SQL Statement',
			columnName: 'sql_period_override',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
		},
		{
			title: 'Run Occurrence',
			columnName: 'occurrence',
			uiSchema: {
				type: UIType.SELECT,
				options: [
					{
						key: EOccurrence.RECURRING,
						value: 'Recurring',
					},
					{
						key: EOccurrence.ONE_TIME,
						value: 'One Time',
					},
					{
						key: EOccurrence.DAILY,
						value: 'Daily',
					},
					{
						key: EOccurrence.NEVER,
						value: 'Never',
					},
				],
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
			},
			required: true,
		},
		{
			title: 'Period',
			columnName: 'period',
			uiSchema: {
				type: UIType.SELECT,
				initialSelection:
					typeof activeKeyMeasureSource.period === 'object'
						? activeKeyMeasureSource.period.name
						: null,
				options: periods.map((period) => {
					return {
						key: period.id,
						value: period.name,
					};
				}),
				onSelect: (period: ISelectOption) => {
					return period.key;
				},
			},
			required: true,
		},
		{
			title: 'Daily Run Interval',
			columnName: 'daily_run_interval',
			uiSchema: {
				type: UIType.NUMBER,
				min: 0,
				helpText: 'The number of days between each run of the key measure source.',
			},
		},
		{
			title: 'Collect Single Period',
			columnName: 'single_period',
			uiSchema: {
				type: UIType.CHECKBOX,
				helpText:
					'If checked, the key measure source will only collect data for the current period.',
			},
		},
		{
			title: 'Collection Period',
			columnName: 'collection_period',
			uiSchema: {
				type: UIType.SELECT,
				initialSelection:
					typeof activeKeyMeasureSource.collection_period === 'object'
						? activeKeyMeasureSource.collection_period.name
						: null,
				options: periods.map((period) => {
					return {
						key: period.id,
						value: period.name,
					};
				}),
				onSelect: (period: ISelectOption) => {
					return period.key;
				},
			},
			required: true,
		},
		{
			title: 'Aggregation Method',
			columnName: 'aggregate_method',
			uiSchema: {
				type: UIType.SELECT,
				initialSelection: EAggregateMethodMap[activeKeyMeasureSource.aggregate_method],
				options: [
					{
						key: EAggregateMethod.AGGREGATE_UP_FROM_LOWER_PERIOD,
						value: 'Aggregate Up From Lower Periods',
					},
					{
						key: EAggregateMethod.DONT_AUTO_AGGREGATE,
						value: "Don't Auto Aggregate",
					},
					{
						key: EAggregateMethod.FORCE_LAST_LOWER_PERIOD,
						value: 'Force Last Lower Period',
					},
				],
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
			},
		},
		{
			title: 'Collect Single Period',
			columnName: 'single_period',
			uiSchema: {
				type: UIType.BOOLEAN,
			},
		},
		{
			title: 'Refresh Back Periods',
			columnName: 'backward_periods',
			uiSchema: {
				type: UIType.NUMBER,
				min: 0,
				helpText: 'The number of periods to refresh when the key measure source is run.',
			},
		},
		{
			title: 'Collect Forward Periods',
			columnName: 'forward_periods',
			uiSchema: {
				type: UIType.NUMBER,
				min: 0,
				helpText: 'The number of periods to collect when the key measure source is run.',
			},
		},
		{
			title: 'Run Group',
			columnName: 'run_group',
			uiSchema: {
				type: UIType.NUMBER,
				min: 0,
				helpText: 'The run group to use when running the key measure source.',
			},
		},
		{
			title: 'Run Sequence',
			columnName: 'run_sequence',
			uiSchema: {
				type: UIType.NUMBER,
				min: 0,
				helpText: 'The run sequence to use when running the key measure source.',
			},
		},
		{
			title: 'Start Date',
			columnName: 'start_date',
			uiSchema: {
				type: UIType.DATE,
				helpText: 'The date to start collecting data for the key measure source.',
			},
			required: true,
		},
		{
			title: 'End Date',
			columnName: 'end_date',
			uiSchema: {
				type: UIType.DATE,
				helpText: 'The date to stop collecting data for the key measure source.',
			},
		},
		{
			title: 'Next Run Date',
			columnName: 'next_run_date',
			uiSchema: {
				type: UIType.DATE,
				helpText: 'The date to run the next key measure source.',
			},
		},
	];

	const actions: Action<TKeyMeasureSource>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			callback: (item) => {
				setActiveKeyMeasureSource(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditKeyMeasureSource,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			callback: (item) => {
				setTableLoading(true);
				KeyMeasureSource.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setData((prev) => prev.filter((dimension) => dimension.id !== item.id));
						addToast('Key Measure Source deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete Key Measure Source. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.DeleteKeyMeasureSource,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faFileImport} />
					Data Import
				</>
			),
			callback: (item) => {
				AdminActions.DataImport(DataImportEntityTypeEnum.KEYMEASURE_SOURCE, item.id)
					.then(() => {
						addToast('Key Measure source queued successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to queue data import. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.CanTriggerDataImport,
		},
	];

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Key Measure Source"
					pageDescription="Manage your Key Measure Sources."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});

		loadTableData();
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = () => {
		loadTableData();
	};

	const loadTableData = () => {
		setIsModalOpen(false);
		setTableLoading(true);

		KeyMeasureSource.GetAll(['keyMeasure', 'ETLSourceDatabase', 'collection_period'])
			.then((KeyMeasures) => {
				setData(KeyMeasures);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load key measure sources. Please try again later.',
					AlertVariant.danger
				);
				setTableLoading(false);
			});
	};

	const addButton = (
		<div>
			<PermissionButton
				data-testid={'key-measure-source-add-btn'}
				variant={'primary'}
				permission={Permission.CreateKeyMeasureSource}
				onClick={() => {
					AdminActions.DataImportDS(DataImportEntityTypeEnum.KEYMEASURE_SOURCE, 0).catch(
						() => {
							addToast(
								'An error occurred while trying to queue data import. Please try again later.',
								AlertVariant.danger
							);
						}
					);
				}}
			>
				Data Import All <FontAwesomeIcon icon={faFileImport} />
			</PermissionButton>
			<PermissionButton
				data-testid={'key-measure-source-add-btn'}
				variant={'primary'}
				permission={Permission.CreateKeyMeasureSource}
				onClick={() => {
					setActiveKeyMeasureSource(
						KeyMeasureSource.Default() as unknown as TKeyMeasureSource
					);
					setIsModalOpen(true);
				}}
			>
				New Key Measure Source
			</PermissionButton>
		</div>
	);

	const entityTable = (
		<ZiTable<TKeyMeasureSource>
			ariaLabel={'Key Measure Source table'}
			columns={selectedColumns}
			data={data}
			caption="Key Measure Source"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Key Measure Source Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TKeyMeasureSource>
					title={'Key Measure Source Management'}
					fields={formProperties}
					initialSubject={activeKeyMeasureSource}
					isLoading={isFormLoading}
					onSubmit={(entity) => {
						setIsFormLoading(true);
						if ('id' in entity) {
							KeyMeasureSource.Update(entity, [
								'keyMeasure',
								'ETLSourceDatabase',
								'collection_period',
							])
								.then((updated) => {
									handleSuccess();
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the key measure. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setActiveKeyMeasureSource(entity);
									setIsFormLoading(false);
								});
						} else {
							KeyMeasureSource.New(entity, [
								'keyMeasure',
								'ETLSourceDatabase',
								'collection_period',
							])
								.then((newSource) => {
									handleSuccess();
								})
								.catch(() => {
									setActiveKeyMeasureSource(entity);
									addToast(
										'An error occurred while trying to save the key measure. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={entityTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
