import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../layout/Layout';
import { useMount } from 'react-use';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Grid,
	GridItem,
	Tab,
	Tabs,
	Text,
	TextContent,
	TextVariants,
} from '@patternfly/react-core';
import { Subscription } from '../api/subscriptions/Subscription';
import DualListSelectorGeneric from '../helpers/helper-components/DualListSelectorGeneric';
import { useApplication } from '../components/user/ApplicationProvider';
import { GenericResponse } from '../api/types';
import { TDimension } from '../api/analytics/Dimension';
import {
	SubscriptionContent,
	TSubscriptionContent,
} from '../api/subscriptions/SubscriptionContent';
import {
	SubscriptionModel,
	TNewSubscriptionModel,
	TSubscriptionModel,
} from '../api/subscriptions/SubscriptionModel';
import Loader from '../components/util/Loader';
import { SubscriptionModelTypesEnum } from '../enums/subscription-model-types.enum';
import SubscriptionContentTabs from './SubscriptionContentTabs';

export default function SubscriptionEdit(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [activeTabKey, setActiveTabKey] = useState(0);
	const [nestedActiveTabKey, setNestedActiveTabKey] = useState(0);
	const [nestedActiveTabKey2, setNestedActiveTabKey2] = useState(0);
	const [isSecondTabClicked, setIsSecondTabClicked] = useState<boolean>(false);
	const [keymeasures, setKeymeasures] = React.useState<React.ReactNode[]>([]);
	const [chosenKeymeasures, setChosenKeymeasures] = React.useState<React.ReactNode[]>([]);
	const [selectDimensions, setSelectDimensions] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectDimensions, setChosenSelectDimensions] = React.useState<React.ReactNode[]>(
		[]
	);
	const [selectDimensionAttributes, setSelectDimensionAttributes] = React.useState<
		React.ReactNode[]
	>([]);
	const [chosenSelectDimensionAttributes, setChosenselectDimensionAttributes] = React.useState<
		React.ReactNode[]
	>([]);
	const chosenPaneRef_KeyMeasures = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Dimensions = useRef<HTMLDivElement>(null);
	const chosenPaneRef_DimensionAttributes = useRef<HTMLDivElement>(null);
	const { measures, dimensions } = useApplication();
	const { subscriptionId } = useParams();
	const [subscriptionName, setSubscriptionName] = React.useState('');
	const [existingSubscriptionContents, setExistingSubscriptionContents] = useState<
		TSubscriptionContent[]
	>([]);
	const [existingSubscriptionModels, setExistingSubscriptionModels] = useState<
		TSubscriptionModel[]
	>([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const [isPublishBtnDisabled, setIsPublishBtnDisabled] = useState<boolean>(false);

	useMount(() => {
		if (subscriptionId) {
			void Subscription.Get(subscriptionId).then((response) => {
				setSubscriptionName(response.name);

				void SubscriptionContent.Get(response.id).then((subscriptionContentReponse) => {
					setExistingSubscriptionContents(subscriptionContentReponse);
				});

				void SubscriptionModel.Get(response.id)
					.then((subscriptionModelReponse) => {
						const response = subscriptionModelReponse as GenericResponse<
							TSubscriptionModel[]
						>;
						const hasValidResponseItems =
							(subscriptionModelReponse as TSubscriptionModel[]).length > 0;

						if (hasValidResponseItems) {
							const response = subscriptionModelReponse as TSubscriptionModel[];
							setExistingSubscriptionModels(response);
						} else if (response.message.includes('not found')) {
							setIsLoading(false);
						}
					})
					.catch(() => {
						setIsLoading(false);
					});
			});
		}

		const mappedCommonKeymeasures: React.ReactNode[] = measures.map((option, index) => (
			<div
				key={`option${index + 1}`}
				id={option.uuid}
				data-id={option.uuid}
			>
				{option.display_name ?? option.name}
			</div>
		));

		const mappedCommonDimensions: React.ReactNode[] = dimensions.map((option, index) => (
			<div
				key={`option${index + 1}`}
				id={option.uuid}
				data-id={option.uuid}
			>
				{option.display_name ?? option.name}
			</div>
		));

		const mappedCommonDimensionAttributes: React.ReactNode[] = dimensions
			.flatMap((x) => x.dimensionAttributes)
			.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));

		setKeymeasures(mappedCommonKeymeasures);
		setSelectDimensions(mappedCommonDimensions);
		setSelectDimensionAttributes(mappedCommonDimensionAttributes);
	});

	useEffect(() => {
		if (existingSubscriptionContents.length == 0 && existingSubscriptionModels.length == 0) {
			setIsPublishBtnDisabled(true);
		} else {
			setIsPublishBtnDisabled(false);
		}
	}, [existingSubscriptionContents, existingSubscriptionModels]);

	//load logic
	useEffect(() => {
		let hasExistingItems = false;

		if (existingSubscriptionModels && existingSubscriptionModels.length > 0) {
			const existingKmIds = existingSubscriptionModels
				.filter((x) => x.entityType == SubscriptionModelTypesEnum.KEY_MEASURE)
				.map((x) => x.entityId);
			const existingKms = measures.filter((x) => existingKmIds.includes(x.uuid!));

			const mappedSelectedKMs: React.ReactNode[] = existingKms.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.display_name ?? option.name}
				</div>
			));

			setChosenKeymeasures(mappedSelectedKMs);
			hasExistingItems = true;
		}
	}, [existingSubscriptionModels]);

	useEffect(() => {
		const hasExistingDataLoaded = chosenKeymeasures && chosenKeymeasures.length > 0;

		if (hasExistingDataLoaded) {
			setIsLoading(false);
		}
	}, [chosenKeymeasures]);

	useEffect(() => {
		const dimensionIds: string[] = [];
		if (chosenPaneRef_Dimensions.current) {
			const dimensionElements =
				chosenPaneRef_Dimensions.current.querySelectorAll('[data-id]');
			dimensionElements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimensionIds.push(id);
				}
			});
		}

		const dimAttributes = dimensions
			.filter((x) => dimensionIds.includes(x.uuid!))
			.flatMap((x) => x.dimensionAttributes);

		const mappedCommonDimensionAttributes: React.ReactNode[] = dimAttributes.map(
			(option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			)
		);

		setChosenselectDimensionAttributes(mappedCommonDimensionAttributes);

		if (chosenSelectDimensions && chosenSelectDimensions.length > 0) {
			const dimsToUse = dimensions.filter((x) => !dimensionIds.some((y) => y === x.uuid));

			const mappedCommonDimensions: React.ReactNode[] = dimsToUse.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.display_name ?? option.name}
				</div>
			));

			setSelectDimensions(mappedCommonDimensions);
		} else {
			const mappedCommonDimensions: React.ReactNode[] = dimensions.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.display_name ?? option.name}
				</div>
			));

			setSelectDimensions(mappedCommonDimensions);
		}
	}, [chosenSelectDimensions]);

	useEffect(() => {
		const keyMeasureIds: string[] = [];
		if (chosenPaneRef_KeyMeasures.current) {
			const dimensionElements =
				chosenPaneRef_KeyMeasures.current.querySelectorAll('[data-id]');
			dimensionElements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					keyMeasureIds.push(id);
				}
			});
		}

		if (keyMeasureIds.length > 0) {
			const dimsBasedOnKeyMeasures = measures.find((x) => keyMeasureIds.includes(x.uuid!))!
				.dimensions as TDimension[];

			const mappedDimensionsBasedonKMs: React.ReactNode[] = dimsBasedOnKeyMeasures.map(
				(option, index) => (
					<div
						key={`option${index + 1}`}
						id={option.uuid}
						data-id={option.uuid}
					>
						{option.display_name ?? option.name}
					</div>
				)
			);
			setChosenSelectDimensions(mappedDimensionsBasedonKMs);
		} else {
			setChosenSelectDimensions([]);

			const mappedCommonKeymeasures: React.ReactNode[] = measures.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.display_name ?? option.name}
				</div>
			));
			setKeymeasures(mappedCommonKeymeasures);
		}

		const measuresToUse = measures.filter((x) => !keyMeasureIds.some((y) => y === x.uuid));
		const mappedCommonKeymeasures: React.ReactNode[] = measuresToUse.map((option, index) => (
			<div
				key={`option${index + 1}`}
				id={option.uuid}
				data-id={option.uuid}
			>
				{option.display_name ?? option.name}
			</div>
		));

		setKeymeasures(mappedCommonKeymeasures);
	}, [chosenKeymeasures, chosenPaneRef_KeyMeasures.current]);

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Subscriptions"
					pageDescription="Manage Subscriptions."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	// Define a function to handle tab selection
	const handleTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setIsSecondTabClicked(tabIndex == 1);
		setActiveTabKey(Number(tabIndex));
	};
	const handleNestedTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setNestedActiveTabKey(Number(tabIndex));
	};

	const handleNestedTabSelect2 = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setNestedActiveTabKey2(Number(tabIndex));
	};

	const getSaveSubscriptionData = (
		kmIds: string[],
		dimIds: string[],
		dimAtrributeIds: string[]
	) => {
		const KeyMeasurePromises = kmIds.map((id) => {
			const model: TNewSubscriptionModel = {
				entityId: id,
				entityType: SubscriptionModelTypesEnum.KEY_MEASURE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionModel.New(model);
		});
		const dimPromises = dimIds.map((id) => {
			const model: TNewSubscriptionModel = {
				entityId: id,
				entityType: SubscriptionModelTypesEnum.DIMENSION,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionModel.New(model);
		});
		const dimAttributePromises = dimAtrributeIds.map((id) => {
			const model: TNewSubscriptionModel = {
				entityId: id,
				entityType: SubscriptionModelTypesEnum.ATTRIBUTE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionModel.New(model);
		});

		const subscriptionModelPromises: Promise<TSubscriptionModel>[] = [
			...KeyMeasurePromises,
			...dimPromises,
			...dimAttributePromises,
		];

		return subscriptionModelPromises;
	};

	const saveSubscription = () => {
		setIsLoading(true);

		const kmIds: string[] = [];
		const dimIds: string[] = [];
		const dimAtrributeIds: string[] = [];

		if (chosenPaneRef_KeyMeasures.current) {
			const elements = chosenPaneRef_KeyMeasures.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					kmIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Dimensions.current) {
			const elements = chosenPaneRef_Dimensions.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimIds.push(id);
				}
			});
		}
		if (chosenPaneRef_DimensionAttributes.current) {
			const elements =
				chosenPaneRef_DimensionAttributes.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimAtrributeIds.push(id);
				}
			});
		}

		const isExistingSubModel =
			existingSubscriptionModels && existingSubscriptionModels.length > 0;

		let deletePromises: Promise<boolean>[] = [];

		if (isExistingSubModel) {
			deletePromises = [SubscriptionModel.Delete(subscriptionId ?? '0')];
		}

		//delete pre-existing records
		Promise.all(deletePromises)
			.then(() => {
				const combinedList = getSaveSubscriptionData(kmIds, dimIds, dimAtrributeIds);
				Promise.all(combinedList)
					.then((responses: TSubscriptionModel[]) => {
						setIsLoading(false);
						addToast('Sucessfully saved Subscription Model', AlertVariant.success);
					})
					.catch(() => {
						setIsLoading(false);
						addToast(
							'An error occured while saving Subscription Model',
							AlertVariant.danger
						);
					});
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const PublishSubscription = () => {
		Subscription.Publish(subscriptionId!)
			.then((response) => {
				addToast('Subscription successfully Published.', AlertVariant.success);
				navigate('/setup/subscription/builder');
			})
			.catch(() => {
				addToast('An Error cccured: unable to Publish Subscription.', AlertVariant.danger);
			});
	};

	return (
		<React.Fragment>
			{' '}
			<Card>
				<Grid span={12}>
					<GridItem span={11}>
						<TextContent className="p-sub">
							<Text component={TextVariants.h2}>
								Subscription: {subscriptionName}
							</Text>
						</TextContent>
					</GridItem>
					<GridItem span={1}>
						<TextContent className="p-sub">
							<Button
								isDisabled={isPublishBtnDisabled}
								onClick={() => PublishSubscription()}
							>
								Publish
							</Button>
						</TextContent>
					</GridItem>
				</Grid>

				<br />
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<Tabs
							className="parent-tabs"
							activeKey={activeTabKey}
							onSelect={handleTabSelect}
						>
							<Tab
								eventKey={0}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Model<strong/>',
										}}
									></span>
								}
							>
								{/* Content for Tab 1 */}
								<Tabs
									activeKey={nestedActiveTabKey}
									onSelect={handleNestedTabSelect}
									className="child-tabs"
								>
									<Tab
										eventKey={0}
										title="Key Measures"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Key Measures"
												rightHeading="Chosen Key Measures"
												leftItems={keymeasures}
												setLeftItems={setKeymeasures}
												chosenOptions={chosenKeymeasures}
												setChosenOptions={setChosenKeymeasures}
												chosenPaneRef={chosenPaneRef_KeyMeasures}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={1}
										title="Dimensions"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Dimensions"
												rightHeading="Chosen Dimensions"
												leftItems={selectDimensions}
												setLeftItems={setSelectDimensions}
												chosenOptions={chosenSelectDimensions}
												setChosenOptions={setChosenSelectDimensions}
												chosenPaneRef={chosenPaneRef_Dimensions}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={2}
										title="Dimension Attributes"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Dimension Attributes"
												rightHeading="Chosen Key Dimensions Attributes"
												leftItems={selectDimensionAttributes}
												setLeftItems={setSelectDimensionAttributes}
												chosenOptions={chosenSelectDimensionAttributes}
												setChosenOptions={
													setChosenselectDimensionAttributes
												}
												chosenPaneRef={chosenPaneRef_DimensionAttributes}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
								</Tabs>
							</Tab>
							<Tab
								eventKey={1}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Content<strong/>',
										}}
									></span>
								}
							>
								{/* Content for Tab 2 */}
								<SubscriptionContentTabs
									isFormLoading={isLoading}
									handleNestedTabSelect2={handleNestedTabSelect2}
									nestedActiveTabKey2={nestedActiveTabKey2}
									setIsLoading={setIsLoading}
								/>
							</Tab>
						</Tabs>
					)}
					{!isSecondTabClicked && !isLoading && (
						<>
							<br />
							<div className="pull-right">
								<Button
									variant="primary"
									onClick={() => {
										navigate('/setup/subscription/builder');
									}}
								>
									Back
								</Button>
								<Button
									variant="primary"
									onClick={() => saveSubscription()}
								>
									Save
								</Button>
							</div>
						</>
					)}
				</CardBody>
			</Card>
		</React.Fragment>
	);
}
