import axiosConfig from '../axiosConfig';

export type TSubscriptionContent = {
	id: string;
	subscription: string;
	version: number;
	contentType: string;
	contentId: string;
};

export type TNewSubscriptionContent = Omit<TSubscriptionContent, 'id'>;

export class SubscriptionContent implements TSubscriptionContent {
	id: string;
	subscription: string;
	version: number;
	contentType: string;
	contentId: string;

	constructor(sub: TSubscriptionContent) {
		this.id = sub.id;
		this.subscription = sub.subscription;
		this.contentType = sub.contentType;
		this.version = sub.version;
		this.contentId = sub.contentId;
	}

	public static Default(): TSubscriptionContent {
		return {
			id: '',
			version: 0,
			subscription: '',
			contentType: '',
			contentId: '',
		};
	}

	public static GetAll(
		expand: (keyof TSubscriptionContent)[] = []
	): Promise<TSubscriptionContent[]> {
		return axiosConfig.instance
			.get(`app/subscriptions`, { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TSubscriptionContent[]).map((entity) => {
					return entity;
				});
			});
	}

	public static Get(GuidId: string): Promise<TSubscriptionContent[]> {
		return axiosConfig.instance
			.get(`app/subscription-content/subscription/${GuidId}`, {})
			.then((res) => {
				return res.data as TSubscriptionContent[];
			});
	}

	public static Delete(id: string): Promise<boolean> {
		return axiosConfig.instance.delete(`app/subscription-content/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TNewSubscriptionContent): Promise<TSubscriptionContent> {
		return axiosConfig.instance.post(`app/subscription-content`, entity).then((res) => {
			return res.data as TSubscriptionContent;
		});
	}

	public static Update(entity: TSubscriptionContent): Promise<TSubscriptionContent> {
		return axiosConfig.instance.patch('app/subscription-content', entity).then((res) => {
			return res.data as TSubscriptionContent;
		});
	}
}

export class SubscriptionDashboard implements SubscriptionDashboard {
	dashboardIds: number[];

	constructor() {
		this.dashboardIds = [];
	}

	static Default(): SubscriptionDashboard {
		return new SubscriptionDashboard();
	}
}

export type TSubscriptionDashboard = {
	dashboardIds: number[];
};

export class SubscriptionChart implements SubscriptionChart {
	chartIds: number[];

	constructor() {
		this.chartIds = [];
	}

	static Default(): SubscriptionChart {
		return new SubscriptionChart();
	}
}

export type TSubscriptionChart = {
	chartIds: number[];
};

export class SubscriptionReportTable implements SubscriptionReportTable {
	entityIds: number[];

	constructor() {
		this.entityIds = [];
	}

	static Default(): SubscriptionReportTable {
		return new SubscriptionReportTable();
	}
}

export type TSubscriptionReportTable = {
	entityIds: number[];
};

export class SubscriptionDataframe implements SubscriptionDataframe {
	dataframeIds: number[];

	constructor() {
		this.dataframeIds = [];
	}

	static Default(): SubscriptionDataframe {
		return new SubscriptionDataframe();
	}
}

export type TSubscriptionDataframe = {
	dataframeIds: number[];
};

export class SubscriptionUserGroup implements SubscriptionUserGroup {
	userGroupIds: number[];

	constructor() {
		this.userGroupIds = [];
	}

	static Default(): SubscriptionUserGroup {
		return new SubscriptionUserGroup();
	}
}

export type TSubscriptionUserGroup = {
	userGroupIds: number[];
};

export class SubscriptionRole implements SubscriptionRole {
	roleIds: number[];

	constructor() {
		this.roleIds = [];
	}

	static Default(): SubscriptionRole {
		return new SubscriptionRole();
	}
}

export type TSubscriptionRole = {
	roleIds: number[];
};
