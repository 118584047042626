import {
	Button,
	Modal,
	ModalVariant,
	Popover,
	TextInput,
	Text,
	AlertVariant,
	Pagination,
	PaginationVariant,
} from '@patternfly/react-core';
import {
	TableComposable,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TbodyProps,
	TrProps,
} from '@patternfly/react-table';
import styles from '@patternfly/react-styles/css/components/Table/table';
import React, { FormEvent, useEffect, useState } from 'react';
import Loader from '../../components/util/Loader';
import { useMount } from 'react-use';
import { generateGUID } from '../../helpers/guid.helper';
import TwitterPicker from 'react-color/lib/components/twitter/Twitter';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import './DimensionValueEditorModal.scss';
import * as lodash from 'lodash';
import { Dimension, DimensionTypeEnum, TDimensionValue } from '../../api/analytics/Dimension';
import { DimensionValueDnd } from '../../types/dnd/dimension-value-dnd';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { DimensionAttribute } from '../../api/analytics/DimensionAttribute';
import { DimensionValueListResponse } from '../../api/types';
import { ZIDatePicker } from '../../components/date-picker/ZIDatePicker';

type Props = {
	id: number;
	name: string;
	type: string;
	isDimension: boolean;
	handleClose: () => void;
};

const DimensionValueEditorModal = (props: Props) => {
	const { id, isDimension, handleClose, name } = props;

	const columns = ['Value', 'Color', 'Actions'];

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [dimValues, setDimValues] = useState<DimensionValueDnd[]>([]);
	const [draggedItemId, setDraggedItemId] = React.useState<string | null>(null);
	const [draggingToItemIndex, setDraggingToItemIndex] = React.useState<number | null>(null);
	const [isDragging, setIsDragging] = React.useState(false);
	const [itemOrder, setItemOrder] = React.useState<string[]>([]);
	const [tempItemOrder, setTempItemOrder] = React.useState<string[]>([]);
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [perPage, setPerPage] = React.useState<number>(10);
	const [totalValues, setTotalValues] = React.useState<number>();
	const [searchTerm, setSearchTerm] = React.useState<string>('');

	const bodyRef = React.useRef<HTMLTableSectionElement | null>(null);

	const [dataType, _] = React.useState<string>(props.type);

	const { addToast } = useToast();

	useMount(() => {
		getData(1, 10, '');
	});

	useEffect(() => {
		if (searchTerm.length > 2 || searchTerm.length == 0) {
			setPageNumber(1);
			getData(1, perPage, searchTerm);
		}
	}, [searchTerm]);

	const getData = (pageNumber: number, perPage: number, searchTerm: string) => {
		setIsLoading(true);

		if (isDimension) {
			Dimension.GetValues(id, pageNumber, perPage, searchTerm)
				.then((response) => {
					handleDataResponse(response);
				})
				.catch(() => {
					setIsLoading(false);
					addToast('There was an issue retrieving the values', AlertVariant.danger);
				});
		} else {
			DimensionAttribute.GetValues(id, pageNumber, perPage, searchTerm)
				.then((response) => {
					handleDataResponse(response);
				})
				.catch(() => {
					setIsLoading(false);
					addToast('There was an issue retrieving the values', AlertVariant.danger);
				});
		}
	};

	const handleDataResponse = (response: DimensionValueListResponse) => {
		if (response.data.length) {
			setTotalValues(response.total);

			const newValObject = response.data.map((val, index) => {
				return {
					color: val.color ?? '#000000',
					id: generateGUID(),
					originalId: val.id,
					sequence: val.sequence ?? index + 1,
					value: val.value,
				};
			});

			setItemOrder(newValObject.map((val) => val.id));
			setDimValues(newValObject);
		}

		setIsLoading(false);
	};

	const onDragStart: TrProps['onDragStart'] = (evt) => {
		evt.dataTransfer.effectAllowed = 'move';
		evt.dataTransfer.setData('text/plain', evt.currentTarget.id);
		const draggedItemId = evt.currentTarget.id;

		evt.currentTarget.classList.add(styles.modifiers.ghostRow);
		evt.currentTarget.setAttribute('aria-pressed', 'true');

		setDraggedItemId(draggedItemId);
		setIsDragging(true);
	};

	const moveItem = (arr: string[], i1: string, toIndex: number) => {
		const fromIndex = arr.indexOf(i1);
		if (fromIndex === toIndex) {
			return arr;
		}
		const temp = arr.splice(fromIndex, 1);
		arr.splice(toIndex, 0, temp[0]);

		return arr;
	};

	const move = (itemOrder: string[]) => {
		const ulNode = bodyRef.current;
		const nodes = Array.from(ulNode?.children ?? []);

		if (nodes && ulNode) {
			if (nodes.map((node) => node.id).every((id, i) => id === itemOrder[i])) {
				return;
			}
			while (ulNode.firstChild) {
				if (ulNode.lastChild) {
					ulNode.removeChild(ulNode.lastChild);
				}
			}

			itemOrder.forEach((id) => {
				const item = nodes.find((n) => n.id === id);
				if (item) {
					ulNode.appendChild(item);
				}
			});
		}
	};

	const onDragCancel = () => {
		Array.from(bodyRef.current?.children ?? []).forEach((el) => {
			el.classList.remove(styles.modifiers.ghostRow);
			el.setAttribute('aria-pressed', 'false');
		});
		setDraggedItemId(null);
		setDraggingToItemIndex(null);
		setIsDragging(false);
	};

	const onDragLeave: TbodyProps['onDragLeave'] = (evt) => {
		if (!isValidDrop(evt)) {
			move(itemOrder);
			setDraggingToItemIndex(null);
		}
	};

	const isValidDrop = (evt: React.DragEvent<HTMLTableSectionElement | HTMLTableRowElement>) => {
		const ulRect = bodyRef.current?.getBoundingClientRect();
		if (ulRect) {
			return (
				evt.clientX > ulRect.x &&
				evt.clientX < ulRect.x + ulRect.width &&
				evt.clientY > ulRect.y &&
				evt.clientY < ulRect.y + ulRect.height
			);
		}

		return false;
	};

	const onDrop: TrProps['onDrop'] = (evt) => {
		if (isValidDrop(evt)) {
			setItemOrder(tempItemOrder);
		} else {
			onDragCancel();
		}
	};

	const onDragOver: TbodyProps['onDragOver'] = (evt) => {
		evt.preventDefault();

		const curListItem = (evt.target as HTMLTableSectionElement).closest('tr');
		if (
			!curListItem ||
			!bodyRef.current?.contains(curListItem) ||
			curListItem.id === draggedItemId
		) {
			return null;
		} else {
			const dragId = curListItem.id;
			const newDraggingToItemIndex = Array.from(bodyRef.current.children).findIndex(
				(item) => item.id === dragId
			);
			if (newDraggingToItemIndex !== draggingToItemIndex) {
				const tempItemOrder = moveItem(
					[...itemOrder],
					draggedItemId ?? '',
					newDraggingToItemIndex
				);

				move(tempItemOrder);
				setDraggingToItemIndex(newDraggingToItemIndex);
				setTempItemOrder(tempItemOrder);
			}
		}
	};

	const onDragEnd: TrProps['onDragEnd'] = (evt) => {
		const target = evt.target as HTMLTableRowElement;
		target.classList.remove(styles.modifiers.ghostRow);
		target.setAttribute('aria-pressed', 'false');
		setDraggedItemId(null);
		setDraggingToItemIndex(null);
		setIsDragging(false);
	};

	const onSave = () => {
		const ids = Array.from(bodyRef.current?.children ?? []).map((element) => element.id);
		const lowestSequence = Math.min(...dimValues.map((item) => item.sequence));
		const updatedValues = lodash
			.cloneDeep(dimValues)
			.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id))
			.map((val, index) => {
				return {
					id: val.originalId,
					color: val.color,
					value: val.value,
					sequence: lowestSequence + index,
				};
			});

		setIsLoading(true);

		if (isDimension) {
			Dimension.UpdateValues(id, updatedValues)
				.then(() => {
					setPageNumber(1);
					getData(1, perPage, searchTerm);
				})
				.catch(() => {
					setIsLoading(false);
					addToast('There was an issue saving the values', AlertVariant.danger);
				});
		} else {
			DimensionAttribute.UpdateValues(id, updatedValues)
				.then(() => {
					setPageNumber(1);
					getData(1, perPage, searchTerm);
				})
				.catch(() => {
					setIsLoading(false);
					addToast('There was an issue saving the values', AlertVariant.danger);
				});
		}
	};

	const getModalButtons = () => {
		const buttons: React.ReactNode[] = [];

		buttons.push(
			<Button
				key="confirm"
				variant="primary"
				onClick={onSave}
				disabled={isLoading}
			>
				Save
			</Button>
		);

		buttons.push(
			<Button
				key="cancel"
				variant="secondary"
				onClick={handleClose}
				disabled={isLoading}
			>
				Cancel
			</Button>
		);

		return buttons;
	};

	const getRowControls = (row: DimensionValueDnd) => {
		return Object.keys(row)
			.filter((key) => key != 'sequence')
			.sort((a, b) => b.localeCompare(a))
			.map((key, keyIndex) => {
				if (key != 'id' && key != 'originalId') {
					return (
						<Td
							key={`${row.id}_${keyIndex}`}
							dataLabel={columns[keyIndex]}
						>
							{getRowControl(key, row)}
						</Td>
					);
				}
			});
	};

	const setRowValue = (
		value: FormEvent<HTMLInputElement> | string,
		key: keyof TDimensionValue,
		row: DimensionValueDnd
	) => {
		setDimValues(
			dimValues.map((item) => {
				if (item.id === row.id) {
					if (typeof value === 'object') {
						item[key] = value.currentTarget.value as never;
					} else {
						(item[key] as any) = value;
					}
				}

				return item;
			})
		);
	};

	const getRowControl = (type: string, row: DimensionValueDnd) => {
		switch (type) {
			case 'value':
				return dataType === DimensionTypeEnum.DATE ? (
					<ZIDatePicker
						id="value-date-picker"
						name="value-date-picker"
						value={row.value}
						onChange={(value) => setRowValue(value, type, row)}
					/>
				) : (
					<TextInput
						value={row.value}
						type={getInputType()}
						onChange={(_event, value) => setRowValue(value, type, row)}
						aria-label="text input example"
					/>
				);
			case 'color':
				return (
					<Popover
						headerContent={'Colors'}
						bodyContent={
							<>
								<Text component="h3">
									{' '}
									<div
										className="active-color-preview"
										style={{
											backgroundColor: row.color,
										}}
									></div>{' '}
									<span className="active-color-label">
										{`Current Color is ${row.color}`}
									</span>
								</Text>
								<span className="spacer-xs"></span>
								<TwitterPicker
									color={'#FFFFFF'}
									onChangeComplete={(color, event) =>
										setRowValue(color.hex, type, row)
									}
								/>
							</>
						}
					>
						<div className="color-and-button">
							<Button
								variant="plain"
								style={{
									backgroundColor: row.color,
									color: 'transparent',
								}}
							>
								color
							</Button>
						</div>
					</Popover>
				);
		}
	};

	const addNewValue = () => {
		const id = generateGUID();
		const item = {
			id: id,
			color: '#000000',
			value: '',
			sequence: 0,
		};
		setItemOrder([id, ...itemOrder]);
		setDimValues([item, ...dimValues]);
	};

	const removeRow = (row: DimensionValueDnd) => {
		if (row.originalId) {
			setIsLoading(true);

			if (isDimension) {
				Dimension.DeleteValue(id, row.originalId ?? 0)
					.then(() => {
						setPageNumber(1);
						getData(1, perPage, searchTerm);
					})
					.catch(() => {
						setIsLoading(false);
						addToast('There was an issue deleting the value', AlertVariant.danger);
					});
			} else {
				DimensionAttribute.DeleteValue(id, row.originalId ?? 0)
					.then(() => {
						setPageNumber(1);
						getData(1, perPage, searchTerm);
					})
					.catch(() => {
						setIsLoading(false);
						addToast('There was an issue deleting the value', AlertVariant.danger);
					});
			}
		} else {
			setItemOrder(itemOrder.filter((item) => item != row.id.toString()));
			setDimValues(dimValues.filter((val) => val.id != row.id));
		}
	};

	const onSetPage = (
		_event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
		newPage: number
	) => {
		setPageNumber(newPage);
		getData(newPage, perPage, searchTerm);
	};

	const onPerPageSelect = (
		_event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
		newPerPage: number,
		newPage: number
	) => {
		setPerPage(newPerPage);
		setPageNumber(newPage);
		getData(newPage, newPerPage, searchTerm);
	};

	const getInputType = (): 'number' | 'text' | 'time' | 'date' | 'datetime-local' | undefined => {
		let type = 'text';
		switch (dataType) {
			case DimensionTypeEnum.TIME:
				type = 'time';
				break;
			case DimensionTypeEnum.DATE:
				type = 'date';
				break;
			case DimensionTypeEnum.DATETIME:
				type = 'datetime-local';
				break;
			case DimensionTypeEnum.FLOAT:
			case DimensionTypeEnum.INTEGER:
				type = 'number';
				break;
			default:
				type = 'text';
				break;
		}
		return type as 'number' | 'text' | 'time' | 'date' | 'datetime-local' | undefined;
	};

	return (
		<React.Fragment>
			<Modal
				title={`Configure ${name} Values`}
				variant={ModalVariant.medium}
				isOpen={true}
				onClose={handleClose}
				actions={getModalButtons()}
				className="dimension-value-modal"
			>
				{isLoading ? (
					<Loader />
				) : (
					<React.Fragment>
						<div className="actions">
							<TextInput
								type="text"
								aria-describedby="Search By Value"
								placeholder="Search By Value"
								value={searchTerm}
								onChange={(value) => setSearchTerm(value)}
							/>
							<Button
								variant="primary"
								onClick={addNewValue}
							>
								Add Value
							</Button>
						</div>

						<div className="value-table">
							<TableComposable
								aria-label="Draggable table"
								className={`${isDragging ? styles.modifiers.dragOver : ''}`}
							>
								<Thead>
									<Tr>
										<Th />
										{columns.map((column, columnIndex) => (
											<Th key={columnIndex}>{column}</Th>
										))}
									</Tr>
								</Thead>
								<Tbody
									ref={bodyRef}
									onDragOver={onDragOver}
									onDrop={onDragOver}
									onDragLeave={onDragLeave}
								>
									{dimValues.map((row, rowIndex) => (
										<Tr
											key={rowIndex}
											id={row.id.toString()}
											draggable
											onDrop={onDrop}
											onDragEnd={onDragEnd}
											onDragStart={onDragStart}
										>
											<Td
												draggableRow={{
													id: `draggable-row-${row.id}`,
												}}
											/>
											{getRowControls(row)}
											<Td>
												<Button
													variant="plain"
													aria-label="Action"
													onClick={() => {
														removeRow(row);
													}}
												>
													<TrashIcon />
												</Button>
											</Td>
										</Tr>
									))}
								</Tbody>
							</TableComposable>
							<Pagination
								perPageComponent="button"
								itemCount={totalValues}
								widgetId="table-pagination"
								perPage={perPage}
								page={pageNumber}
								variant={PaginationVariant.bottom}
								onSetPage={onSetPage}
								onPerPageSelect={onPerPageSelect}
							/>
						</div>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default DimensionValueEditorModal;
