import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import { TUser, User } from '../../api/security/User';
import { Role, TRole } from '../../api/security/Role';
import { Group, TGroup } from '../../api/security/Group';
import ZiTable, { Action, Column } from '../../components/table/ZiTable';
import FilterTableLayout from '../../layout/FilterTableLayout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalBoxFooter, ModalVariant } from '@patternfly/react-core';
import { Permission } from '../../enums/permission.enum';
import PermissionButton from '../../components/button/PermissionButton';
import SchnurForm, {
	Field,
	IDualListOption,
	ISelectOption,
	UIType,
} from '../../components/form/SchnurForm/SchnurForm';

export default function Users(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TUser[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isUserDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [activeUser, setActiveUser] = useState<TUser>(User.Default() as TUser);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [roles, setRole] = useState<TRole[]>([]);
	const [groups, setGroups] = useState<TGroup[]>([]);
	const navigate = useNavigate();

	const selectedColumns: Column<TUser>[] = [
		{
			title: 'Account',
			columnName: 'account',
		},
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Role',
			columnName: 'role',
			customAccessor: (item) => {
				if (typeof item.role === 'object') {
					return item.role.name;
				}

				return item.role;
			},
			sortable: false,
		},
		{
			title: 'Groups',
			columnName: 'groups',
			customAccessor: (item) => {
				return item.groups.length;
			},
			sortable: true,
		},
	];

	const actions: Action<TUser>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			callback: (item) => {
				setActiveUser(item);
				setIsModalOpen(true);
			},
			permission: Permission.EditUser,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			callback: (item) => {
				setActiveUser(item);
				setIsDeleteModalOpen(true);
			},
			permission: Permission.DeleteUser,
		},
		{
			name: 'User Dimension Filters',
			callback: (item) => {
				navigate(`/setup/security/user/${item.id}/dimension/filters`);
			},
			permission: Permission.EditUser,
		},
		{
			name: 'User Attribute Filters',
			callback: (item) => {
				navigate(`/setup/security/user/${item.id}/dimension/attribute/filters`);
			},
			permission: Permission.EditUser,
		},
		{
			name: <>Request Password Reset</>,
			callback: (item) => {
				User.RequestResetPassword(item)
					.then(() => {
						addToast('Password reset email sent successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to send password reset email. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.CanResetUserPassword,
		},
		{
			name: <>Clear Password History</>,
			callback: (item) => {
				User.ClearPasswordHistory(item)
					.then(() => {
						addToast('Password history cleared successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to clear password history. Please try again later.',
							AlertVariant.danger
						);
					});
			},
			permission: Permission.CanClearPasswordHistory,
		},
	];

	const formProperties: Field<TUser>[] = [
		{
			title: 'Account Name',
			columnName: 'account',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'A unique identifier that will be used to log in.',
			},
			required: true,
		},
		{
			title: 'User Full Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The Full name of the account holder.',
			},
			required: true,
		},
		{
			title: 'Email',
			columnName: 'email',
			uiSchema: {
				type: UIType.TEXT,
				helpText:
					'The email of the account holder. This email will be used to set and their passwords.',
			},
		},
		{
			title: 'Account Status',
			columnName: 'status',
			uiSchema: {
				type: UIType.SELECT,
				options: [
					{
						key: 'Active',
						value: 'Active',
					},
					{
						key: 'Revoked',
						value: 'Revoked',
					},
					{
						key: 'Locked',
						value: 'Locked',
					},
				],
				initialSelection: 'id' in activeUser ? activeUser.status : 'Active',
				onSelect: (value: ISelectOption) => {
					return value.value;
				},
			},
		},
		{
			title: 'Role',
			columnName: 'role',
			uiSchema: {
				type: UIType.SELECT,
				options: roles.map((role) => ({ key: role.id, value: role.name } as ISelectOption)),
				initialSelection:
					'id' in activeUser && typeof activeUser.role === 'object'
						? activeUser.role.name
						: undefined,
				onSelect: (value: ISelectOption) => value.key,
			},
			required: true,
		},
		{
			title: 'Groups',
			columnName: 'groups',
			uiSchema: {
				type: UIType.DUAL_LIST,
				options: groups.map(
					(group) => ({ key: group.id, value: group.name } as ISelectOption)
				),
				onSelect: (value: IDualListOption[]) => {
					return [...value.map((option) => Number(option.key))];
				},
				selected: activeUser.groups
					.map((group) => {
						if (typeof group === 'object') {
							return {
								key: group.id,
								value: group.name,
							} as ISelectOption;
						} else if (typeof group === 'number') {
							const foundGroup = groups.find((g) => g.id === group);
							return {
								key: foundGroup?.id,
								value: foundGroup?.name,
							} as ISelectOption;
						}

						return;
					})
					.filter((option) => option !== undefined) as ISelectOption[],
			},
			required: true,
		},
	];

	useMount(() => {
		User.GetAll(['role', 'groups'])
			.then((users) => {
				setTableData(users);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to fetch Users. Please try again later.',
					AlertVariant.danger
				);
			});

		Role.GetAll()
			.then((roles) => {
				setRole(roles);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to load this users role. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});

		Group.GetAll()
			.then((groups) => {
				setGroups(groups);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to this users groups. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setIsFormLoading(false);
			});
	});

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Users"
					pageDescription="Manage Users."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			permission={Permission.CreateUser}
			data-testid={'user-create'}
			variant={'primary'}
			onClick={() => {
				setActiveUser(User.Default() as TUser);
				setIsModalOpen(true);
			}}
		>
			New System User
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);
		User.Delete(activeUser.id)
			.then(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				setTableData((prev) => prev.filter((user) => user.id !== activeUser.id));
				addToast('User deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				addToast(
					'An error occurred while trying to delete User. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const handleSuccess = (value: TUser) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const userTable = (
		<ZiTable<TUser>
			ariaLabel={'Users'}
			columns={selectedColumns}
			data={tableData}
			caption="Users"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="User Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TUser>
					title={'User Management'}
					fields={formProperties}
					initialSubject={activeUser}
					isLoading={isFormLoading}
					onSubmit={(user) => {
						setIsFormLoading(true);

						if (user.id) {
							User.Update(user, ['role', 'groups'])
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							User.New(user, ['role', 'groups'])
								.then((newUser) => {
									handleSuccess(newUser);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the User. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>

			<Modal
				title="User Delete Confirmation"
				variant="small"
				isOpen={isUserDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>Are you sure you want to delete {activeUser.name}?</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right add-question-footer">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal>

			<React.Fragment>
				<FilterTableLayout
					table={userTable}
					layoutActions={[addButton]}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
