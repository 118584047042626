import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
	RefObject,
	FormEvent,
} from 'react';
import { DualListSelector, Grid, GridItem, SearchInput } from '@patternfly/react-core';

type Props = {
	leftItems?: React.ReactNode[];
	setLeftItems?: Dispatch<SetStateAction<React.ReactNode[]>>;
	onComponentMount?: () => void;
	leftHeading: string;
	rightHeading: string;
	chosenOptions: React.ReactNode[];
	setChosenOptions: Dispatch<SetStateAction<React.ReactNode[]>>;
	chosenPaneRef: RefObject<HTMLDivElement>;
	isNonModalDualList?: boolean;
	isDisabled?: boolean;
};

const DualListSelectorGeneric = (props: Props) => {
	const [filteredLeftItems, setFilteredLeftItems] = useState<React.ReactNode[]>(
		props.leftItems || []
	);
	const [filteredRightItems, setFilteredRightItems] = useState<React.ReactNode[]>(
		props.chosenOptions || []
	);
	const [searchLeft, setSearchLeft] = useState('');
	const [searchRight, setSearchRight] = useState('');

	const leftSearchRef = useRef<HTMLInputElement>(null);
	const rightSearchRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (props.onComponentMount) {
			props.onComponentMount();
		}
		setFilteredLeftItems(props.leftItems || []);
		setFilteredRightItems(props.chosenOptions || []);
	}, [props.leftItems, props.chosenOptions, props.onComponentMount]);

	useEffect(() => {
		setFilteredLeftItems(filterItems(props.leftItems || [], searchLeft));
		if (leftSearchRef.current) {
			leftSearchRef.current.value = searchLeft;
			leftSearchRef.current.focus();
		}
	}, [searchLeft, props.leftItems]);

	useEffect(() => {
		setFilteredRightItems(filterItems(props.chosenOptions || [], searchRight));
		if (rightSearchRef.current) {
			rightSearchRef.current.value = searchRight;
			rightSearchRef.current.focus();
		}
	}, [searchRight, props.chosenOptions]);

	const extractText = (node: React.ReactNode): string => {
		if (typeof node === 'string' || typeof node === 'number') {
			return node.toString();
		} else if (React.isValidElement(node)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
			return node.props?.children ? extractText(node.props.children) : '';
		} else if (Array.isArray(node)) {
			return node.map(extractText).join('');
		}
		return '';
	};

	const filterItems = (items: React.ReactNode[], value: string) =>
		items.filter((item) => extractText(item).toLowerCase().includes(value));

	const onFilter = (event: FormEvent<HTMLInputElement>, paneType: 'left' | 'right') => {
		const changeEvent = event as unknown as ChangeEvent<HTMLInputElement>;
		const value = changeEvent.target.value.toLowerCase();

		if (paneType === 'left') {
			setSearchLeft(value);
		} else {
			setSearchRight(value);
		}
	};

	const onListChange = (
		newAvailableOptions: React.ReactNode[],
		newChosenOptions: React.ReactNode[]
	) => {
		if (props.setLeftItems) {
			props.setLeftItems(newAvailableOptions.sort());
		}
		props.setChosenOptions(newChosenOptions.sort());
		setFilteredLeftItems(newAvailableOptions.sort());
		setFilteredRightItems(newChosenOptions.sort());
	};

	return (
		<div className="dual-list-container">
			<br />
			{props.isNonModalDualList ? (
				<Grid span={12}>
					<GridItem span={5}>
						{' '}
						<label className="bold">{props.leftHeading}</label>
					</GridItem>
					<GridItem span={2}></GridItem>
					<GridItem span={5}>
						{' '}
						<label className={`bold non-modal-dual-list-search`}>
							{props.rightHeading}
						</label>
					</GridItem>
				</Grid>
			) : (
				<div className="dual-list-search-label">
					<label className="bold">{props.leftHeading}</label>
					<br />
					<label className="bold">{props.rightHeading}</label>
					<br />
				</div>
			)}

			<div className="dual-list-search">
				<SearchInput
					type="search"
					onChange={(event) => onFilter(event, 'left')}
					ref={leftSearchRef}
					className="dual-list-search-input"
					style={{ marginLeft: '-3px' }}
				/>
				<SearchInput
					type="search"
					onChange={(event) => onFilter(event, 'right')}
					ref={rightSearchRef}
					className="dual-list-search-input"
					style={{ marginLeft: '0px' }}
				/>
			</div>
			<DualListSelector
				availableOptions={filteredLeftItems}
				chosenOptions={filteredRightItems}
				onListChange={onListChange}
				className={`dual-list-selector-basic-search ${
					props.isDisabled ? 'disabled-dual-list-selector' : ''
				}`}
			/>
			<div
				ref={props.chosenPaneRef}
				className="chosen-pane hidden"
			>
				{props.chosenOptions}
			</div>
		</div>
	);
};

export default DualListSelectorGeneric;
