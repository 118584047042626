import { faClock, faStar } from '@fortawesome/pro-light-svg-icons';
import { INavigationLink } from '../../layout/PageNavigationLinks';

export const PresentSidebarContext: INavigationLink[] = [
	{
		name: 'Recents',
		iconDefinition: faClock,
		to: '/present/recents',
		id: 'present-recents',
	},
	{
		name: 'Favorites',
		iconDefinition: faStar,
		to: '/present/favorites',
		id: 'present-favorites',
	},
];
