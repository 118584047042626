import React, { createContext, useContext, useState, ReactNode } from 'react';
import { DashboardFilterStore } from '../../types/dashboards/dashboard-filter-store';
import { Chart, TChart } from '../../api/charts/Chart';
import { TDataframe, TReport } from '../../api/types';
import { Dashboard, TDashboard } from '../../api/dashbboards/Dashboards';
import { Present, TPresent } from '../../api/present/Present';

interface CommonStoreContext {
	sharedDashboardFilters: DashboardFilterStore[];
	setSharedDashboardFilters: (filters: DashboardFilterStore[]) => void;
	currentSelectedChart: TChart;
	setCurrentSelectedChart: (chart: TChart) => void;
	currentSelectedDataframe: TDataframe | undefined;
	setCurrentSelectedDataframe: (dataframe: TDataframe | undefined) => void;
	currentSelectedTableReport: TReport | undefined;
	setCurrentSelectedTableReport: (entity: TReport | undefined) => void;
	currentSelectedDashboard: TDashboard;
	setCurrentSelectedDashboard: (dashboard: TDashboard) => void;
	currentSelectedPresentation: TPresent;
	setCurrentSelectedPresentation: (present: TPresent) => void;
}

const CommonStoreContext = createContext<CommonStoreContext | null>(null);

export const CommonStoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [sharedDashboardFilters, setSharedDashboardFilters] = useState<DashboardFilterStore[]>(
		[]
	);
	const [currentSelectedChart, setCurrentSelectedChart] = useState<TChart>(Chart.Default());
	const [currentSelectedDataframe, setCurrentSelectedDataframe] = useState<TDataframe>();
	const [currentSelectedTableReport, setCurrentSelectedTableReport] = useState<TReport>();
	const [currentSelectedDashboard, setCurrentSelectedDashboard] = useState<TDashboard>(
		Dashboard.Default()
	);
	const [currentSelectedPresentation, setCurrentSelectedPresentation] = useState<TPresent>(
		Present.Default()
	);

	return (
		<CommonStoreContext.Provider
			value={{
				sharedDashboardFilters,
				setSharedDashboardFilters,
				currentSelectedChart,
				setCurrentSelectedChart,
				currentSelectedDataframe,
				setCurrentSelectedDataframe,
				currentSelectedTableReport,
				setCurrentSelectedTableReport,
				currentSelectedDashboard,
				setCurrentSelectedDashboard,
				currentSelectedPresentation,
				setCurrentSelectedPresentation,
			}}
		>
			{children}
		</CommonStoreContext.Provider>
	);
};

export const useCommonStoreContext = () => {
	const context = useContext(CommonStoreContext);
	if (!context)
		throw new Error('useCommonStoreContext must be used within a CommonStoreProvider');
	return context;
};
