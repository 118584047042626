import React, { Dispatch, useEffect, useState } from 'react';
import ZiChart, { ZiChartProps } from './ZiChart';
import { Chart, TChart } from '../../api/charts/Chart';
import { AlertVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import Loader from '../util/Loader';
import { Dataframe, TNewDataframeFilter } from '../../api/dataframes/Dataframes';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	DimSettings,
	LambdaInsightsRequest,
	LambdaInsightsResponse,
	TDataframe,
	TDateRange,
} from '../../api/types';
import { formatCsvDataToColumns, MultipartResponse } from '../../helpers/multipart-response.helper';
import { Link, useParams } from 'react-router-dom';
import { useApplication, useUser } from '../user/ApplicationProvider';
import { ChartSeriesChartTypes } from '../../types/charts/chart-options';
import { TUnitType } from '../../api/analytics/UnitType';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { ZiChartSettings } from '../../types/charts/chart-settings';
import { getPreviewDataAndCalculateGrandTotal } from '../../helpers/chart.helper';
import {
	populateDroppedFactsByDataframe,
	populateDroppedRowsByDataframe,
	setAvailableMeasuresAndDimensionsByDataframe,
} from '../../hooks/DataBuilderHooks';
import { AxiosError } from 'axios';
import { useMount } from 'react-use';
import { DataframeDataJoin } from '../../api/dataframe-data-join/DataframesDataJoin';
import { PeriodTypesEnum } from '../../enums/period-types-enum';
import { timestampToMMDDYYYY } from '../../utilities';
import { DataBuilderTypes } from '../../enums/data-builder-types';
import { Lambda } from '../../api/lambda/Lambda';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import { useCommonStoreContext } from '../common-store/CommonStoreProvider';
import { DashboardFilterStore } from '../../types/dashboards/dashboard-filter-store';

type Props = {
	widgetId?: number;
	chartId: string;
	selectedDate?: TNewDateRange;
	allowClickNavigate?: boolean;
	height?: number | string;
	width?: number | string;
	filters?: DashboardFilter[];
	isEdit?: boolean;
	hideXAxis?: boolean;
	hideTitle?: boolean;
	transparentBackground: boolean;
	delayedDisplayTime?: number;
	hideYAxis?: boolean;
	fetchInsights?: boolean;
	setLambdaInsights?: Dispatch<React.SetStateAction<LambdaInsightsResponse | undefined>>;
	setHasRetrievalData?: Dispatch<React.SetStateAction<boolean>>;
	noAnimation?: boolean;
};

const ChartView = (props: Props) => {
	const {
		widgetId,
		chartId,
		selectedDate,
		allowClickNavigate,
		height,
		width,
		filters,
		isEdit,
		hideXAxis,
		hideTitle,
		transparentBackground,
		delayedDisplayTime,
		hideYAxis,
		noAnimation,
	} = props;
	const { addToast } = useToast();
	const { dashboardId, presentationId } = useParams();
	const { currentDatePeriods, dimensions, measures, unitTypes } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [chartLoaded, setChartLoaded] = useState<boolean>(false);
	const [chart, setChart] = useState<TChart | null>(null);

	const [chartSettings, setChartSettings] = useState<ZiChartSettings>({
		chartColor: '',
		hideLegend: true,
		hideYAxis: hideYAxis ?? false,
		limitResults: 0,
		showAverage: false,
		showDataValues: true,
		showPercentages: false,
		showScrollbar: false,
		showTargets: false,
		showValues: false,
		hideXAxis,
		hideTitle,
		overrideValueDefinedColors: false,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [chartRetrevialData, setChartRetrevialData] = useState<any[]>([]);
	const [dimConfigValues, setDimConfigValues] = useState<DimSettings[]>([]);
	const [chartRetrevialCSVData, setChartRetrevialCSVData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [chartProps, setChartProps] = useState<ZiChartProps>({
		isConfigMode: false,
		displayChart: true,
		title: '',
		categoryTitle: '',
		valueTitle: '',
		chartRetrevialData: chartRetrevialData ?? [],
		dimConfigValues,
		chartData: chart ?? undefined,
		transparentBackground: transparentBackground,
		...(height && width && { size: { height, width } }),
		containerProps: { style: { height: '100%' } },
		chartSettings,
		setChartSettings,
		showLimitWarning: false,
		grandTotal: [{}],
		chartRenderedCallback: () => {
			setChartLoaded(true);
		},
		isChartOnly: true,
	});

	const [dataframe, setDataframe] = useState<TDataframe>();
	const [parentDataframe, setParentDataframe] = useState<TDataframe>();
	const [dataframeHasJoins, setDataframeHasJoins] = useState<boolean>();
	const [hiddenClass, setHiddenClass] = useState<string>('hidden');
	const [unitType, setUnitType] = useState<TUnitType>();
	const [unitType2, setUnitType2] = useState<TUnitType>();
	const [numDecimals, setNumDecimals] = useState<number | undefined>(2);
	const [numDecimals2, setNumDecimals2] = useState<number | undefined>(2);
	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedEndDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedEndPeriod
			? JSON.parse(previousSelectedEndPeriod)
			: null;
	const currentUser = useUser();
	const { sharedDashboardFilters, setSharedDashboardFilters } = useCommonStoreContext();

	const getChartLink = () => {
		let url = `/analyze/charts/view/chart/${chartId}`;
		if (dashboardId) {
			url = `/analyze/dashboards/${dashboardId}/chart/${chartId}/view`;
		} else if (presentationId) {
			url = `/present/${presentationId}/chart/${chartId}`;
		}

		return url;
	};

	useMount(() => {
		getChart();
	});

	useEffect(() => {
		if (chart && dataframe && selectedDate) {
			setIsLoading(true);
			setChartLoaded(false);
			getRetrievalData();
			setSharedDashboardFilters(mapFiltersToShare());
		}
	}, [selectedDate, filters, dataframe]);

	useEffect(() => {
		if (chart) {
			getChartDataframe();
		}
	}, [chart]);

	useEffect(() => {
		if (dataframe) {
			getDataframeJoins();
		}
	}, [dataframe]);

	useEffect(() => {
		getDataframeUnitType();
		getDataframeDecimalPrecision();
	}, [dataframeHasJoins]);

	useEffect(() => {
		if (dataframe && chartRetrevialData && chart) {
			const previewDataAndGrandTotal = getPreviewDataAndCalculateGrandTotal(
				populateDroppedFactsByDataframe(dataframe, []),
				chartRetrevialData,
				true
			);

			setChartProps({
				...chartProps,
				isSingleSeries: dataframe.rowEntry.length == 1,
				chartRetrevialData: chartRetrevialData,
				chartType: chart.chart_type as ChartSeriesChartTypes,
				chartData: chart,
				title: chart.name,
				selectedNumDecimals: numDecimals,
				selected2ndNumDecimals: numDecimals2,
				...(unitType && { selectedUnitType: unitType }),
				...(unitType && { unitType: unitType }),
				...(unitType && { valueTitle: unitType.name }),
				...(unitType2 && { selected2ndUnitType: unitType2 }),
				chartSettings: chartSettings,
				showLimitWarning: false,
				grandTotal: previewDataAndGrandTotal?.grandTotal,
				droppedFacts: populateDroppedFactsByDataframe(dataframe, []),
				droppedFields: populateDroppedRowsByDataframe(dataframe, []),
				unitTypes,
				noAnimation: noAnimation,
			});

			// Time out used to prevent the flash of the chart component before all data has initialized
			if (delayedDisplayTime) {
				setTimeout(() => {
					setHiddenClass('');
				}, delayedDisplayTime);
			} else {
				setHiddenClass('');
			}

			setIsLoading(false);

			if (!chartRetrevialData.length) {
				setChartLoaded(true);
			}
		}
	}, [chartRetrevialData]);

	useEffect(() => {
		if (props.fetchInsights && props.setLambdaInsights) {
			getInsightsData();
		}
	}, [props.fetchInsights]);

	const getDataframeJoins = () => {
		if (dataframe?.parent) {
			void DataframeDataJoin.Get(dataframe.parent).then((response) => {
				setDataframeHasJoins(response.length ? true : false);
			});
		} else {
			setDataframeHasJoins(false);
		}
	};

	const getDataframeUnitType = () => {
		if (dataframe?.datasets[0] && dataframe.datasets[0].unitType) {
			const unitType1 = unitTypes?.find((x) => x.id == dataframe.datasets[0].unitType);
			setUnitType(unitType1);
		}

		if (dataframe?.datasets[1] && dataframe.datasets[1].unitType) {
			const unitType2 = unitTypes?.find((x) => x.id == dataframe.datasets[1].unitType);
			setUnitType2(unitType2);
		}
	};

	const getDataframeDecimalPrecision = () => {
		if (dataframe?.datasets[0]) {
			setNumDecimals(dataframe.datasets[0].numDecimals ?? chart?.num_decimals);
		}

		if (dataframe?.datasets[1]) {
			setNumDecimals2(dataframe.datasets[1].numDecimals ?? 2);
		}
	};

	const getRetrievalData = () => {
		if (dataframe) {
			const previousDate = {
				begin_date:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate?.begin_date
						: defaultDatePeriod?.begin_date ?? 0,
				end_date:
					hasPreviouslySelectedPeriod && previouslySelectedEndDate
						? previouslySelectedEndDate.end_date
						: defaultDatePeriod?.end_date ?? 0,
				period:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate.period
						: defaultDatePeriod?.period ?? 0,
			};

			const dateToUse = selectedDate ?? previousDate;

			const request: DataframeDataRetrievalRequest = {
				dataframeId: dataframe.id,
				begin_date: dateToUse.begin_date,
				end_date: dateToUse.end_date,
				periodId: dateToUse.period,
				override: {
					id: dataframe.id,
					...(dataframe.parent && { parent: dataframe.parent }),
					columnEntry: [],
					datasets: dataframe.datasets,
					name: 'override',
					rowEntry: dataframe.rowEntry,
					order: dataframe.order,
					filters: mapFilters(filters ?? [], dataframe),
				},
			};

			Dataframe.Retrieve(request)
				.then((newDataResponse) => {
					const newResponseRetrevialData = formatCsvDataToColumns(
						newDataResponse.json?.columns ?? [],
						newDataResponse.csvData ?? [],
						dateToUse?.period ?? 3
					);
					if (props.setHasRetrievalData) {
						props.setHasRetrievalData(!!newDataResponse.csvData);
					}
					setChartRetrevialData(newResponseRetrevialData);
					setDimConfigValues(newDataResponse.json?.dim_setting ?? []);
					setChartRetrevialCSVData(newDataResponse);
				})
				.catch((e: AxiosError<{ message: string }>): void => {
					if (e.response?.data?.message) {
						addToast(e.response.data.message, AlertVariant.danger);
					} else {
						addToast('Error fetching data.', AlertVariant.danger);
					}
					setIsLoading(false);
					setChartLoaded(true);
				});
		}
	};

	const getChartDataframe = () => {
		Dataframe.Get(chart?.dataframe ?? 0, ['datasets', 'filters', 'rowEntry', 'order'])
			.then((dataframeResponse) => {
				if (dataframeResponse.parent) {
					Dataframe.Get(dataframeResponse.parent, [
						'datasets',
						'filters',
						'rowEntry',
						'order',
					])
						.then((dataframeParentResponse) => {
							setDataframe(dataframeResponse);
							setParentDataframe(dataframeParentResponse);
						})
						.catch(() => {
							setIsLoading(false);
							addToast(
								'There was an issue retrieving the parent dataframe',
								AlertVariant.danger
							);
						});
				} else {
					setDataframe(dataframeResponse);
				}
			})
			.catch(() => {
				setIsLoading(false);
				addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
			});
	};

	const getChart = () => {
		setIsLoading(true);
		Chart.GetOne(parseInt(chartId))
			.then((chartResponse: TChart): void => {
				setChart(chartResponse);
				setChartSettings({
					chartColor: chartResponse.color_set,
					hideLegend: chartResponse.hide_legend,
					hideYAxis: hideYAxis ?? chartResponse.hide_y_axis,
					limitResults: 500,
					showAverage: chartResponse.show_average,
					showDataValues: chartResponse.show_data_values,
					showPercentages: chartResponse.show_percentages,
					showScrollbar: chartResponse.show_scrollbar,
					showTargets: chartResponse.show_targets,
					showValues: chartResponse.show_targets,
					hideXAxis,
					hideTitle,
					overrideValueDefinedColors: chartResponse.override_value_defined_colors,
				});
			})
			.catch((): void => {
				addToast('Error fetching dataframe chart data.', AlertVariant.danger);
				setIsLoading(false);
			})
			.catch((): void => {
				addToast('Error fetching chart data.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const getInsightsData = () => {
		if (dataframe && chartRetrevialCSVData) {
			const previousDate = {
				begin_date:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate?.begin_date
						: defaultDatePeriod?.begin_date ?? 0,
				end_date:
					hasPreviouslySelectedPeriod && previouslySelectedEndDate
						? previouslySelectedEndDate.end_date
						: defaultDatePeriod?.end_date ?? 0,
				period:
					hasPreviouslySelectedPeriod && previouslySelectedStartDate
						? previouslySelectedStartDate.period
						: defaultDatePeriod?.period ?? 0,
			};

			const date = selectedDate ?? previousDate;

			const request: LambdaInsightsRequest = {
				metadata: {
					key_measures: dataframe?.datasets.map((fact) => {
						return {
							name: fact.title ?? '',
							description: '',
						};
					}),
					dimensions: dataframe?.rowEntry.map((dim) => {
						return {
							name: dim.title ?? '',
							description: '',
						};
					}),
					columns: chartRetrevialCSVData.json?.columns ?? [],
					...getPeriodData(date),
					filters_applied: getInsightsFilters(),
				},
				data: chartRetrevialCSVData.csvDataUnformatted ?? '',
				logging: {
					user_id: currentUser.id,
					entity_type: DataBuilderTypes.chart,
					entity_id: chart?.id ?? 0,
				},
			};

			Lambda.GetInsights(request)
				.then((response) => {
					if (props.setLambdaInsights) {
						if (response.success) {
							if (typeof response.data === 'string') {
								response.data = JSON.parse(response.data) as LambdaInsightsResponse;
							}
							props.setLambdaInsights(response.data);
						} else {
							addToast(response.message, AlertVariant.danger);
							props.setLambdaInsights(undefined);
						}
					}
				})
				.catch((): void => {
					addToast('Error fetching insights.', AlertVariant.danger);
					props.setLambdaInsights && props.setLambdaInsights(undefined);
				});
		}
	};

	const getInsightsFilters = () => {
		const filters: string[] = [];
		if (dataframe) {
			dataframe.filters.forEach((filter) => {
				if (filter.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
					const dim = dimensions.find((dim) =>
						dim.dimensionAttributes.some((dimAttr) => dimAttr.id === filter.entity_id)
					);
					if (dim) {
						const dimAttr = dim.dimensionAttributes.find(
							(dimAttr) => dimAttr.id === filter.entity_id
						);

						if (dimAttr) {
							filters.push(`${dimAttr.name ?? ''}: ${filter.value ?? ''}`);
						}
					}
				} else if (filter.entity_type === OptionsBuilderItemTypes.Dimension) {
					const dim = dimensions.find((dim) => dim.id === filter.entity_id);

					if (dim) {
						filters.push(`${dim.name ?? ''}: ${filter.value ?? ''}`);
					}
				}
			});
		}

		return filters;
	};

	const getPeriodData = (date: { begin_date: number; end_date: number; period: number }) => {
		const response = {
			time_period: '',
			time_range: {
				start_date: '',
				end_date: '',
			},
		};

		if (date) {
			response.time_range.start_date = timestampToMMDDYYYY(date.begin_date);
			response.time_range.end_date = timestampToMMDDYYYY(date.end_date);

			switch (date.period) {
				case 1: {
					response.time_period = PeriodTypesEnum.Year;
					break;
				}
				case 2: {
					response.time_period = PeriodTypesEnum.Quarter;
					break;
				}
				case 3: {
					response.time_period = PeriodTypesEnum.Month;
					break;
				}
				case 4: {
					response.time_period = PeriodTypesEnum.Week;
					break;
				}
				default: {
					response.time_period = PeriodTypesEnum.Month;
					break;
				}
			}
		}

		return response;
	};

	const mapFilters = (
		filters: DashboardFilter[],
		dataframe: TDataframe
	): TNewDataframeFilter[] => {
		const filtersToReturn: TNewDataframeFilter[] = [];
		const availableItems = setAvailableMeasuresAndDimensionsByDataframe(
			dataframe,
			parentDataframe ?? undefined,
			measures,
			dimensions,
			false
		);

		if (filters.length) {
			const allowedFilters = filters
				? filters.filter((item) => {
						if (item.entity_type === OptionsBuilderItemTypes.Dimension) {
							const dimIds = availableItems.dimensions.map((dim) => dim.id);
							return item.value && dimIds.includes(item.entity_id);
						} else {
							const attributes = availableItems.dimensions.flatMap(
								(dim) => dim.dimensionAttributes
							);
							const attributeIds = attributes.map((attr) => attr.id);
							return item.value && attributeIds.includes(item.entity_id);
						}
				  })
				: [];

			allowedFilters.forEach((filter) => {
				const existingFilter = dataframe.filters.find(
					(df) =>
						df.entity_id === filter.entity_id && df.entity_type === filter.entity_type
				);

				if (existingFilter && filter.value) {
					existingFilter.excluded = filter.excluded;
					existingFilter.isExistingValue = filter.isExistingValue;
					existingFilter.operator = filter.operator;
					existingFilter.value = filter.value;
				} else {
					filtersToReturn.push({
						entity_id: filter.entity_id,
						entity_type: filter.entity_type,
						excluded: filter.excluded,
						isExistingValue: filter.isExistingValue,
						operator: filter.operator,
						value: filter.value,
					});
				}
			});
		}

		return [...filtersToReturn, ...dataframe.filters];
	};

	const mapFiltersToShare = (): DashboardFilterStore[] => {
		if (dashboardId && widgetId) {
			const availableItems = setAvailableMeasuresAndDimensionsByDataframe(
				dataframe,
				parentDataframe ?? undefined,
				measures,
				dimensions,
				false
			);
			const existingSharedFilter = sharedDashboardFilters.find(
				(filter) => filter.id === +dashboardId
			);

			const allowedFilters = filters
				? filters.filter((item) => {
						if (item.entity_type === OptionsBuilderItemTypes.Dimension) {
							const dimIds = availableItems.dimensions.map((dim) => dim.id);
							return item.value && dimIds.includes(item.entity_id);
						} else {
							const attributes = availableItems.dimensions.flatMap(
								(dim) => dim.dimensionAttributes
							);
							const attributeIds = attributes.map((attr) => attr.id);
							return item.value && attributeIds.includes(item.entity_id);
						}
				  })
				: [];

			if (existingSharedFilter) {
				const widgetExists = existingSharedFilter.widgets.find(
					(widget) => widget.id === widgetId
				);

				return sharedDashboardFilters.map((filter) => {
					if (filter.id === +dashboardId) {
						if (widgetExists) {
							filter.widgets = filter.widgets.map((widget) => {
								if (widget.id === widgetId) {
									widget.filters = allowedFilters;
								}
								return widget;
							});
						} else {
							filter.widgets = [
								...filter.widgets,
								...[
									{
										id: widgetId,
										itemId: +chartId,
										filters: allowedFilters,
									},
								],
							];
						}
					}

					return filter;
				});
			} else {
				return [
					...(sharedDashboardFilters ?? []),
					...[
						{
							id: +dashboardId,
							widgets: [
								{
									id: widgetId,
									itemId: +chartId,
									filters: allowedFilters,
								},
							],
						},
					],
				];
			}
		}

		return sharedDashboardFilters;
	};

	const componentStyles = {
		height: '100%',
		width: '100%',
		overflow: 'auto',
		display: chartLoaded ? 'block' : 'none',
	};

	let component = <Loader />;

	if (!isLoading) {
		component = (
			<div
				style={componentStyles}
				className={hiddenClass}
				id={`widget-${widgetId ?? 0}`}
			>
				<ZiChart
					{...chartProps}
					chartRetrevialData={chartRetrevialData || []}
				/>
			</div>
		);

		if (allowClickNavigate && !isEdit) {
			component = (
				<div
					style={componentStyles}
					className={hiddenClass}
				>
					<Link to={getChartLink()}>{component}</Link>
				</div>
			);
		}
	}
	return component;
};

export default ChartView;
