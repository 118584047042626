import React, { useEffect, useState } from 'react';
import ZiTable, { Action, Column } from '../../../components/table/ZiTable';
import FilterTableLayout from '../../../layout/FilterTableLayout';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';
import { Schedule, TSchedule } from '../../../api/schedules/Schedules';
import { AlertVariant, Grid, GridItem, Title } from '@patternfly/react-core';
import { useMount } from 'react-use';
import { TReport } from '../../../api/types';
import { Report } from '../../../api/reports/Reports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Permission } from '../../../enums/permission.enum';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import AddEditScheduleModal, {
	AddEditScheduleModalProps,
} from '../../../components/modals/schedule/AddEditScheduleModal';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../../../helpers/helper-components/DeleteConfirmationModal';

function ListSchedules() {
	const [schedules, setSchedules] = useState<Schedule[]>([]);
	const [reports, setReports] = useState<TReport[]>([]);
	const [activeSchedule, setActiveSchedule] = useState<Schedule>();
	const [activeReport, setActiveReport] = useState<TReport>();
	const [isEditScheduleModalOpen, setIsEditScheduleModalOpen] = useState<boolean>(false);
	const [isDeleteScheduleModalOpen, setIsDeleteScheduleModalOpen] = useState<boolean>(false);
	const [scheduleIdToDelete, setScheduleIdToDelete] = useState<number>();
	const { addToast } = useToast();
	const navigate = useNavigate();
	const location = useLocation();
	const query = location.search;

	useMount(() => {
		getAllSchedules();

		void Report.GetAll().then((response) => {
			setReports(response);
		});

		if (query.includes('reportId')) {
			const reportId = +query.split('=')[1];
			const newUrl = query.split('?')[0];
			navigate(newUrl, { replace: true });

			void Report.Get(reportId, ['reportSchedule']).then((response) => {
				const existingSchedules = response.reportSchedule;

				if (existingSchedules && existingSchedules.length > 0) {
					const firstSchedule = existingSchedules[0];
					setActiveReport(response);
					setActiveSchedule(firstSchedule);
					setIsEditScheduleModalOpen(true);
				}
			});
		}
	});

	useEffect(() => {
		getAllSchedules();
	}, [location]);

	const getAllSchedules = () => {
		void Schedule.GetAll().then((response) => {
			setSchedules(response);
		});
	};

	const selectedColumns: Column<TSchedule>[] = [
		{
			title: 'Name',
			columnName: 'name',
			sortable: true,
		},
		{
			title: 'Report',
			columnName: 'report',
			sortable: true,
			customAccessor: (item) => {
				const reportName = reports.find((x) => x.id == item.id)?.name ?? '';
				return reportName;
			},
		},
		{
			title: 'Interval',
			columnName: 'interval',
			sortable: true,
		},
		{
			title: 'Next Run Date',
			columnName: 'next_run_date',
			sortable: true,
		},
	];

	const actions: Action<TSchedule>[] = [
		{
			name: (
				<>
					<FontAwesomeIcon icon={faPenToSquare} />
					Edit
				</>
			),
			callback: (item) => {
				const report = reports.find((x) => x.id == item.id);
				setActiveReport(report);
				setActiveSchedule(item);
				setIsEditScheduleModalOpen(true);
			},
			permission: Permission.EditReportSchedule,
		},
		{
			name: (
				<>
					<FontAwesomeIcon icon={faTrashAlt} />
					Delete
				</>
			),
			callback: (item) => {
				setScheduleIdToDelete(item.id);
				setIsDeleteScheduleModalOpen(true);
			},
			permission: Permission.EditReportSchedule,
		},
	];

	const schedulesTable = (
		<ZiTable<Schedule>
			ariaLabel={'Schedules table'}
			columns={selectedColumns}
			data={schedules}
			caption="Schedules"
			loading={false}
			actions={actions}
		/>
	);

	const scheduleModalProps: AddEditScheduleModalProps = {
		isOpen: isEditScheduleModalOpen,
		onClose: () => {
			setIsEditScheduleModalOpen(false);
			setActiveReport(undefined);
			setActiveSchedule(undefined);
		},
		existingReportSchedule: activeSchedule,
		setExistingReportSchedule: setActiveSchedule,
		existingReport: activeReport,
	};

	const deleteReportSchedule = () => {
		Schedule.Delete(scheduleIdToDelete ?? 0)
			.then(() => {
				getAllSchedules();
				closeDeleteModal();
				addToast('Report Schedule deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to delete Report Schedule. Please try again later.',
					AlertVariant.danger
				);
			});
	};

	const closeDeleteModal = () => {
		setIsDeleteScheduleModalOpen(false);
	};

	return (
		<React.Fragment>
			<Grid hasGutter>
				<span className="spacer-sm"></span>
				<Title headingLevel={'h1'}>Schedules</Title>
				<GridItem span={12}>
					<FilterTableLayout
						table={schedulesTable}
						icon={FilterIcon}
					/>
				</GridItem>
			</Grid>
			{isEditScheduleModalOpen && <AddEditScheduleModal {...scheduleModalProps} />}
			{isDeleteScheduleModalOpen && (
				<DeleteConfirmationModal
					isOpen={isDeleteScheduleModalOpen}
					onClose={closeDeleteModal}
					onSubmit={deleteReportSchedule}
				/>
			)}
		</React.Fragment>
	);
}
export default ListSchedules;
