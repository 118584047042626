import React, { useState } from 'react';
import { Select, SelectVariant, TreeView, TreeViewDataItem } from '@patternfly/react-core';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { useMount } from 'react-use';

type SearchableTreeViewSelectProps = {
	data: TreeViewDataItem[];
	onToggle?: () => void;
	placeHolderText?: string;
	selectedItems?: TreeViewDataItem[];
	treeItemsExpanded?: boolean;
	onSelect?: (
		event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem,
		parentItem: TreeViewDataItem
	) => void;
	className?: string;
	isTopLvlSearchOnly?: boolean;
	isInputAndTreeOnly?: boolean;
	searchInputRef?: React.RefObject<HTMLInputElement>;
	validated?: 'success' | 'warning' | 'error' | 'default';
};

function SearchableTreeViewSelect(props: SearchableTreeViewSelectProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedItem, setSelectedItem] = useState<TreeViewDataItem | undefined>();

	useMount(() => {
		if (props.selectedItems && props.selectedItems.length > 0) {
			setSelectedItem(props.selectedItems[0]);
		}
	});

	const onToggle = () => {
		setIsOpen(!isOpen);
	};

	const onSelectTreeItem = (
		_event: React.MouseEvent<Element, MouseEvent>,
		item: TreeViewDataItem,
		parentItem: TreeViewDataItem
	) => {
		setSelectedItem(item);

		if (props.onSelect) {
			props.onSelect(_event, item, parentItem);
		}
		onToggle();
	};

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	let filteredData = props.data.flatMap((item) => {
		const newFilteredData: TreeViewDataItem[] = [];

		if (!searchQuery) {
			return newFilteredData;
		}

		if (props.isTopLvlSearchOnly) {
			const topLevelItems =
				item.name && item.name.toString().toLowerCase().includes(searchQuery.toLowerCase())
					? [item]
					: [];

			return topLevelItems;
		}
		// Check and filter children
		const filteredChildren = item.children
			? item.children.filter(
					(child) =>
						child.name &&
						child.name.toString().toLowerCase().includes(searchQuery.toLowerCase())
			  )
			: [];

		// Include the parent item even if a match is found in its children
		if (filteredChildren.length > 0) {
			const newItem = { ...item, children: filteredChildren };
			newFilteredData.push(newItem);
		}

		return newFilteredData;
	});

	if (filteredData.length == 0) {
		filteredData = [BuildTreeViewItem({ id: -1, name: 'No Options were found' }, [])];
	}

	function setChildId(id?: string) {
		const childId = typeof id === 'number' ? id : id?.split('__');

		return childId && childId.length > 1
			? Number(childId[childId.length - 1])
			: childId
			? Number(childId)
			: 0;
	}

	const tree = (
		<TreeView
			data={searchQuery == '' ? props.data : filteredData}
			allExpanded={props.treeItemsExpanded ?? false}
			onSelect={onSelectTreeItem}
			className={`extra-treeview-height ${
				props.isInputAndTreeOnly ? 'treeview-inner-fixed-height' : ''
			}`}
		/>
	);

	if (props.isInputAndTreeOnly) {
		return (
			<>
				<input
					type="text"
					className="pf-c-form-control"
					placeholder="Search..."
					value={searchQuery}
					onChange={onSearchChange}
					ref={props.searchInputRef}
				/>
				{tree}
			</>
		);
	}
	return (
		<Select
			className={props.className}
			variant={SelectVariant.single}
			isOpen={isOpen}
			menuAppendTo="parent"
			onToggle={onToggle}
			placeholderText={selectedItem?.name ?? props.placeHolderText}
			onSelect={onToggle}
			validated={props.validated}
			customContent={
				<>
					<input
						type="text"
						className="pf-c-form-control"
						placeholder="Search..."
						value={searchQuery}
						onChange={onSearchChange}
					/>
					{tree}
				</>
			}
		/>
	);
}

export const dropdownToTreeViewData = (dropdownItems: JSX.Element[]): TreeViewDataItem[] => {
	return dropdownItems
		.map((group, groupIndex) => {
			// Extract label and key
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			const label = group.props?.label || `Group ${groupIndex + 1}`;
			const groupKey = group.key || `group-${groupIndex}`;

			// Convert DropdownItems inside the DropdownGroup
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
			const children = React.Children.toArray(group.props.children)
				.filter((child) => React.isValidElement(child)) // Ensure valid elements
				.map((child: any, itemIndex) => ({
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
					id: child.props.id || `${groupKey}-item-${itemIndex}`, // Use id from props or fallback
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
					name: child.props.children, // DropdownItem's text content
				}));

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			return {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				id: groupKey,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				name: label,
				children: children,
			};
		})
		.filter(Boolean); // Remove null values
};

export const dropdownChildrenToTreeViewData = (
	dropdownItems: JSX.Element[]
): TreeViewDataItem[] => {
	return dropdownItems
		.filter((item) => React.isValidElement(item)) // Ensure valid elements
		.map((item: any, index) => ({
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			id: item.props.id || `item-${index}`, // Use provided id or fallback
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			name: item.props.children, // DropdownItem's text content
		}));
};

export default SearchableTreeViewSelect;
