import axiosConfig from '../axiosConfig';
import { TPresentationSlide } from '../present/PresentationSlide';
import { TextWidgetDataRequest, TextWidgetDataResponse, TWidgetConditionalRules } from '../types';
import { TDashboard } from './Dashboards';

export type TWidget = {
	id?: number;
	col: number;
	row: number;
	sizex: number;
	sizey: number;
	dashboard?: TDashboard | number | undefined;
	presentationSlide?: TPresentationSlide | number | undefined;
	page: number;
	name: string;
	widget_type: 'text' | 'pivot_table' | 'notes' | 'chart' | 'image' | 'base';
	content?: string;
	chart?: number;
	report?: number;
	source?: string;
	isNew?: boolean;
	widgetChanged?: boolean;
	isEdit?: boolean;
	conditionalRules?: TWidgetConditionalRules[];
	shouldPrepImage?: boolean;
	image?: any;
};

export class Widget implements TWidget {
	id?: number;
	col: number;
	row: number;
	sizex: number;
	sizey: number;
	dashboard?: TDashboard | number | undefined;
	presentationSlide?: TPresentationSlide | number | undefined;
	page: number;
	name: string;
	widget_type: 'text' | 'pivot_table' | 'notes' | 'chart' | 'image' | 'base';
	content?: string;
	chart?: number;
	report?: number;
	source?: string;
	isNew?: boolean;
	widgetChanged?: boolean;
	isEdit?: boolean;
	conditionalRules?: TWidgetConditionalRules[];

	constructor(widget: TWidget) {
		this.id = widget.id;
		this.col = widget.col;
		this.row = widget.row;
		this.sizex = widget.sizex;
		this.sizey = widget.sizey;
		this.dashboard = widget.dashboard;
		this.presentationSlide = widget.presentationSlide;
		this.page = widget.page;
		this.name = widget.name;
		this.widget_type = widget.widget_type;
		this.content = widget.content;
		this.chart = widget.chart;
		this.source = widget.source;
		this.isNew = widget.isNew;
		this.widgetChanged = widget.widgetChanged;
		this.report = widget.report;
		this.isEdit = widget.isEdit;
		this.conditionalRules = widget.conditionalRules;
	}

	public static Default(): TWidget {
		return {
			name: '',
			col: 0,
			row: 0,
			sizex: 0,
			sizey: 0,
			widget_type: 'base',
			page: 1,
			dashboard: undefined,
			presentationSlide: undefined,
		};
	}

	public static New(dashboardWidget: TWidget): Promise<TWidget> {
		return axiosConfig.instance.post('app/widget', dashboardWidget).then((res) => {
			return new Widget(res.data as TWidget);
		});
	}

	public static Edit(dashboardWidget: TWidget): Promise<TWidget> {
		return axiosConfig.instance.patch('app/widget', dashboardWidget).then((res) => {
			return new Widget(res.data as TWidget);
		});
	}

	public static Get(dashboardWidgetId: number): Promise<TWidget> {
		return axiosConfig.instance.get(`app/widget/${dashboardWidgetId}`).then((res) => {
			return new Widget(res.data as TWidget);
		});
	}

	public static GetAll(
		filter: Record<string, string>,
		expand: (keyof TWidget)[] = []
	): Promise<TWidget[]> {
		let url = 'app/widgets';
		if (filter) {
			let joinedFilterString = '';
			for (const property in filter) {
				if (joinedFilterString !== '') {
					joinedFilterString = `${joinedFilterString}&`;
				}
				joinedFilterString = `${joinedFilterString}filter[${property}]=${filter[property]}`;
			}
			url = `${url}?${joinedFilterString}`;
		}
		return axiosConfig.instance.get<TWidget[]>(url, { params: { expand } }).then((res) => {
			return res.data?.length > 0 ? res.data : [];
		});
	}

	public static Delete(dashboardWidgetId: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/widget/${dashboardWidgetId}`).then((res) => {
			return res.data as boolean;
		});
	}

	public static GetTextWidgetData(
		request: TextWidgetDataRequest
	): Promise<TextWidgetDataResponse> {
		return axiosConfig.instance.post('app/text-widget', request).then((res) => {
			return res.data as TextWidgetDataResponse;
		});
	}
}
